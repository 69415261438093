import { number } from 'prop-types';
import React from 'react';
import * as Yup from 'yup';

function notEqualTo(ref, msg) {
  return this.test({
    name: 'notEqualTo',
    exclusive: false,
    message: msg || 'Os números de telefone não podem ser repetidos.',
    params: {
      reference: ref.path,
    },
    test(value) {
      return value === this.resolve(ref);
    },
  });
}

export const ValidacaoEnvioProposta = () => {
  Yup.addMethod(Yup.string, 'notEqualTo', notEqualTo);

  const validation = Yup.object().shape({
    Vendedor: Yup.string().notRequired(),
    ValorVista: Yup.number()
      .min(60, 'O valor deve ser maior que R$ 60,00')
      .max(22000, 'O valor deve ser menor que R$ 22.000,00')
      .required('Campo obrigatório'),

    ValorEntrada: Yup.string().notRequired().nullable(),
    TabelaComercial: Yup.string().required('Selecione uma tabela comercial'),
    SeguroPrestamista: Yup.string().notRequired().nullable(),
    Plano: Yup.string().required('Informe um plano'),
    Observacao: Yup.string().notRequired(),
    FormaPagamentoCliente: Yup.string().notRequired(),
    DataPrimeiraParcela: Yup.date().min(
      new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
      'A data deve ser no mínimo para 30 dias após a data atual'
    ),
    CodigoPedidoLojista: Yup.string().notRequired(),

    // Conta do Cliente
    ContaCliente: Yup.object().shape({
      Agencia: Yup.string().notRequired(),
      CodigoBanco: Yup.string().notRequired(),
      Conta: Yup.string().notRequired(),
      DigitoAgencia: Yup.string().notRequired(),
      DigitoConta: Yup.string().notRequired(),
      TipoConta: Yup.string().notRequired().nullable(),
    }),

    // Cliente
    Cliente: Yup.object().shape({
      Apelido: Yup.string().notRequired(),

      Cpf: Yup.string().required('Campo obrigatório'),
      DataNascimento: Yup.date()
        .required('Campo obrigatório')
        .typeError('Campo obrigatório'),
      Email: Yup.string().notRequired().email('Informe um e-mail válido'),

      Endereco: Yup.object().shape({
        Bairro: Yup.string().required('Campo obrigatório'),
        Cep: Yup.string().required('Campo obrigatório'),
        Cidade: Yup.string().required('Campo obrigatório'),
        Logradouro: Yup.string().required('Campo obrigatório'),
        PontoReferencia: Yup.string().notRequired(),
        Numero: Yup.number('Campo obrigatório')
          .required('Campo obrigatório')
          .positive('Campo obrigatório')
          .typeError('Campo obrigatório'),
        Uf: Yup.string().required('Campo obrigatório'),
      }),
      EnderecoAnterior: Yup.object().shape({
        Bairro: Yup.string().notRequired(),
        Cep: Yup.string().notRequired(),
        Cidade: Yup.string().notRequired(),
        Logradouro: Yup.string().notRequired(),
        PontoReferencia: Yup.string().notRequired(),
        Numero: Yup.string().notRequired(),
        Uf: Yup.string().notRequired(),
      }),

      Nacionalidade: Yup.string().required('Campo obrigatório'),
      Naturalidade: Yup.string().required('Campo obrigatório'),
      Nome: Yup.string().required('Campo obrigatório'),
      NomeMae: Yup.string().required('Campo obrigatório'),
      NomePai: Yup.string().notRequired(),
      Observacao: Yup.string().notRequired(),
      Ocupacao: Yup.object().shape({
        TipoAtividade: Yup.string().required('Campo obrigatório'),

        Cargo: Yup.string().required('Campo obrigatório'),
        DataAdmissao: Yup.date()
          .required('Campo obrigatório')
          .typeError('Campo obrigatório'),
        Empresa: Yup.string().required('Campo obrigatório'),
        EnderecoComercial: Yup.object().shape({
          Bairro: Yup.string().notRequired().nullable(),
          Cep: Yup.string().notRequired().nullable(),
          Cidade: Yup.string().notRequired().nullable(),
          Logradouro: Yup.string().notRequired().nullable(),
          PontoReferencia: Yup.string().notRequired().nullable(),
          Numero: Yup.string()
            .notRequired()
            .nullable()
            .typeError('Campo obrigatório'),
          Uf: Yup.string().notRequired().nullable(),
        }),
        OutrasRendas: Yup.string().notRequired(),
        Salario: Yup.number()
          .required('Campo obrigatório')
          .positive('O valor deve ser maior que zero'),
        TelefoneComercial: Yup.object().shape({
          Ddd: Yup.string().notRequired().nullable(),
          Numero: Yup.string().notRequired().nullable(),
        }),
      }),

      QuantidadeDependentes: Yup.string().notRequired(),

      ReferenciasBancarias: Yup.array()
        .of(
          Yup.object().shape({
            CodigoBanco: Yup.string().notRequired().nullable(),
            Agencia: Yup.string().notRequired(),
            DigitoAgencia: Yup.string().notRequired().nullable(),
            DataAberturaConta: Yup.string()
              .notRequired()
              .nullable()
              .typeError('Informe uma data válida'),
          })
        )
        .required(),

      ReferenciasPessoais: Yup.array()
        .of(
          Yup.object().shape({
            Nome: Yup.string().notRequired(),
            Telefone: Yup.object().shape({
              Ddd: Yup.string().notRequired(),
              Numero: Yup.string().notRequired(),
            }),
          })
        )
        .required(),
      ReferenciasComerciais: Yup.array()
        .of(
          Yup.object().shape({
            Nome: Yup.string().notRequired(),
            Telefone: Yup.object().shape({
              Ddd: Yup.string().notRequired(),
              Numero: Yup.string().notRequired(),
            }),
          })
        )
        .required(),

      Rg: Yup.object().shape({
        DataExpedicao: Yup.date()
          .required('Campo obrigatório')
          .typeError('Campo obrigatório'),
        Numero: Yup.string().required('Campo obrigatório'),
        OrgaoExpedidor: Yup.string().required('Campo obrigatório'),
        Uf: Yup.string().required('Campo obrigatório'),
      }),

      Sexo: Yup.number()
        .required('Campo obrigatório')
        .positive('Campo obrigatório')
        .typeError('Campo obrigatório'),
      SituacaoConjugal: Yup.number()
        .required('Campo obrigatório')
        .positive('Campo obrigatório')
        .typeError('Campo obrigatório'),

      TelefonePessoal: Yup.object().shape({
        Ddd: Yup.string().notRequired(),
        Numero: Yup.string().notRequired(),
      }),
      TelefoneResidencial: Yup.object().shape({
        Ddd: Yup.string().notRequired(),
        Numero: Yup.string().notRequired(),
      }),

      TempoResidencia: Yup.number()
        .required('Campo obrigatório')
        .positive('Campo obrigatório')
        .typeError('Campo obrigatório'),
      TipoImovel: Yup.number()
        .required('Campo obrigatório')
        .positive('Campo obrigatório')
        .typeError('Campo obrigatório'),
      Conjuge: Yup.object().when(
        'SituacaoConjugal',
        (SituacaoConjugal, schema) => {
          // console.log(SituacaoConjugal);
          return SituacaoConjugal === 2
            ? schema.shape({
                Cpf: Yup.string().required('Campo obrigatório').nullable(),
                Nome: Yup.string().required('Campo obrigatório').nullable(),
                DataNascimento: Yup.date()
                  .required('Campo obrigatório')
                  .nullable(),
                Telefone: Yup.object().shape({
                  Ddd: Yup.string().required('Campo obrigatório').nullable(),
                  Numero: Yup.string().required('Campo obrigatório').nullable(),
                }),
                Empresa: Yup.string().nullable(),
                Cargo: Yup.string().nullable(),
                Salario: Yup.string().notRequired().nullable(),
                TempoServico: Yup.string().nullable(),
                TelefoneComercial: Yup.object().shape({
                  Ddd: Yup.string().nullable(),
                  Numero: Yup.string().nullable(),
                }),
              })
            : schema.shape({
                Cpf: Yup.string().notRequired().nullable(),
                Nome: Yup.string().notRequired().nullable(),
                DataNascimento: Yup.string().nullable(),
                Telefone: Yup.object().shape({
                  Ddd: Yup.string().notRequired().nullable(),
                  Numero: Yup.string().notRequired().nullable(),
                }),
                Empresa: Yup.string().notRequired().nullable(),
                Cargo: Yup.string().notRequired().nullable(),
                Salario: Yup.string().notRequired().nullable(),
                TempoServico: Yup.string().nullable(),
                TelefoneComercial: Yup.object().shape({
                  Ddd: Yup.string().notRequired().nullable(),
                  Numero: Yup.string().notRequired().nullable(),
                }),
              });
        }
      ),
    }),

  });

  return validation;
};
export const ValidacaoEnvioPropostaNova = () => {
  Yup.addMethod(Yup.string, 'notEqualTo', notEqualTo);

  const validation = Yup.object().shape({

    tipoOperacao: Yup.string().required("Campo obrigatório"),
    tipoCliente: Yup.string().required("Campo obrigatório"),
    valorVista: Yup.string().required('Campo obrigatório'),
    valorEntrada: Yup.string().notRequired().nullable(),
    valorParcela: Yup.string().required("Calcule o valor da parcela"),
    tabelaComercial: Yup.string().required('Selecione uma tabela comercial'),
    plano: Yup.string().required('Informe um plano'),
    dataPrimeiraParcela: Yup.date().min(
      new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
      'A data deve ser no mínimo para 30 dias após a data atual'
    ),
    formaPagamentoCliente: Yup.string().notRequired(),
    cpfAtendente: Yup.string().required('Campo obrigatório'),
    lojaId: Yup.string().required('Campo obrigatório'),
    observacao: Yup.string().notRequired(),
    codigoPropostaLojista: Yup.string().notRequired(),

    cliente: Yup.object().shape({
      documento: Yup.string().required('Campo obrigatório'),
      nome: Yup.string().required('Campo obrigatório'),
      pessoaFisica: Yup.object().shape({


        dataNascimento: Yup.string()
        .required('Campo obrigatório'),
        rg: Yup.string().required("Campo obrigatório"),
        orgaoEmissorRg: Yup.string().required("Campo obrigatório"),
        ufOrgaoEmissorRg: Yup.string().required("Campo obrigatório"),
        dataEmissaoRg: Yup.string().required("Campo obrigatório"),
  
        mae: Yup.string().required("Campo obrigatório"),
        pai: Yup.string().notRequired(),
  
        sexo: Yup.string().required("Campo obrigatório"),
        estadoCivil: Yup.string().required("Campo obrigatório"),
        email: Yup.string().notRequired(),
  
  
        naturalidade: Yup.string().notRequired(),
        tipoResidencia: Yup.string().required("Campo obrigatório"),
        tempoResidencia: Yup.string().required("Campo obrigatório"),
        ocupacao: Yup.string().required("Campo obrigatório"),
        cargo: Yup.string().notRequired(),
        empresa: Yup.string().notRequired(),
        valorRenda: Yup.string().notRequired(),
        valorRendaExtra: Yup.string().notRequired(),
        tempoServico: Yup.string().notRequired(),
        falecido: Yup.string().notRequired(),
        observacao: Yup.string().notRequired(),
        apelido: Yup.string().notRequired(),
        quantidadeDependentes: Yup.string().notRequired(), 

      }),



      conjuge: Yup.object().when(
        'estadoCivil',
        (estadoCivil, schema) => {
          // console.log(estadoCivil);
          return estadoCivil === "Casado"
            ? schema.shape({
                nome: Yup.string().required('Campo obrigatório').nullable(),
                cpf: Yup.string().required('Campo obrigatório').nullable(),
                dataNascimento: Yup.date()
                  .required('Campo obrigatório')
                  .nullable(),
                valorRenda: Yup.string().notRequired().nullable(),
                empresa: Yup.string().nullable(),
                cargo: Yup.string().nullable(),
                tempoServico: Yup.string().nullable(),
              })
            : schema.shape({
              nome: Yup.string().notRequired().nullable(),
              cpf: Yup.string().notRequired().nullable(),
              dataNascimento: Yup.date()
                .notRequired()
                .nullable(),
              valorRenda: Yup.string().notRequired().nullable(),
              empresa: Yup.string().notRequired().nullable(),
              cargo: Yup.string().notRequired().nullable(),
              tempoServico: Yup.string().notRequired().nullable(),
              });
        }
      ),
      
    }),
    contaBancaria: Yup.object().shape({
      banco: Yup.string().required("Campo obrigatório"),
      agencia: Yup.string().required("Campo obrigatório"),
      digitoAgencia: Yup.string().notRequired(),
      conta: Yup.string().required("Campo obrigatório"),
      digitoConta: Yup.string().notRequired(),
      tipoContaBancaria: Yup.string().required("Campo obrigatório"),
    }),    
    


  });

  return validation;
};

export default ValidacaoEnvioProposta;
