export function signInRequest(data) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { data },
  };
}

export function signInSucess(data) {
  return {
    type: '@auth/SIGN_IN_SUCESS',
    payload: { data },
  };
}
export function signInFailure() {
  return {
    type: '@auth/SIGN_IN_FAILURE',
    payload: {},
  };
}

export function recuperarSenhaRequest(data) {
  return {
    type: '@auth/RECUPERAR_SENHA_REQUEST',
    payload: { data },
  };
}
export function recuperarSenhaSuccess(data) {
  return {
    type: '@auth/RECUPERAR_SENHA_SUCCESS',
    payload: { data },
  };
}
export function recuperarSenhaFailure() {
  return {
    type: '@auth/RECUPERAR_SENHA_FAILURE',
    payload: {},
  };
}

export function logoutRequest() {
  return {
    type: '@auth/LOGOUT_REQUEST',
    payload: {},
  };
}

export function logoutSuccess() {
  return {
    type: '@auth/LOGOUT_SUCCESS',
    payload: {},
  };
}
export function logoutFailure() {
  return {
    type: '@auth/LOGOUT_FAILURE',
    payload: {},
  };
}
