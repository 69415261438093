import React, { useState } from 'react';
import FileBase64 from 'react-file-base64';

export default function PDF() {
  const [listaArquivos, setListaArquivos] = useState(false);
  const getFiles = (files) => {
    setListaArquivos(files);
  };

  console.log(listaArquivos);

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <label>
            Enviar TA
            <FileBase64
              multiple
              name="documentosUpload"
              id="documentosUpload"
              onDone={getFiles}
            />
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2>Display file</h2>

          {listaArquivos.length > 0 && (
            <a
              href={`data:application/octet-stream;base64,${listaArquivos[0].base64.replace(
                /^data:application\/[a-z]+;base64,/,
                ''
              )}`}
              download="impressao.pdf"
            >
              Octet-stream
            </a>
          )}
          <br />
          {listaArquivos.length > 0 && (
            <a href={`${listaArquivos[0].base64}`} download="impressao.pdf">
              b64
            </a>
          )}

          <br />
          {listaArquivos.length > 0 && (
            <a
              href={`${listaArquivos[0].base64.replace(
                /^data:application\/[a-z]+;base64,/,
                ''
              )}`}
              download="impressao.pdf"
            >
              sem metadata
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
