import React, { useEffect, useState } from 'react';
import Header from '~/components/header';
import SidebarLeft from '~/components/sidebar-left';
import LoadingGif from "~/assets/images/loading.gif";
import ElleveAPI from '~/services/api';
import PropostaItem from './componentes/PropostaItem';
import history from '~/services/history';

// import { Container } from './styles';

const ConsultaNova = () => {

    const [pageLoading, setPageLoading] = useState(true)
    const [listaPropostas, setListaPropostas] = useState([]);



    useEffect(() => {
        ElleveAPI()
          .get('Proposta/painel')
          .then((response) => {
            setListaPropostas(response.data.value)
            setPageLoading(false);
          })
          .catch((error) => {
              setPageLoading(false)
              setListaPropostas([])
          });
    
        // console.log('Lista:', lista);
      }, []);


      const handleAtualizarLista = () => {
        history.go(0)  
      }

    if (pageLoading)
    return (
      <div className="full-height">
        <Header />
        <div className="container content-container loading">
          <div className="row">
            <img src={LoadingGif} alt="loading spinner" />
          </div>
        </div>
      </div>
    );

  if (!pageLoading)
    return (
      <div className="full-height">
        <Header />

        <div className="content-container full-height">
            <SidebarLeft />

            <main className="page-content ">


            
            <div className="legendas">
                  <span className="legenda analise">Em análise</span>
                  <span className="legenda aprovado">Aprovado</span>
                  <span className="legenda pendente">Pendente</span>
                  <span className="legenda reprovado">Reprovado</span>

                  <button type="button" className="btn-atualizar" onClick={handleAtualizarLista}>Atualizar Lista</button>
                </div>
            

            
              
              <div className="proposta-lista">

              
                {listaPropostas.length === 0 && (
                    <h2>Nenhuma proposta</h2>
                )}

                {listaPropostas.length > 0 && listaPropostas.map((proposta, index) => (
                    <PropostaItem key={proposta.id} proposta={proposta} />
                ))}

</div>
            </main>

        </div>
      </div>
    );
}

export default ConsultaNova;