import { Form } from '@unform/web';
import React, {useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Input from '~/components/CustomInputs/Input';
import ElleveAPI from '~/services/api';
import history from '~/services/history';
import { deletarPropostaRequest } from '~/store/modules/propostas/actions';
import FileBase64 from 'react-file-base64';

// import { Container } from './styles';

const PropostaItem = ({proposta}) => {
    // console.log(proposta)
    const [propostaClass, setPropostaClass] = useState('Temporaria');
    const [propostaSituacao, setPropostaSituacao] = useState('Temporaria');
    const [propostaObservacao, setPropostaObservacao] = useState('');

    useEffect(() => {

        if(proposta){
            ElleveAPI().get(`Proposta/resposta/${proposta.id}`).then((response) => {
                console.log(response.data.value)
                // console.log(response)
                setPropostaClass(response.data.value.situacao)
                setPropostaSituacao(response.data.value.situacao)
                setPropostaObservacao(response.data.value.mensagem);
            })
            .catch((error) => {
                console.log(error)
            })
        }
        
    }, [proposta])

    


    const dispatch = useDispatch();

    const [situacaoButtons, setSituacaoButtons] = useState({
        editar: false,
        deletar: false,
        gerarContrato: false,
        gerarBoleto: false,
    })

    useEffect(() => {
        switch(propostaSituacao){
            case 'Cancelada': {
                setSituacaoButtons({
                    editar: false,
                    deletar: true,
                    gerarContrato: false,
                    gerarBoleto: false,
                })
                break;
            }
            case 'Reprovada': {
                setSituacaoButtons({
                    editar: true,
                    deletar: true,
                    gerarContrato: false,
                    gerarBoleto: false,
                    enviarTa: true,

                })
                break;
            }
            case 'ReprovadaEncerrada': {
                setSituacaoButtons({
                    editar: false,
                    deletar: true,
                    gerarContrato: false,
                    gerarBoleto: false,
                })
                break;
            }
            case 'Aprovada': {
                setSituacaoButtons({
                    editar: true,
                    deletar: true,
                    gerarContrato: true,
                    gerarBoleto: false,
                })
                break;
            }
            case 'AprovadaEncerrada': {
                setSituacaoButtons({
                    editar: false,
                    deletar: true,
                    gerarContrato: true,
                    gerarBoleto: true,

                })
                break;
            }
            case 'Temporaria': {
                setSituacaoButtons({
                    editar: false,
                    deletar: true,
                    gerarContrato: false,
                    gerarBoleto: false,
                })
                break;
            }
            case 'EnviadoAnalise': {
                setSituacaoButtons({
                    editar: false,
                    deletar: true,
                    gerarContrato: false,
                    gerarBoleto: false,
                })
                break;
            }
            case 'EnviadoAtendente': {
                setSituacaoButtons({
                    editar: true,
                    deletar: true,
                    gerarContrato: false,
                    gerarBoleto: false,
                })
                break;
            }

            default:
        }
    }, [propostaSituacao])

    const HandleDeletarProposta = () => {
        if (window.confirm('Tem certeza que deseja deletar esta proposta?')) {
          dispatch(deletarPropostaRequest(proposta));
        }
      };

      const handleImprimirBoletos = () => {
          
        toast.info("Gerando boletos");
          ElleveAPI().get(`Proposta/boletos/${proposta.id}`).then((response) => {

            console.log(response.data.value.pdfStream)
              const {pdfStream}  = response.data.value;

              var ancorTag = document.createElement('a');
                ancorTag.href = `data:application/octet-stream;base64,${pdfStream}`;
                ancorTag.download = 'Boleto.pdf';
                document.body.appendChild(ancorTag);
                ancorTag.click();
                document.body.removeChild(ancorTag);
          }).catch((error) => {
              console.log(error.response)
              toast.error("Erro ao imprimir boletos")
          })
      }

      const handleGerarContrato = () => {
        if (window.confirm('Gerar contrato para a proposta?')) {
          ElleveAPI()
            .get(`Proposta/contrato/${proposta.id}`)
            .then((response) => {
                console.log(response.data)

                if(response.data.hasError){
                    toast.error(response.data.errors[0])
                }else{
                    toast.success("Contrato gerado")
                    history.go(0);
                }
            
            }).catch((error) => {
                console.log(error.response)
                toast.error(error.response.data.errors[0]);
            })
        }
      };

      const handleEditarProposta = () => {
          console.log(proposta)
          history.push(`/dashboard/editarproposta/${proposta.id}`);
      }


      const [displayModal, setDisplayModal] = useState(false)
      const handleEnviarTa = () => {
        setDisplayModal(true)
      }

      const [taForUpload, setTaForUpload] = useState(false);
      const getFiles = (file) => {
        const fileType = file.file.type.split('/');

          setTaForUpload({
              propostaId: proposta.id,
              documento: file[0].base64.replace(/^data:image\/[a-z]+;base64,/, '')
              .replace(/^data:application\/[a-z]+;base64,/, ''),
              tipoDocumento: "TA",
              extensao: fileType[1],

          })
      }

    //   console.log(taForUpload)

      const handleUploadTa = () => {
        toast.info("Fazendo upload de documento");
        ElleveAPI().post(`Proposta/documento`, taForUpload).then((response) => {

        //   console.log(response.data)
          toast.success("Upload realizado com sucesso")
          setDisplayModal(false)
          setTaForUpload(false)
            
        }).catch((error) => {
            console.log(error.response)
            toast.error("Erro ao fazer upload")
        })
      }




  return (
      <div className={`card card-body proposta-item-card ${propostaClass}`} >

          <Modal
            size="lg"
            show={displayModal}
            onHide={() => setDisplayModal(false)}
          >
              <Modal.Header closeButton>
                  <h3>Enviar TA</h3>
              </Modal.Header>

              <Modal.Body>
                  <div className="row mt-4 mb-4">
                      <div className="col-md">
                          <h4>Selecione o arquivo para enviar</h4>
                      </div>
                  </div>
                  <div className="row mt-4 mb-4">
                  <FileBase64
                    multiple
                    name="taUpload"
                    id="taUpload"
                    onDone={getFiles}
                  />
                </div>
                  
                  
              </Modal.Body>

              <Modal.Footer>
                  <div className="row">
                      <div className="col-md">
                          <button type="button" className="btn btn-success" disabled={!taForUpload} onClick={handleUploadTa}>Enviar TA</button>
                      </div>
                  </div>
              </Modal.Footer>

          </Modal>
          

          <div className="d-flex flex-row justify-content-start">
              <div className="w-75 pr-3">
                    

                    <h3 className="proposta-cliente">
                    <span className="badge badge-secondary mr-3">{new Date(proposta.dataProposta).toLocaleDateString()}</span>
                        {proposta.nomeCliente}
                        </h3>
                    
                    <div className="situacao-proposta">
                        {propostaSituacao}
                    </div>
                    <hr/>
                    <div className="observacao-proposta">
                        <h4>Observação:</h4>
                        <p>
                            {propostaObservacao}
                        </p>
                        
                    </div>
                    

              </div>
              <div className="w-25 ml-auto proposta-botoes">


                {situacaoButtons.editar && (
                    <a className="" href={`/dashboard/editarproposta/${proposta.id}`}>
                        Editar
                    </a>
                )}
                

                
                
                {situacaoButtons.gerarContrato && (
                    <button type="button" className="" onClick={handleGerarContrato}>
                        Gerar Contrato
                    </button>
                )}
                {situacaoButtons.enviarTa && (
                    <button type="button" className="" onClick={handleEnviarTa}>
                        Enviar TA
                    </button>
                )}
                {situacaoButtons.gerarBoleto && (
                    <button type="button" className="" onClick={handleImprimirBoletos}>
                        Imprimir Boletos
                    </button>
                )}
                

                    {situacaoButtons.deletar && (
                        <button type="button" className="" onClick={HandleDeletarProposta}>
                            Deletar
                        </button>
                    )}
                
              </div>

          </div>


      </div>
  );
}

export default PropostaItem;