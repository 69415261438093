import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  error: null,
  errors: null,
  proposta: null,
  success: null,
};

export default function propostas(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@propostas/ENVIAR_PROPOSTA_REQUEST': {
        // console.log(action);
        draft.loading = true;
        draft.success = false;
        draft.errors = false;
        break;
      }

      case '@propostas/ENVIAR_PROPOSTA_SUCCESS': {
        console.log(action.payload.data);
        draft.errors = null;
        draft.message = action.payload.data.mensagem;
        draft.loading = false;
        draft.success = true;
        break;
      }

      case '@propostas/ENVIAR_PROPOSTA_FAILURE': {
        console.log(action.payload.data);
        draft.loading = false;
        draft.error = true;
        draft.errors = action.payload.data.erros;
        draft.message = action.payload.data.Messsage;
        draft.success = false;
        console.log(action.payload.data);
        break;
      }




      case '@propostas/ENVIAR_PROPOSTA_NOVA_REQUEST': {
        // console.log(action);
        draft.loading = true;
        draft.success = false;
        draft.errors = false;
        break;
      }

      case '@propostas/ENVIAR_PROPOSTA_NOVA_SUCCESS': {
        console.log(action.payload.data);
        draft.errors = null;
        draft.message = action.payload.data.mensagem;
        draft.loading = false;
        draft.success = true;
        break;
      }

      case '@propostas/ENVIAR_PROPOSTA_NOVA_FAILURE': {
        // console.log(action.payload.data);
        draft.loading = false;
        draft.error = true;
        draft.errors = action.payload.data.errors ? action.payload.data.errors : [] ;
        draft.message = action.payload.data.title ? action.payload.data.title : "Error";
        draft.success = false;
        // console.log(action.payload.data);
        break;
      }



      case '@propostas/UPDATE_PROPOSTA_NOVA_REQUEST': {
        // console.log(action);
        draft.loading = true;
        draft.success = false;
        draft.errors = false;
        break;
      }

      case '@propostas/UPDATE_PROPOSTA_NOVA_SUCCESS': {
        console.log(action.payload.data);
        draft.errors = null;
        draft.message = action.payload.data.mensagem;
        draft.loading = false;
        draft.success = true;
        break;
      }

      case '@propostas/UPDATE_PROPOSTA_NOVA_FAILURE': {
        // console.log(action.payload.data);
        draft.loading = false;
        draft.error = true;
        draft.errors = action.payload.data.errors ? action.payload.data.errors : [] ;
        draft.message = action.payload.data.title ? action.payload.data.title : "Error";
        draft.success = false;
        // console.log(action.payload.data);
        break;
      }





      case '@propostas/UPDATE_PROPOSTA_REQUEST': {
        // console.log(action);
        draft.loading = true;
        draft.errors = null;
        draft.success = null;
        break;
      }

      case '@propostas/UPDATE_PROPOSTA_SUCCESS': {
        console.log(action.payload.data);
        draft.errors = null;
        draft.message = action.payload.data.Mensagem;
        draft.loading = false;
        draft.success = true;
        break;
      }

      case '@propostas/UPDATE_PROPOSTA_FAILURE': {
        console.log(action.payload.data);
        draft.loading = false;
        draft.error = true;
        draft.errors = action.payload.data.erros;
        draft.message = action.payload.data.Messsage;
        draft.success = null;
        break;
      }

      case '@propostas/DELETAR_PROPOSTA_REQUEST': {
        // console.log(action);
        draft.loading = true;
        break;
      }
      case '@propostas/DELETAR_PROPOSTA_SUCCESS': {
        // console.log(action);
        draft.loading = false;
        break;
      }

      case '@propostas/DELETAR_PROPOSTA_FAILURE': {
        // console.log(action);
        draft.loading = false;

        break;
      }
      case '@propostas/SELECIONAR_PROPOSTA_EDITAR_REQUEST': {
        // console.log(action);
        draft.loading = true;
        break;
      }
      case '@propostas/SELECIONAR_PROPOSTA_EDITAR_SUCCESS': {
        // console.log(action.payload.data);
        draft.proposta = action.payload.data;
        draft.loading = false;
        break;
      }

      case '@propostas/SELECIONAR_PROPOSTA_EDITAR_FAILURE': {
        draft.proposta = null;
        // console.log(action);
        draft.loading = false;

        break;
      }

      case '@propostas/LIMPAR_ERROS_REQUEST': {
        // console.log(action);
        // draft.loading = true;
        break;
      }
      case '@propostas/LIMPAR_ERROS_SUCCESS': {
        // console.log(action.payload.data);
        draft.errors = null;
        draft.error = null;
        draft.proposta = null;
        break;
      }
      default:
    }
  });
}
