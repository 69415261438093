import React, { useCallback, useState } from 'react';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

// import { Container } from './styles';

function BiometriaFacial({ callBackSendPhoto }) {
  const [currentFoto, setCurrentPhoto] = useState(false);
  const handleTakePhotoAnimationDone = (dataUri) => {
    // Do stuff with the photo...
    console.log(dataUri.split(',')[1]);
    if (dataUri) setCurrentPhoto(dataUri);
  };

  const handleEnviarFoto = () => {
    if (currentFoto) {
      callBackSendPhoto(currentFoto);
    }
  };

  return (
    <div className="biometria-foto">
      {currentFoto ? (
        <img src={currentFoto} alt="Biometria Facial" className="" />
      ) : (
        <Camera
          onTakePhotoAnimationDone={(dataUri) => {
            handleTakePhotoAnimationDone(dataUri);
          }}
        />
      )}

      <div className="biometria-foto-actions">
        <h4>Prévia da Imagem</h4>

        <div className="actions-container">
          <button
            type="button"
            className="btn-referencias rem"
            onClick={() => setCurrentPhoto(false)}
            disabled={!currentFoto}
          >
            Tirar outra
          </button>
          <button
            type="button"
            className="btn-referencias add"
            onClick={handleEnviarFoto}
            disabled={!currentFoto}
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
}

export default BiometriaFacial;
