import { all, takeLatest, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import cleanDeep from 'clean-deep';
import { store } from '../..';

import api, { ElleveAPI, fetchAgoraCred } from '../../../services/api';
import {
  deletarPropostaFailure,
  deletarPropostaSuccess,
  enviarPropostaFailure,
  enviarPropostaNovaFailure,
  enviarPropostaNovaSuccess,
  enviarPropostaSuccess,
  limparErrosDeValidacaoSuccess,
  selecionarPropostaParaEditarFailure,
  selecionarPropostaParaEditarSuccess,
  updatePropostaFailure,
  updatePropostaNovaFailure,
  updatePropostaNovaSuccess,
  updatePropostaSuccess,
} from './actions';
import history from '~/services/history';

const parseReferenciasBancarias = (referencias) => {
  return Object.values(referencias).map((ref) => {
    const filtered = { ...ref };

    filtered.CodigoBanco =
      ref.CodigoBanco && ref.CodigoBanco > 0
        ? parseInt(ref.CodigoBanco, 10)
        : null;
    filtered.Agencia = ref.Agencia && ref.Agencia > 0 ? parseInt(ref.Agencia, 10) : null; 
    return filtered;
  });
};
const parseTelefones = (telefones) => {
  if(!telefones) return null;
  return Object.values(telefones).map((ref) => {
    const filtered = { ...ref };
    return filtered;
  });
};
const parseEnderecos = (enderecos) => {
  if(!enderecos) return null;
  return Object.values(enderecos).map((ref) => {
    const filtered = { ...ref };
    filtered.numero = ref.numero && ref.numero > 0 ? parseInt(ref.numero, 10)  : null 
    return filtered;
  });
};
const parseReferencias = (referencias) => {
  if(!referencias) return null;
  return Object.values(referencias).map((ref) => {
    const filtered = { ...ref };
    filtered.tipoReferencia = parseInt(ref.tipoReferencia, 10);
    return filtered;
  });
};
const parseReferenciasBancariasNova = (referenciasBancarias) => {
  if(!referenciasBancarias) return null;
  return Object.values(referenciasBancarias).map((ref) => {
    const filtered = { ...ref };
    filtered.banco = ref.banco && ref.banco > 0 ? parseInt(ref.banco, 10) : null;
    filtered.agencia = ref.agencia && ref.agencia > 0 ? parseInt(ref.agencia, 10) : null;
    return filtered;
  });
};




const parseIntegerOrNull = (field) => {
  if(field){
    return parseInt(field, 10)
  }else{
    return null;
  }
}


const ajustaValoresDaRequestNovo = (data) => {


  try {

    // console.log(data)
    const parsedData = cleanDeep({
      codigoProposta: parseIntegerOrNull(data.codigoProposta),
      ...data,
      codigo: null,
      propostaId: parseIntegerOrNull(data.id),
      biometria: null,
      listaArquivos: null,
      valorVista: parseIntegerOrNull(data.valorVista),
      valorEntrada: parseIntegerOrNull(data.valorEntrada),
      valorParcela: parseIntegerOrNull(data.valorParcela),
      tabelaComercial: parseIntegerOrNull(data.tabelaComercial),
      plano: parseIntegerOrNull(data.plano),
      lojaId: parseIntegerOrNull(data.lojaId),
      codigoPropostaLojista: parseIntegerOrNull(data.codigoPropostaLojista),
      cliente: data.cliente ? {
        ...data.cliente,
  
        pessoaFisica: data.cliente.pessoaFisica ? {
          ...data.cliente.pessoaFisica,
          tempoResidencia: parseIntegerOrNull(data.cliente.pessoaFisica && data.cliente.pessoaFisica.tempoResidencia && data.cliente.pessoaFisica.tempoResidencia),
          ocupacao: parseIntegerOrNull(data.cliente.pessoaFisica && data.cliente.pessoaFisica.ocupacao && data.cliente.pessoaFisica.ocupacao),
          valorRenda: parseIntegerOrNull(data.cliente.pessoaFisica && data.cliente.pessoaFisica.valorRenda && data.cliente.pessoaFisica.valorRenda),
          valorRendaExtra: parseIntegerOrNull(data.cliente.pessoaFisica && data.cliente.pessoaFisica.valorRendaExtra && data.cliente.pessoaFisica.valorRendaExtra),
          tempoServico: parseIntegerOrNull(data.cliente.pessoaFisica && data.cliente.pessoaFisica.tempoServico && data.cliente.pessoaFisica.tempoServico),
          quantidadeDependentes: parseIntegerOrNull(data.cliente.pessoaFisica && data.cliente.pessoaFisica.quantidadeDependentes && data.cliente.pessoaFisica.quantidadeDependentes),
          falecido: data.cliente.pessoaFisica.falecido === 'Sim' ? true : false,
        } : null,
  
        
        
        
        conjuge: data.cliente.conjuge ? {
          ...data.cliente.conjuge,
          valorRenda: parseIntegerOrNull(data.cliente && data.cliente.conjuge.valorRenda),
          tempoServico: parseIntegerOrNull(data.cliente && data.cliente.conjuge.tempoServico),
        } : null,
        telefones: parseTelefones(data.cliente.telefones),
        enderecos: parseEnderecos(data.cliente.enderecos),
        referencias: parseReferencias(data.cliente.referencias),
        referenciasBancarias: parseReferenciasBancariasNova(data.cliente.referenciasBancarias)
      } : null,

      avalista: data.avalista ? {
        ...data.avalista,
  
        pessoaFisica: data.avalista.pessoaFisica ? {
          ...data.avalista.pessoaFisica,
          tempoResidencia: parseIntegerOrNull(data.avalista.pessoaFisica && data.avalista.pessoaFisica.tempoResidencia && data.avalista.pessoaFisica.tempoResidencia),
          ocupacao: parseIntegerOrNull(data.avalista.pessoaFisica && data.avalista.pessoaFisica.ocupacao && data.avalista.pessoaFisica.ocupacao),
          valorRenda: parseIntegerOrNull(data.avalista.pessoaFisica && data.avalista.pessoaFisica.valorRenda && data.avalista.pessoaFisica.valorRenda),
          valorRendaExtra: parseIntegerOrNull(data.avalista.pessoaFisica && data.avalista.pessoaFisica.valorRendaExtra && data.avalista.pessoaFisica.valorRendaExtra),
          tempoServico: parseIntegerOrNull(data.avalista.pessoaFisica && data.avalista.pessoaFisica.tempoServico && data.avalista.pessoaFisica.tempoServico),
          quantidadeDependentes: parseIntegerOrNull(data.avalista.pessoaFisica && data.avalista.pessoaFisica.quantidadeDependentes && data.avalista.pessoaFisica.quantidadeDependentes),
          falecido: data.avalista.pessoaFisica.falecido === 'Sim' ? true : false,
        } : null,
  
        
        
        
        conjuge: data.avalista.conjuge ? {
          ...data.avalista.conjuge,
          valorRenda: parseIntegerOrNull(data.avalista && data.avalista.conjuge.valorRenda),
          tempoServico: parseIntegerOrNull(data.avalista && data.avalista.conjuge.tempoServico),
        } : null,
        telefones: parseTelefones(data.avalista.telefones),
        enderecos: parseEnderecos(data.avalista.enderecos),
        referencias: parseReferencias(data.avalista.referencias),
        referenciasBancarias: parseReferenciasBancariasNova(data.avalista.referenciasBancarias)
      } : null,
      
      // avalista: data.avalista ? {
      //   ...data.avalista,
  
      //   pessoaFisica: data.avalista.pessoaFisica ? {
      //     ...data.avalista.pessoaFisica,
      //     tempoResidencia: parseIntegerOrNull(data.avalista.pessoaFisica && data.avalista.pessoaFisica.tempoResidencia && data.avalista.pessoaFisica.tempoResidencia),
      //     ocupacao: parseIntegerOrNull(data.avalista.pessoaFisica && data.avalista.pessoaFisica.ocupacao && data.avalista.pessoaFisica.ocupacao),
      //     valorRenda: parseIntegerOrNull(data.avalista.pessoaFisica && data.avalista.pessoaFisica.valorRenda && data.avalista.pessoaFisica.valorRenda),
      //     valorRendaExtra: parseIntegerOrNull(data.avalista.pessoaFisica && data.avalista.pessoaFisica.valorRendaExtra && data.avalista.pessoaFisica.valorRendaExtra),
      //     tempoServico: parseIntegerOrNull(data.avalista.pessoaFisica && data.avalista.pessoaFisica.tempoServico && data.avalista.pessoaFisica.tempoServico),
      //     quantidadeDependentes: parseIntegerOrNull(data.avalista.pessoaFisica && data.avalista.pessoaFisica.quantidadeDependentes && data.avalista.pessoaFisica.quantidadeDependentes),
      //     falecido: data.avalista.pessoaFisica.falecido === 'Sim' ? true : false,
      //   } : null,
            
      //   conjuge: data.avalista.conjuge ? {
      //     ...data.avalista.conjuge,
      //     valorRenda: parseIntegerOrNull(data.avalista && data.avalista.conjuge.valorRenda),
      //     tempoServico: parseIntegerOrNull(data.avalista && data.avalista.conjuge.tempoServico),
      //   } : null,
      //   telefones: parseTelefones(data.avalista.telefones),
      //   enderecos: parseEnderecos(data.avalista.enderecos),
      //   referencias: parseReferencias(data.avalista.referencias),
      //   referenciasBancarias: parseReferenciasBancariasNova(data.avalista.referenciasBancarias)
      // } : null,
     
  
      contaBancaria: data.contaBancaria ? {
        ...data.contaBancaria,
        banco: parseIntegerOrNull(data.contaBancaria.banco),
        agencia: parseIntegerOrNull(data.contaBancaria.agencia),
        conta: parseIntegerOrNull(data.contaBancaria.conta),
        tipoContaBancaria: parseIntegerOrNull(data.contaBancaria.tipoContaBancaria),
      } : null,
  
    })
    // console.log(parsedData)
    return parsedData  
  } catch (error) {
    
    console.log(error)
    
  }
  
  
}

const ajustaValoresDaRequest = (data) => {
  return cleanDeep({
    CodigoProposta: data.codigo ? parseInt(data.codigo, 10) : null,
    ...data,
    codigo: null,
    biometria: null,
    listaArquivos: null,
    ValorVista: data.ValorVista ? parseInt(data.ValorVista, 10) : null,
    ValorEntrada: data.ValorEntrada ? parseInt(data.ValorEntrada, 10) : null,
    TabelaComercial: data.TabelaComercial
      ? parseInt(data.TabelaComercial, 10)
      : null,
    Plano: data.Plano ? parseInt(data.Plano, 10) : null,
    CodigoPedidoLojista: data.CodigoPedidoLojista
      ? parseInt(data.CodigoPedidoLojista, 10)
      : null,
    SeguroPrestamista:
      data.SeguroPrestamista && data.SeguroPrestamista === 'true' ? true : null,
    Cliente: {
      ...data.Cliente,
      QuantidadeDependentes:
        data.Cliente && data.Cliente.QuantidadeDependentes
          ? parseInt(data.Cliente.QuantidadeDependentes, 10)
          : null,
      Endereco: {
        ...data.Cliente.Endereco,
        Numero:
          data.Cliente && data.Cliente.Endereco && data.Cliente.Endereco.Numero
            ? parseInt(data.Cliente.Endereco.Numero, 10)
            : null,
      },
      EnderecoAnterior: {
        ...data.Cliente.EnderecoAnterior,
        Numero:
          data.Cliente &&
          data.Cliente.EnderecoAnterior &&
          data.Cliente.EnderecoAnterior.Numero
            ? parseInt(data.Cliente.EnderecoAnterior.Numero, 10)
            : null,
      },
      TempoResidencia:
        data.Cliente && data.Cliente.TempoResidencia
          ? parseInt(data.Cliente.TempoResidencia, 10)
          : null,
      Ocupacao: {
        ...data.Cliente.Ocupacao,
        Salario:
          data.Cliente.Ocupacao && data.Cliente.Ocupacao.Salario
            ? parseInt(data.Cliente.Ocupacao.Salario, 10)
            : null,
        OutrasRendas:
          data.Cliente.Ocupacao &&
          data.Cliente.Ocupacao.OutrasRendas &&
          data.Cliente.Ocupacao.OutrasRendas > 0
            ? parseInt(data.Cliente.Ocupacao.OutrasRendas, 10)
            : null,

        EnderecoComercial: {
          ...data.Cliente.Ocupacao.EnderecoComercial,
          Numero:
            data.Cliente &&
            data.Cliente.Ocupacao &&
            data.Cliente.Ocupacao.EnderecoComercial &&
            data.Cliente.Ocupacao.EnderecoComercial.Numero
              ? parseInt(data.Cliente.Ocupacao.EnderecoComercial.Numero, 10)
              : null,
        },
      },
      Conjuge: {
        ...data.Cliente.Conjuge,
        TempoServico:
          data.Cliente &&
          data.Cliente.Conjuge &&
          data.Cliente.Conjuge.TempoServico
            ? parseInt(data.Cliente.Conjuge.TempoServico, 10)
            : null,
        Salario:
          data.Cliente.Conjuge &&
          data.Cliente.Conjuge.Salario &&
          data.Cliente.Conjuge.Salario > 0
            ? parseInt(data.Cliente.Conjuge.Salario, 10)
            : null,
      },
      ReferenciasBancarias: parseReferenciasBancarias(
        data.Cliente.ReferenciasBancarias
      ),
    },
    ContaCliente: {
      ...data.ContaCliente,
      CodigoBanco:
        data.ContaCliente && data.ContaCliente.CodigoBanco
          ? parseInt(data.ContaCliente.CodigoBanco, 10)
          : null,
      Agencia:
        data.ContaCliente && data.ContaCliente.Agencia
          ? parseInt(data.ContaCliente.Agencia, 10)
          : null,
      Conta:
        data.ContaCliente && data.ContaCliente.Conta
          ? parseInt(data.ContaCliente.Conta, 10)
          : null,
    },

    Avalista: {
      ...data.Avalista,
      QuantidadeDependentes:
        data.Avalista && data.Avalista.QuantidadeDependentes
          ? parseInt(data.Avalista.QuantidadeDependentes, 10)
          : null,
      Endereco: {
        ...data.Avalista.Endereco,
        Numero:
          data.Avalista &&
          data.Avalista.Endereco &&
          data.Avalista.Endereco.Numero
            ? parseInt(data.Avalista.Endereco.Numero, 10)
            : null,
      },
      EnderecoAnterior: {
        ...data.Avalista.EnderecoAnterior,
        Numero:
          data.Avalista &&
          data.Avalista.EnderecoAnterior &&
          data.Avalista.EnderecoAnterior.Numero
            ? parseInt(data.Avalista.EnderecoAnterior.Numero, 10)
            : null,
      },
      TempoResidencia:
        data.Avalista && data.Avalista.TempoResidencia
          ? parseInt(data.Avalista.TempoResidencia, 10)
          : null,
      Ocupacao: {
        ...data.Avalista.Ocupacao,
        Salario:
          data.Avalista.Ocupacao && data.Avalista.Ocupacao.Salario
            ? parseInt(data.Avalista.Ocupacao.Salario, 10)
            : null,
        OutrasRendas:
          data.Avalista.Ocupacao &&
          data.Avalista.Ocupacao.OutrasRendas &&
          data.Avalista.Ocupacao.OutrasRendas > 0
            ? parseInt(data.Avalista.Ocupacao.OutrasRendas, 10)
            : null,

        EnderecoComercial: {
          ...data.Avalista.Ocupacao.EnderecoComercial,
          Numero:
            data.Avalista &&
            data.Avalista.Ocupacao &&
            data.Avalista.Ocupacao.EnderecoComercial &&
            data.Avalista.Ocupacao.EnderecoComercial.Numero
              ? parseInt(data.Avalista.Ocupacao.EnderecoComercial.Numero, 10)
              : null,
        },
      },
      Conjuge: {
        ...data.Avalista.Conjuge,
        TempoServico:
          data.Avalista &&
          data.Avalista.Conjuge &&
          data.Avalista.Conjuge.TempoServico
            ? parseInt(data.Avalista.Conjuge.TempoServico, 10)
            : null,
        Salario:
          data.Avalista.Conjuge &&
          data.Avalista.Conjuge.Salario &&
          data.Avalista.Conjuge.Salario > 0
            ? parseInt(data.Avalista.Conjuge.Salario, 10)
            : null,
      },
      ReferenciasBancarias: parseReferenciasBancarias(
        data.Avalista.ReferenciasBancarias
      ),
    },
  });
};

export function* enviarProposta({ payload }) {
  try {
    const parsedValues = ajustaValoresDaRequest(payload.data);
    console.log("JsonProposta: ",JSON.stringify(parsedValues))
    
    const { biometria, listaArquivos } = payload.data;

    const response = yield call(
      ElleveAPI().post,
      'Proposta',
      parsedValues
    );
    // console.log(response);
    // console.log(response);

    if (
      response.data &&
      response.data.erros &&
      response.data.erros.length > 0
    ) {
      toast.error(response.data.errors[0][0]);
      yield put(enviarPropostaFailure(response.data));
    } else if (
      response.data &&
      response.data.errors &&
      response.data.errors.length > 0
    ) {
      toast.error(response.data.errors[0][0]);
      yield put(updatePropostaFailure(response.data));
    } else {

      if (biometria) {
        const EnviaBiometriaFacial = yield call(
          ElleveAPI().post,
          'Proposta/biometria',
          biometria
        );
        console.log(EnviaBiometriaFacial)
      }

      if (listaArquivos) {
        yield all(
          listaArquivos.map((arquivo) => {
            // const parsedArquivo = {
            //   documento: arquivo.documento, // base64 sem data:image ou data:application
            //   indexador: response.data.codigo.toString(), // codigo da proposta convertido em string
            //   nomeArquivo: arquivo.nomeArquivo, // nome do arquivo, vindo direto do arquivo selecionado, com extensão (.png, .jpg, .pdf)
            //   tipoDocumento: arquivo.tipoDocumento, // flag do tipo de documento
            // };
            return call(ElleveAPI().post, 'Json/proposta/documento', arquivo);
          })
        );
      }

      toast.success('Proposta cadastrada  com sucesso');
      yield put(enviarPropostaSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    console.log(error.response);

    yield put(enviarPropostaFailure({ Erros: [{ Mensagem: 'Erro da API' }] }));

    toast.error(error.response.data.title);
  }
}


export function* updatePropostaNova({payload}){

  console.log(payload.data)
  const parsedValues = ajustaValoresDaRequestNovo(payload.data);
  console.log(parsedValues)
  const { biometria, listaArquivos } = payload.data;


  try {
    const response = yield call(
      ElleveAPI().put,
      `Proposta/${payload.data.id}`,
      parsedValues
    );
    if (biometria) {

      const biometriaData = {
        propostaId: response.data.value.propostaId,
        foto: biometria.foto,
      };
      const EnviaBiometriaFacial = yield call(
        ElleveAPI().post,
        'Proposta/biometria',
        biometriaData
      );
      console.log(EnviaBiometriaFacial)
    }

    if (listaArquivos) {
      yield all(
        listaArquivos.map((arquivo) => {
          const arquivoData = {
            propostaId: response.data.value.propostaId,
            documento: arquivo.documento,
            tipoDocumento: arquivo.tipoDocumento,
            extensao: arquivo.extensao,
          };
          return call(ElleveAPI().post, 'Proposta/documento', arquivoData);
        })
      );
    }

      toast.success('Proposta atualizada  com sucesso');
      console.log(response)
      yield put(updatePropostaNovaSuccess(response.data));
  } catch (error) {
    console.log(error)
    console.log(error.response)
    yield put(updatePropostaNovaFailure(error.response.data));

    toast.error("Erro ao atualizar proposta");
  }

}
export function* enviarPropostaNova({payload}){
  // console.log(payload.data)
  const parsedValues = ajustaValoresDaRequestNovo(payload.data);
  // console.log(parsedValues)
  const { biometria, listaArquivos } = payload.data;


  try {
    const response = yield call(
      ElleveAPI().post,
      'Proposta',
      parsedValues
    );



    if (
      response.data &&
      response.data.erros &&
      response.data.erros.length > 0
    ) {
      toast.error(response.data.errors[0][0]);
      yield put(enviarPropostaFailure(response.data));
    } else if (
      response.data &&
      response.data.errors &&
      response.data.errors.length > 0
    ) {
      toast.error(response.data.errors[0][0]);
      yield put(updatePropostaFailure(response.data));
    } else {

      if (biometria) {

        const biometriaData = {
          propostaId: response.data.value.propostaId,
          foto: biometria.foto,
          extensao: 'png',
        };
        const EnviaBiometriaFacial = yield call(
          ElleveAPI().post,
          'Proposta/biometria',
          biometriaData
        );
        // console.log(EnviaBiometriaFacial)
      }
  
      if (listaArquivos) {
        yield all(
          listaArquivos.map((arquivo) => {
            const arquivoData = {
              propostaId: response.data.value.propostaId,
              documento: arquivo.documento,
              tipoDocumento: arquivo.tipoDocumento,
              extensao: arquivo.extensao,
            };
            console.log(arquivoData)
            return call(ElleveAPI().post, 'Proposta/documento', arquivoData);
          })
        );
      }

      toast.success('Proposta cadastrada  com sucesso');
      yield put(enviarPropostaSuccess(response.data));
    }

  } catch (error) {
    // console.log(error)
    // console.log(error.response)
    // console.log("JSONProposta", JSON.stringify(error.response.confi));
    console.log("Erro: ",error.response)
    console.log("JSONProposta: ",error.response.config.data)
    yield put(updatePropostaNovaFailure(error.response.data));

    toast.error("Erro ao atualizar proposta");
  }

}

export function* updateProposta({ payload }) {
  try {
    console.log(payload.data);
    const parsedValues = ajustaValoresDaRequest(payload.data);
    // console.log(payload.data);

    const { biometria, listaArquivos } = payload.data;

    const response = yield call(
      ElleveAPI().put,
      `Proposta/${payload.data.id}`,
      parsedValues
    );

    console.log(response);

    if (
      response.data &&
      response.data.erros &&
      response.data.erros.length > 0
    ) {
      toast.error(response.data.erros[0].mensagem);
      // if(response.data.errors){
      //   response.data.errors.map((error1) => {
      //     // toast.error(error1)
      //   })
      // }
      yield put(updatePropostaFailure(response.data));
    } else if (
      response.data &&
      response.data.errors &&
      response.data.errors.length > 0
    ) {
      toast.error(response.data.errors[0]);
      yield put(updatePropostaFailure(response.data));
    } else {
      const ElleveApiJsonEnvio = {
        codigo: response.data.codigo.toString(),
        jsonEnvio: response.config.data,
        jsonRetorno: JSON.stringify(response.data),
        idFinanceira: 1,
        idMetodo: 3,
      };

      if (biometria) {
        const EnviaBiometriaFacial = yield call(
          ElleveAPI().post,
          'Proposta/biometria',
          biometria
        );
        console.log(EnviaBiometriaFacial);
      }

      if (listaArquivos) {
        yield all(
          listaArquivos.map((arquivo) => {
            // const parsedArquivo = {
            //   documento: arquivo.documento,
            //   indexador: response.data.codigo.toString(),
            //   nomeArquivo: arquivo.nomeArquivo,
            //   tipoDocumento: arquivo.tipoDocumento,
            // };
            return call(ElleveAPI().post, 'Json/proposta/documento', arquivo);
          })
        );
      }

      toast.success('Proposta atualizada com sucesso');

      yield put(updatePropostaSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    console.log(error.response);
    toast.error('Erro ao atualizar a proposta');
  }
}

export function* deletarProposta({ payload }) {
  try {
    console.log(payload.data);
    const response = yield call(
      ElleveAPI().delete,
      `Proposta/cancelamento/${payload.data.id}`
    );

    console.log(response)

    if(response.data.hasError){
      console.log(response.data.errors[0])
      toast.error(response.data.errors[0])
      yield put(deletarPropostaFailure());
    }else{
      toast.success('Proposta deletada com sucesso!');
      yield put(deletarPropostaSuccess(response.data));
    }

    
  } catch (error) {
    console.log(error);
    console.log(error.response);

    toast.error('Erro ao deletar a proposta');
    yield put(deletarPropostaFailure());
  }
}

export function* selecionarPropostaParaEditar({ payload }) {
  try {
    console.log(payload.data);
    yield put(selecionarPropostaParaEditarSuccess(payload.data));
  } catch (error) {
    yield put(selecionarPropostaParaEditarFailure());
  }
}

export function* limparErrosValidacao({ payload }) {
  try {
    yield put(limparErrosDeValidacaoSuccess());

    history.push('/dashboard');
    history.go(0);
  } catch (error) {
    console.log(error);
  }
}
export default all([
  takeLatest('@propostas/ENVIAR_PROPOSTA_REQUEST', enviarProposta),
  takeLatest('@propostas/ENVIAR_PROPOSTA_NOVA_REQUEST', enviarPropostaNova),
  takeLatest('@propostas/UPDATE_PROPOSTA_NOVA_REQUEST', updatePropostaNova),
  takeLatest('@propostas/UPDATE_PROPOSTA_REQUEST', updateProposta),
  takeLatest('@propostas/DELETAR_PROPOSTA_REQUEST', deletarProposta),
  takeLatest(
    '@propostas/SELECIONAR_PROPOSTA_EDITAR_REQUEST',
    selecionarPropostaParaEditar
  ),
  takeLatest('@propostas/LIMPAR_ERROS_REQUEST', limparErrosValidacao),
]);
