import React, { useEffect, useRef, useCallback } from 'react';
import { useField } from '@unform/core';

export const Select = ({ name, label, options, defaultValue, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, clearError, error } = useField(name);

  useEffect(() => {
    // console.log(defaultValue)
    if(defaultValue){
      inputRef.current.value = defaultValue;
    }
    
  }, [defaultValue]);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.value = value;
      },
      clearValue(ref) {
        ref.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <div className="form-group">
        <label htmlFor={fieldName}>{label}</label>
        <select
          ref={inputRef}
          name={fieldName}
          className={`form-control ${error ? 'form-error' : null}`}
          onFocus={clearError}
          {...rest}
          autoComplete="off"
          // defaultValue={defaultValue}
        >
          {!defaultValue && (
            <option value="" selected disabled>
              Selecione uma opção
            </option>
          )}

          {options &&
            options.length > 0 &&
            options.map((option, index) => {              
              return (
                <option
                  key={index}
                  
                  selected={
                    defaultValue && defaultValue === option.id
                      ? 'selected'
                      : ''
                  }
                  value={option.id}
                >
                  {option.label}
                </option>
              );
            })}
        </select>
        {error && <span className="form-error-text">{error}</span>}
      </div>
    </>
  );
};

export default Select;
