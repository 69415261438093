import React, { useEffect, useRef, useCallback } from 'react';
import { useField } from '@unform/core';

export const Input = ({
  name,
  label,
  defaultValue,
  variant,
  type,
  nullable,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, clearError, error } = useField(name);

  useEffect(() => {
    if (defaultValue && !nullable) {
      inputRef.current.value = defaultValue;
    }

    if (nullable) {
      inputRef.current.value = null;
    }

    if (!nullable && !defaultValue) {
      inputRef.current.value = '';
    }
  }, []);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        // console.log(ref);
        if (value) {
          ref.value = value;
        } else {
          ref.value = '';
        }
      },
      clearValue(ref: any) {
        ref.value = '';
      },
    });
  }, [fieldName, registerField]);

  const handleOnChange = (value) => {
    if (inputRef.current) inputRef.current.value = value;
  };

  return (
    <>
      {type === 'hidden' ? (
        <input
          type={type}
          ref={inputRef}
          name={fieldName}
          onChange={(e) => handleOnChange(e.target.value)}
          {...rest}
        />
      ) : (
        <div className="form-group">
          {variant ? null : <label htmlFor={fieldName}>{label}</label>}

          <input
            type={type}
            ref={inputRef}
            onFocus={clearError}
            name={fieldName}
            className={`form-control ${error ? 'form-error' : null} ${
              variant || null
            }`}
            onChange={(e) => handleOnChange(e.target.value)}
            {...rest}
          />
          {error && <span className="form-error-text">{error}</span>}
        </div>
      )}
    </>
  );
};

export default Input;
