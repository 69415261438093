import produce from 'immer';
import history from '~/services/history';

const INITIAL_STATE = {
  signed: false,
  loading: false,
  access_token: null,
  refresh_token: null,
  profile: null,
  error: null,
  message: null,
  client_id: 'usuario',
  secret_id: 'senha',

  elleveToken: null,
  tempPassword: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        // console.log(action);
        draft.loading = true;
        break;
      }

      case '@auth/SIGN_IN_SUCESS': {
        console.log(action.payload.data);
        draft.loading = false;
        draft.signed = true;
        draft.elleveToken = action.payload.data.Elleve.token.accessToken;
        draft.profile = {
          nome: action.payload.data.Elleve.nome,
          cpf: action.payload.data.Elleve.cpf,
          id: action.payload.data.Elleve.id,
        };
        break;
      }

      case '@auth/SIGN_IN_FAILURE': {
        draft.loading = false;
        draft.error = true;
        draft.profile = null;
        break;
      }

      case '@auth/RECUPERAR_SENHA_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/RECUPERAR_SENHA_SUCCESS': {
        draft.loading = false;
        draft.success = true;
        draft.tempPassword = action.payload.data.value.password;
        break;
      }
      case '@auth/RECUPERAR_SENHA_FAILURE': {
        draft.loading = false;
        draft.success = false;
        draft.error = true;
        break;
      }

      case '@auth/LOGOUT_REQUEST': {
        break;
      }
      case '@auth/LOGOUT_SUCCESS': {
        draft.access_token = null;
        draft.refresh_token = null;
        draft.signed = false;
        draft.profile = null;
        draft.elleveToken = null;
        history.push('/');
        // history.go(0);
        break;
      }
      case '@auth/LOGOUT_FAILURE': {
        draft.access_token = null;
        draft.refresh_token = null;
        draft.signed = false;
        draft.profile = null;
        draft.elleveToken = null;
        history.push('/');
        // history.go(0);
        break;
      }
      default:
    }
  });
}
