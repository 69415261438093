import React from 'react';
import { useDispatch } from 'react-redux';
import { logoutRequest } from '~/store/modules/auth/actions';
import logo from '../assets/images/logo.svg';

function Header() {
  const dispatch = useDispatch();
  const HandleLogout = () => {
    console.log('Logout');
    dispatch(logoutRequest());
  };
  return (
    <header className="container-fluid fixed header-bg">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-11">
            <a href="/" title="Elleven Soluções Financeiras">
              <img
                src={logo}
                alt="Elleven Soluções Financeiras"
                title="Elleven Soluções Financeiras"
              />
            </a>
          </div>
          <div className="col-1">
            <button
              type="button"
              className="btn-logout"
              onClick={HandleLogout}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
