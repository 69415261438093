// // core
// import React from 'react';
// import { Router } from 'react-router-dom';

// // STORE
// import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';

// // Reactotron
// import './config/ReactotronConfig';

// // components
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// import Routes from './routes';
// import history from './services/history';

// // styleguides
// import './assets/sass/styleguide.scss';

// import { store, persistor } from './store';

// function App() {
//   return (
//     <Provider store={store}>
//       <PersistGate persistor={persistor}>
//         <Router history={history}>
//           <Routes />
//           <ToastContainer
//             style={{
//               maxWidth: '400px',
//               width: 'auto',
//             }}
//             autoClose={4000}
//           />
//         </Router>
//       </PersistGate>
//     </Provider>
//   );
// }

// export default App;

import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { PersistGate } from 'redux-persist/integration/react';
import './config/ReactotronConfig';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import '../node_modules/react-toastify/dist/hooks/';


import Routes from './routes';
import history from './services/history';

import './assets/sass/styleguide.scss';

import { persistor, store } from './store';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
        
          <Routes />
          
        </Router>
      </PersistGate>
      <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            
          />
    </Provider>
  );
}

export default App;
