import { all, takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import qs from 'qs';
import querystring from 'querystring';
import { toast } from 'react-toastify';
import { useState } from 'react';
import {
  signInSucess,
  signInFailure,
  recuperarSenhaSuccess,
  recuperarSenhaFailure,
  logoutSuccess,
  logoutFailure,
} from './actions';

import api, { ElleveAPI } from '../../../services/api';
import history from '~/services/history';
import { store } from '../..';

const axiosPost = async () => {
  const reqData = {
    username: '35308566000160',
    password: 'ach$208518',
    grant_type: 'password',
    cpf_atendente: '15506302703',
  };

  const url = `https://hom-webservice.agoracred.com.br/ApiCredito/token`;

  const response = axios.post(url, querystring.stringify(reqData), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

const fetchToken = () => {
  const url = 'https://hom-webservice.agoracred.com.br/ApiCredito/token';
  const request = {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
    body:
      'grant_type=password&password=ach$208518&username=35308566000160&cpf_atendente=15506302703',
    mode: 'cors',
  };

  return fetch(url, request);
};

async function getToken() {
  const url = 'https://hom-webservice.agoracred.com.br/ApiCredito/token';
  const request = {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
    body:
      'grant_type=password&password=ach$208518&username=35308566000160&cpf_atendente=15506302703',
    mode: 'cors',
  };

  const response = await fetch(url, request);
  return response.json();
}

export function* signIn({ payload }) {
  try {
    const elleveLoginData = {
      userName: payload.data.userName,
      password: payload.data.password,
      grantTypes: 'password',
      captchaId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      resultado: 0,
    };

    const ElleveApiLogin = yield call(
      ElleveAPI().post,
      'login',
      elleveLoginData
    );

    console.log(ElleveApiLogin);

    // history.push('/dashboard');
    yield put(
      signInSucess({
        Elleve: ElleveApiLogin.data.value,
      })
    );
  } catch (error) {
    // console.log(error);
    // console.log(error.ElleveApiLogin);
    toast.error('Falha ao autenticar');

    yield put(signInFailure());
  }
}

export function* recuperarSenha({ payload }) {
  try {
    const response = yield call(
      ElleveAPI().post,
      'Login/senha/resetar',
      payload.data
    );

    console.log(response);
    console.log(response.status);
    if (response.status === 200) {
      yield put(recuperarSenhaSuccess(response.data));
      toast.success('Senha recuperada com successo!');
    }
    if (response.status === 204) {
      yield put(recuperarSenhaFailure());
      toast.error('Erro ao recuperar a senha');
    }
  } catch (error) {
    console.log(error);
    yield put(recuperarSenhaFailure());
    toast.error('Erro ao recuperar a senha');
  }
}

export function* logout({ payload }) {
  try {
    // const response = yield call(ElleveAPI().post, '/Login/Logout');
    // console.log(response);
    // // history.push('/');
    yield put(logoutSuccess());
    // history.go(0);
  } catch (error) {
    console.log(error);
    console.log(error.response);
    // toast.error('Sua sessão expirou, faça login novamente ');
    yield put(logoutFailure());
  }
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/RECUPERAR_SENHA_REQUEST', recuperarSenha),
  takeLatest('@auth/LOGOUT_REQUEST', logout),
]);
