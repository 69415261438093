import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import Header from '~/components/header';
import {
  recuperarSenhaRequest,
  signInRequest,
} from '~/store/modules/auth/actions';
import logo from '~/assets/images/logopng.png';
import Input from '~/components/CustomInputs/Input';

const Recuperar = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.auth.loading);
  const success = useSelector((state) => state.auth.success);
  const tempPassword = useSelector((state) => state.auth.tempPassword);

  // console.log(loading);

  const handleReset = (data) => {
    // console.log(data);
    dispatch(recuperarSenhaRequest(data));
  };
  return (
    <div className="fullHeight login-container">
      {/* <Header /> */}

      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="my-3 d-flex justify-content-center align-items-center">
              <img
                src={logo}
                alt="Logo Elleve"
                className="login-logo img-fluid w-50 "
              />
            </div>
            {success ? (
              <div className="d-flex justify-content-center flex-column">
                <i
                  className="fa fa-check fa-2x validacao-success check-success"
                  aria-hidden="true"
                />

                <h4>PRONTO!</h4>
                {tempPassword && <h5>Sua nova senha é: {tempPassword}</h5>}
              </div>
            ) : (
              <Form ref={formRef} onSubmit={handleReset}>
                <h4>RECUPERAÇÃO DE SENHA</h4>
                <Input
                  type="text"
                  name="userName"
                  // label="Nome de Usuário"
                  required
                  variant="green"
                  placeholder="Nome de Usuário"
                />

                <div className="w-100 d-flex align-items-center">
                  <button
                    type="submit"
                    className="login-btn"
                    disabled={loading ? true : null}
                  >
                    {loading ? 'Carregando' : 'RECUPERAR SENHA'}
                  </button>
                </div>
              </Form>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 offset-md-3 pt-3 mt-3">
            <div className="d-flex flex-row justify-content-around align-items-center">
              <Link to="/">VOLTAR</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Recuperar;
