import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '~/services/history';
import { signInRequest } from '../store/modules/auth/actions';

// import { Container } from './styles';

function SidebarLeft() {
  const dispatch = useDispatch();
  const handleSignIn = () => {
    // console.log('Sign IN');
    dispatch(signInRequest({ teste: 'test' }));
  };
  return (
    <aside className="sidebar-left">
      <nav>
        <Link to="/dashboard" className="link-send" title="Enviar proposta">
          Enviar proposta
        </Link>
        {/* <Link to="/dashboard" className="link-send" title="Enviar proposta">
          Enviar proposta
        </Link> */}
        <Link
          to="/dashboard/consulta"
          className="link-search"
          title="Consultar proposta"
        >
          Consultar proposta
        </Link>

        {/* <Link
          to="#"
          className=""
          onClick={(e) => {
            e.preventDefault();
            history.go(0);
          }}
        >
          Atualizar
        </Link> */}

        {/* <button type="button" onClick={handleSignIn}>
          Sign In
        </button> */}
      </nav>
    </aside>
  );
}

export default SidebarLeft;
