import React, { useState, useEffect, useRef, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import { array } from 'yup';
import Modal from 'react-bootstrap/Modal';
import * as Yup from 'yup';
import axios from 'axios';
import cleanDeep from 'clean-deep';
import FileBase64 from 'react-file-base64';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import SidebarLeft from '../../components/sidebar-left';
import SidebarRight from '../../components/sidebar-right';
import uploadIcon from '~/assets/images/upload.svg';
import PDFIcon from '~/assets/images/pdf.png';

import {
  limparErrosDeValidacaoRequest,
  updatePropostaRequest,
} from '../../store/modules/propostas/actions';
import api, { ElleveAPI } from '../../services/api';
import Section from '~/components/Section';
import InputValor from '~/components/CustomInputs/InputValor';
import Input from '~/components/CustomInputs/Input';
import Select from '~/components/CustomInputs/Select';
import estados from '~/config/estados_cidades.json';
import MaskedInput from '~/components/CustomInputs/MaskedInput';
import TextArea from '~/components/CustomInputs/TextArea';
import {
  listaEstados,
  sexo,
  situacaoConjugal,
  tipoImovel,
  tipoAtividade,
  codigoBancos,
  formaPagamento,
  formaPagamentoUpdate,
  tipoConta,
} from '~/components/DataSources';
import ValidacaoEnvioProposta from '~/components/Validation/ValidacaoEnvioPropostas';
import LoadingImage from '~/assets/images/loading.gif';
import PreencherEndereco, {
  PreencherCidades,
} from '~/components/PreencherEndereco';
import history from '~/services/history';
import LoadingGif from '~/assets/images/loading.gif';
import AvalistaSections from '../Home/Partials/Avalista';
import BiometriaFacial from '~/components/BiometriaFacial';

function reverseCamelCase(o) {
  let newO;
  let origKey;
  let newKey;
  let value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === 'object') {
        value = reverseCamelCase(value);
      }
      return value;
    });
  }
  newO = {};
  for (origKey in o) {
    if (o.hasOwnProperty(origKey)) {
      newKey = (
        origKey.charAt(0).toUpperCase() + origKey.slice(1) || origKey
      ).toString();
      value = o[origKey];
      if (
        value instanceof Array ||
        (value !== null && value.constructor === Object)
      ) {
        value = reverseCamelCase(value);
      }
      newO[newKey] = value;
    }
  }

  return newO;
}

function Proposta() {
  const [biometriaShow, setBiometriaShow] = useState(false);
  const [envioParaAnalise, setEnvioParaAnalise] = useState(false);

  const propostaParaEditar = useSelector((state) => state.propostas.proposta);
  const token = useSelector((state) => state.auth.access_token);
  const profile = useSelector((state) => state.auth.profile);

  const [fixedPropostaParaEditar, setFixedPropostaParaEditar] = useState(false);
  // console.log('propostaParaEditar', propostaParaEditar);

  const handleSituacaoConjugal = (string) => {
    // console.log(string);
    // console.log(situacaoConjugal);
    const value = situacaoConjugal.filter((situacao) => {
      return situacao.label === string;
    });
    if (value.length > 0) {
      // console.log(value[0]);
      return value[0].id;
    }
  };

  const handleDefaultSelect = (where, value) => {
    console.log(where);
    const search = where.filter((item) => {
      return item.label === value;
    });
    if (search.length > 0) {
      return search[0].id;
    }
  };

  const handleDateInput = (value) => {
    // console.log('Initial', value);
    if(value){

      const returnDate = value.substring(0, 10);
      // console.log('Parsed', returnDate);
      return returnDate;
    }
  };

  useEffect(() => {
    if (!propostaParaEditar) {
      // history.push('/dashboard/consulta');
      // history.go(0);
    } else {
      // console.log(propostaParaEditar);
      ElleveAPI()
        .get(`Proposta/${propostaParaEditar.id}`)
        .then((response) => {
          // console.log(response.data);
          const propostaParseData = {
            ...response.data.value,
            // codigo: propostaParaEditar.codigoProposta,
          };
          console.log(propostaParseData)
          const cameldCase = reverseCamelCase(propostaParseData);
          console.log(cameldCase);
          const propostaFixedFields = {
            ...cameldCase,
            Cliente: {
              ...cameldCase.Cliente,
              SituacaoConjugal: handleSituacaoConjugal(
                cameldCase.Cliente.SituacaoConjugal
              ),
              DataNascimento: handleDateInput(
                cameldCase.Cliente.DataNascimento
              ),
              Rg: {
                ...cameldCase.Cliente.Rg,
                DataExpedicao: handleDateInput(
                  cameldCase.Cliente.Rg.DataExpedicao
                ),
              },

              Sexo: handleDefaultSelect(sexo, cameldCase.Cliente.Sexo),
              TipoImovel: handleDefaultSelect(
                tipoImovel,
                cameldCase.Cliente.TipoImovel
              ),
              Ocupacao: {
                ...cameldCase.Cliente.Ocupacao,
                TipoAtividade: handleDefaultSelect(
                  tipoAtividade,
                  cameldCase.Cliente.Ocupacao.TipoAtividade
                ),
                Salario: cameldCase.Cliente.Ocupacao.Salario.toString(),
                OutrasRendas: cameldCase.Cliente.Ocupacao.OutrasRendas
                  ? cameldCase.Cliente.Ocupacao.OutrasRendas.toString()
                  : '0',
                DataAdmissao: handleDateInput(
                  cameldCase.Cliente.Ocupacao.DataAdmissao
                ),
              },
              Conjuge: cameldCase.Cliente.Conjuge
                ? {
                    ...cameldCase.Cliente.Conjuge,
                    Salario: cameldCase.Cliente.Conjuge.Salario.toString(),
                    DataNascimento: handleDateInput(
                      cameldCase.Cliente.Conjuge.DataNascimento
                    ),
                  }
                : null,
              ReferenciasBancarias: cameldCase.Cliente.ReferenciasBancarias
                ? cameldCase.Cliente.ReferenciasBancarias.map((refbancaria) => {
                    return {
                      ...refbancaria,
                      DataAberturaConta: handleDateInput(
                        refbancaria.DataAberturaConta
                      ),
                    };
                  })
                : null,
            },

            Avalista: cameldCase.Avalista
              ? {
                  ...cameldCase.Avalista,
                  SituacaoConjugal: handleSituacaoConjugal(
                    cameldCase.Avalista.SituacaoConjugal
                  ),
                  DataNascimento: handleDateInput(
                    cameldCase.Avalista.DataNascimento
                  ),
                  Rg: {
                    ...cameldCase.Avalista.Rg,
                    DataExpedicao: handleDateInput(
                      cameldCase.Avalista.Rg.DataExpedicao
                    ),
                  },

                  Sexo: handleDefaultSelect(sexo, cameldCase.Avalista.Sexo),
                  TipoImovel: handleDefaultSelect(
                    tipoImovel,
                    cameldCase.Avalista.TipoImovel
                  ),
                  Ocupacao: {
                    ...cameldCase.Avalista.Ocupacao,
                    TipoAtividade: handleDefaultSelect(
                      tipoAtividade,
                      cameldCase.Avalista.Ocupacao.TipoAtividade
                    ),
                    Salario: cameldCase.Avalista.Ocupacao.Salario.toString(),
                    OutrasRendas: cameldCase.Avalista.Ocupacao.OutrasRendas
                      ? cameldCase.Avalista.Ocupacao.OutrasRendas.toString()
                      : '0',
                    DataAdmissao: handleDateInput(
                      cameldCase.Avalista.Ocupacao.DataAdmissao
                    ),
                  },
                  Conjuge: cameldCase.Avalista.Conjuge
                    ? {
                        ...cameldCase.Avalista.Conjuge,
                        Salario: cameldCase.Avalista.Conjuge.Salario.toString(),
                        DataNascimento: handleDateInput(
                          cameldCase.Avalista.Conjuge.DataNascimento
                        ),
                      }
                    : null,
                  ReferenciasBancarias: cameldCase.Avalista.ReferenciasBancarias
                    ? cameldCase.Avalista.ReferenciasBancarias.map(
                        (refbancaria) => {
                          return {
                            ...refbancaria,
                            DataAberturaConta: handleDateInput(
                              refbancaria.DataAberturaConta
                            ),
                          };
                        }
                      )
                    : null,
                }
              : null,

            FormaPagamentoCliente: handleDefaultSelect(
              formaPagamentoUpdate,
              cameldCase.FormaPagamentoCliente
            ),
            ContaCliente: {
              ...cameldCase.ContaCliente,
              TipoConta: handleDefaultSelect(
                tipoConta,
                cameldCase.ContaCliente.TipoConta
              ),
            },
            DataPrimeiraParcela: handleDateInput(
              cameldCase.DataPrimeiraParcela
            ),
          };
          console.log('FixedFields', propostaFixedFields);
          setFixedPropostaParaEditar(propostaFixedFields);
        })
        .catch((error) => {
          setFixedPropostaParaEditar(false);
          toast.error('Erro ao obter os dados da proposta');
          // toast.error(error.response.data);
          console.log(error);
          console.log(error.response);
        });
    }
  }, [propostaParaEditar]);
  const currentPropostaErrors = useSelector((state) => state.propostas.errors);
  // console.log(currentPropostaErrors);
  const formRef = useRef(null);

  // console.log('Current Errors', currentPropostaErrors);
  const [pageLoading, setPageLoading] = useState(true);
  const [tabelasComerciais, setTabelasComerciais] = useState(false);
  const [planos, setPlanos] = useState(false);

  // console.log(propostaParaEditar)

  function toCamel(o) {
    let newO;
    let origKey;
    let newKey;
    let value;
    if (o instanceof Array) {
      return o.map(function (value) {
        if (typeof value === 'object') {
          value = toCamel(value);
        }
        return value;
      });
    }
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = (
          origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
        ).toString();
        value = o[origKey];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamel(value);
        }
        newO[newKey] = value;
      }
    }

    return newO;
  }

  // const fixedPropostaParaEditar = reverseCamelCase(propostaParaEditar)
  // console.log(fixedPropostaParaEditar)

  const [referenciasPessoais, setReferenciasPessoais] = useState(
    fixedPropostaParaEditar &&
      fixedPropostaParaEditar.Cliente &&
      fixedPropostaParaEditar.Cliente.ReferenciasPessoais
      ? fixedPropostaParaEditar.Cliente.ReferenciasPessoais.map(
          (referencia, index) => {
            return {
              id: index,
            };
          }
        )
      : [{ id: 0 }]
  );
  const [ReferenciasComerciais, setReferenciasComerciais] = useState(
    fixedPropostaParaEditar &&
      fixedPropostaParaEditar.Cliente &&
      fixedPropostaParaEditar.Cliente.ReferenciasComerciais
      ? fixedPropostaParaEditar.Cliente.ReferenciasComerciais.map(
          (referencia, index) => {
            return {
              id: index,
            };
          }
        )
      : [{ id: 0 }]
  );
  // console.log(ReferenciasComerciais);

  const [ReferenciasBancarias, setReferenciasBancarias] = useState(
    fixedPropostaParaEditar &&
      fixedPropostaParaEditar.Cliente &&
      fixedPropostaParaEditar.Cliente.ReferenciasBancarias
      ? fixedPropostaParaEditar.Cliente.ReferenciasBancarias.map(
          (referencia, index) => {
            // console.log(referencia);
            return {
              id: index,
            };
          }
        )
      : [{ id: 0 }]
  );

  const [newDate, setNewDate] = useState(false);
  const dispatch = useDispatch();

  const [biometriaFacialData, setBiometriaFacialData] = useState(false);

  const callBackSendPhoto = (fotoBiometria) => {
    setBiometriaFacialData(fotoBiometria);
    setBiometriaShow(false);
  };

  const [listaArquivos, setListaArquivos] = useState([]);
  const getFiles = (file) => {
    const currentFiles = listaArquivos.filter((arq) => {
      return arq.tipoDocumento !== file.tipo;
    });

    const filteredFile = {
      base64: file.file.base64,
      documento: file.file.base64
        .replace(/^data:image\/[a-z]+;base64,/, '')
        .replace(/^data:application\/[a-z]+;base64,/, ''),
      nomeArquivo: file.file.name,
      tipoDocumento: file.tipo,
    };

    const newArray = [...currentFiles, { ...filteredFile }];

    setListaArquivos(newArray);
  };

  const handleRemoverArquivo = (selected) => {
    setListaArquivos(
      listaArquivos.filter((arquivo) => {
        return arquivo.base64 !== selected.base64;
      })
    );
  };

  useEffect(() => {
    // console.log(propostaParaEditar);
    const { biometria, listaArquivos } = fixedPropostaParaEditar;
    if (biometria) {
      setBiometriaFacialData(biometria.foto);
    }

    if (listaArquivos && listaArquivos.length > 0) {
      const lista = listaArquivos.map((item) => {
        const filtered = {};
        filtered.base64 = item.documento;
        filtered.name = item.nomeArquivo;
        return filtered;
      });
      setListaArquivos(lista);
    }
  }, [fixedPropostaParaEditar]);

  // console.log(listaArquivos);

  // console.log(JSON.stringify(fixedPropostaParaEditar));

  const addFieldsToDataToSaveProposta = (data) => {
    return {
      ...data,
      biometria: biometriaFacialData
        ? {
            foto: biometriaFacialData.replace(
              /^data:image\/[a-z]+;base64,/,
              ''
            ),
            cpf: data.Cliente.Cpf,
            nome: data.Cliente.Nome,
            dataNascimento: data.Cliente.DataNascimento,
            nomeArquivo: 'biometriafacial.png',
          }
        : null,
      listaArquivos: listaArquivos
        ? listaArquivos.map((arquivo) => {
            const filtered = {};
            filtered.documento = arquivo.documento;
            filtered.indexador = data.Cliente.Cpf;
            filtered.nomeArquivo = arquivo.nomeArquivo;
            filtered.tipoDocumento = arquivo.tipoDocumento;
            return filtered;
          })
        : null,
    };
  };

  useEffect(() => {
    const now = new Date();
    // console.log(now.toISOString());

    const date = now.toDateString();
    // console.log(date);
    setNewDate(date);

    ElleveAPI()
      .get('Proposta/tabela/1')
      .then((response) => {
        // console.log(response.data);
        const tabelas = response.data.tabelasComerciais.map((tabela) => {
          const filtered = { ...tabela };
          filtered.id = tabela.id;
          filtered.label = tabela.nome;
          return filtered;
        });

        setTabelasComerciais(tabelas);

        setPageLoading(false);

        // const jsonResposta = {
        //   codigo: 'string',
        //   jsonEnvio: null,
        //   jsonRetorno: JSON.stringify(response.data),
        //   idFinanceira: 1,
        //   idMetodo: 1,
        // };

        // ElleveAPI()
        //   .post('Json/', jsonResposta)
        //   .then((resJson) => {});
      })
      .catch((error) => {
        console.log(error);
        setPageLoading(false);
      });
  }, []);

  const HandleReferencias = (referencia, setReferencia, type) => {
    // console.log('Ref: ', referencia);
    // console.log('SetRef: ', setReferencia());

    const ref = [...referencia];
    const newRef = [...referencia];

    switch (type) {
      case 'add': {
        const id = newRef.length === 0 ? 0 : newRef.splice(-1, 1)[0].id + 1;
        ref.push({
          id,
        });

        setReferencia(ref);
        break;
      }
      case 'rem': {
        if (newRef.length === 0) return;
        const { id } = ref.splice(-1, 1)[0];
        const removed = ref.filter((item) => item !== id);

        setReferencia(ref);
        break;
      }
      default:
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  const HandleCancelarCurrentProposta = () => {
    if (window.confirm('Cancelar a proposta atual?')) {
      dispatch(limparErrosDeValidacaoRequest());
    }
  };

  const handleShow = () => setShowModal(true);

  const [modalState, setModalState] = useState({
    loading: true,
    error: false,
    message: 'Validando os dados',
    buttons: [
      {
        id: 1,
        label: 'Cancelar',
        disabled: true,
        function: () => setShowModal(false),
      },
      {
        id: 2,
        label: 'Salvar Dados',
        disabled: true,
        function: () => {
          console.log('submited');
        },
      },
    ],
  });

  const currentPropostaSuccess = useSelector(
    (state) => state.propostas.success
  );

  useEffect(() => {
    if (currentPropostaErrors) {
      setModalState({
        loading: false,
        error: true,
        message: 'Resposta: Verifique os seguintes erros',
        listaErros: currentPropostaErrors,
        buttons: [
          {
            id: 1,
            label: 'Alterar Dados',
            disabled: false,
            function: () => setShowModal(false),
          },
          {
            id: 2,
            label: 'Enviar para análise',
            disabled: false,
            function: () => {
              // console.log('Current Data Sent:', formRef.current.getData());
              dispatch(
                updatePropostaRequest(
                  addFieldsToDataToSaveProposta(formRef.current.getData())
                )
              );
            },
          },
        ],
      });

      setEnvioParaAnalise(true);
    }

    if (envioParaAnalise) {
      setModalState({
        loading: true,
        error: false,
        message: 'Validando dados da proposta',
        // listaErros: currentPropostaErrors,
        buttons: [
          {
            id: 1,
            label: 'Alterar Dados',
            disabled: true,
            function: () => setShowModal(false),
          },
          {
            id: 2,
            label: 'Enviar para análise',
            disabled: true,
            function: () => {
              // console.log('Cleansed Data: ', formRef.current.getData());
              dispatch(
                updatePropostaRequest(
                  addFieldsToDataToSaveProposta(formRef.current.getData())
                )
              );
              setEnvioParaAnalise(false);
            },
          },
        ],
      });
    }

    if (currentPropostaSuccess) {
      setModalState({
        loading: false,
        error: false,
        message: 'Proposta atualizada com sucesso!',
        // listaErros: currentPropostaErrors,
        buttons: [
          {
            id: 1,
            label: 'Fechar',
            disabled: false,
            function: () => {
              setShowModal(false);
              history.push('/dashboard/consulta');
              history.go(0);
            },
          },
          // {
          //   id: 2,
          //   label: 'Enviar para análise',
          //   disabled: true,
          //   function: () => {
          //     console.log('submited');
          //     dispatch(enviarPropostaRequest(formRef.current.getData()));
          //   },
          // },
        ],
      });
    }
  }, [currentPropostaErrors, envioParaAnalise, currentPropostaSuccess]);

  const handleEnviarProposta = async (data, { reset }) => {
    console.log(data);
    setShowModal(!showModal);
    try {
      const schema = ValidacaoEnvioProposta();
      await schema.validate(data, {
        abortEarly: false,
      });
      setModalState({
        loading: false,
        error: false,
        message: 'Dados validados com sucesso',
        buttons: [
          {
            id: 1,
            label: 'Alterar Dados',
            disabled: false,
            function: () => setShowModal(false),
          },
          {
            id: 2,
            label: 'Enviar para análise',
            disabled: false,
            function: () => {
              // console.log('Current Data Sent:', formRef.current.getData());

              dispatch(
                updatePropostaRequest(addFieldsToDataToSaveProposta(data))
              );
              setEnvioParaAnalise(true);
            },
          },
        ],
      });
    } catch (err) {
      const validationErrors = {};
      formRef.current.setErrors({});

      setModalState({
        loading: false,
        error: true,
        message: `Verifique os dados do formulário. Foram encontrados ${err.errors.length} erros. `,
        buttons: [
          {
            id: 1,
            label: 'Alterar Dados',
            disabled: false,
            function: () => setShowModal(false),
          },
          {
            id: 2,
            label: 'Salvar Dados',
            disabled: false,
            function: () => {
              // console.log('Current Data Sent:', formRef.current.getData());
              dispatch(
                updatePropostaRequest(addFieldsToDataToSaveProposta(data))
              );
            },
          },
        ],
      });
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          // console.log(error);
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      // dispatch(updatePropostaRequest(data));
    }
  };

  //   console.log(propostaData);

  //   useEffect(() => {
  //     console.log('dataProps', propostaData);
  //     if (formRef.current) {
  //       formRef.current.SetData({ propostaData });
  //     }
  //   }, [formRef.current]);

  // console.log('FormRef', formRef);
  useEffect(() => {
    if (formRef.current) {
      // console.log('Data');
      formRef.current.setData(fixedPropostaParaEditar);
    }
  });

  // console.log(propostaParaEditar);

  const parseDate = (refDate) => {
    const date = new Date(refDate).toLocaleDateString('pt-BR').split('/');
    const dateParsed = `${date[2]}/${date[1]}/${date[0]}`;
    // console.log(dateParsed);

    const now = Date.now();
    // console.log(new Date(now).toLocaleDateString('pt-BR'));

    return new Date(now).toDateString();
  };

  //



  let time = null;

  const [bancoFilter, setBancoFilter] = useState(false);
  const [bancoLoading, setBancoLoading] = useState(false);
  const handleSearchBanco = (busca) => {

    clearTimeout(time);
    time = setTimeout(() => {
      setBancoLoading(true)
      console.log(busca);

      if(busca.length === 0){
        setBancoFilter(false)
        setBancoLoading(false)
      }else{
        const listaFiltered = codigoBancos.filter((item) => {
          return item.label.toLowerCase().indexOf(busca.toLowerCase()) !== -1;
        });
        console.log(listaFiltered)
        setBancoFilter(listaFiltered)
        setBancoLoading(false)
  
      }


    },500)


  }

  if (pageLoading)
    return (
      <div className="">
        <Header />
        <div className="container content-container loading">
          <div className="row">
            <img src={LoadingGif} alt="loading spinner" />
          </div>
        </div>
      </div>
    );
  if (!pageLoading) {
    return (
      <div>
        <Header />

        <div className="container content-container">
          <div className="row">
            <SidebarLeft />

            <main className="col-8 col-lg-7">
              <Form
                ref={formRef}
                onSubmit={handleEnviarProposta}
                id="frm-envio"
              >
                <div className="accordion" id="accordionExample">
                  <Section title="Valores" expanded>
                    <div className="row">
                      <div className="col-md">
                        <Input type="hidden" name="Codigo" />
                        <InputValor
                          name="ValorVista"
                          defaultValue={
                            fixedPropostaParaEditar &&
                            fixedPropostaParaEditar.ValorVista &&
                            fixedPropostaParaEditar.ValorVista.toString()
                          }
                          label="Valor à Vista"
                        />
                      </div>
                      <div className="col-md">
                        <InputValor
                          name="ValorEntrada"
                          label="Valor da Entrada"
                          defaultValue={
                            fixedPropostaParaEditar &&
                            fixedPropostaParaEditar.ValorEntrada &&
                            fixedPropostaParaEditar.ValorEntrada.toString()
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <Input
                          name="DataPrimeiraParcela"
                          type="date"
                          label="Data da primeira parcela"
                          defaultValue={parseDate(
                            fixedPropostaParaEditar.DataPrimeiraParcela
                          )}
                        />
                      </div>
                      <div className="col-md">
                        <Select
                          name="TabelaComercial"
                          label="Tabela Comercial"
                          options={tabelasComerciais}
                          // defaultValue={fixedPropostaParaEditar.TabelaComercial}
                        />
                      </div>
                      <div className="col-md">
                        <Input
                          type="number"
                          label="Plano - Prazo em meses"
                          // defaultValue={fixedPropostaParaEditar.Plano}
                          name="Plano"
                        />
                        {/* <Select
                          name="Plano"
                          label="Plano"
                          options={[{ id: 4, label: 'Plano Default' }]}
                        /> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <Input
                          type="number"
                          name="CodigoPedidoLojista"
                          label="Código do pedido do Lojista"
                          // defaultValue={fixedPropostaParaEditar.CodigoPedidoLojista}
                        />
                      </div>
                      <div className="col-md">
                        <Input
                          type="text"
                          name="Vendedor"
                          label="Nome do vendedor"
                          disabled
                          defaultValue={profile.cpf}
                        />
                      </div>
                      <div className="col-md">
                        <Select
                          name="SeguroPrestamista"
                          label="Seguro prestamista?"
                          options={[
                            { id: true, label: 'Sim' },
                            { id: false, label: 'Não' },
                          ]}
                          // defaultValue={fixedPropostaParaEditar.SeguroPrestamista}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <TextArea
                          name="Observacao"
                          label="Observações"
                          // defaultValue={fixedPropostaParaEditar.Observacao}
                        />
                      </div>
                    </div>
                  </Section>

                  <Section title="Dados do Cliente">
                    <Scope path="Cliente">
                      <div className="row">
                        <div className="col-md">
                          <Input type="text" name="Nome" label="Nome" />
                        </div>
                        <div className="col-md">
                          <Input type="text" name="Apelido" label="Apelido" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md">
                          <MaskedInput
                            type="text"
                            mask="000.000.000-00"
                            name="Cpf"
                            label="CPF"
                            unmask
                          />
                        </div>
                        <div className="col-md">
                          <Input
                            name="DataNascimento"
                            type="date"
                            label="Data de nascimento"
                            defaultValue={newDate}
                          />
                        </div>
                      </div>

                      <Scope path="Rg">
                        <div className="row">
                          <div className="col-md">
                            <Input
                              type="text"
                              name="Numero"
                              label="Número do RG"
                            />
                          </div>
                          <div className="col-md">
                            <Input
                              type="text"
                              name="OrgaoExpedidor"
                              label="Órgão expeditor"
                            />
                          </div>
                          <div className="col-md">
                            <Select
                              name="Uf"
                              label="UF"
                              options={listaEstados}
                            />
                          </div>
                          <div className="col-md">
                            <Input
                              type="date"
                              name="DataExpedicao"
                              label="Data de expedicao"
                              defaultValue={newDate}
                            />
                          </div>
                        </div>
                      </Scope>

                      <div className="row">
                        <div className="col-md">
                          <Input
                            type="text"
                            name="Nacionalidade"
                            label="Nacionalidade"
                          />
                        </div>
                        <div className="col-md">
                          <Select
                            name="Naturalidade"
                            label="Naturalidade"
                            options={listaEstados}
                          />
                        </div>
                        <div className="col-md">
                          <Select name="Sexo" label="Sexo" options={sexo} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md">
                          <Input
                            type="text"
                            name="NomePai"
                            label="Nome do pai"
                          />
                        </div>
                        <div className="col-md">
                          <Input
                            type="text"
                            name="NomeMae"
                            label="Nome da mãe"
                          />
                        </div>
                        <div className="col-md">
                          <Select
                            name="SituacaoConjugal"
                            label="Situação conjugal"
                            options={situacaoConjugal}
                            // defaultValue={handleSituacaoConjugal(
                            //   fixedPropostaParaEditar.Cliente &&
                            //     fixedPropostaParaEditar.Cliente
                            //       .SituacaoConjugal &&
                            //     fixedPropostaParaEditar.Cliente.SituacaoConjugal
                            // )}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md">
                          <Input type="email" name="Email" label="E-mail" />
                        </div>
                        <div className="col-md">
                          <Input
                            type="number"
                            name="QuantidadeDependentes"
                            label="Quantidade de dependentes"
                          />
                        </div>
                      </div>
                      <Scope path="TelefonePessoal">
                        <h4 className="scope-title">Telefone Pessoal</h4>

                        <div className="row">
                          <div className="col-md-2">
                            <Input type="text" name="Ddd" label="DDD" />
                          </div>
                          <div className="col-md-10">
                            <MaskedInput
                              type="text"
                              name="Numero"
                              label="Número"
                              mask="0 0000 0000"
                              unmask
                            />
                          </div>
                        </div>
                      </Scope>
                      <Scope path="TelefoneResidencial">
                        <h4 className="scope-title">Telefone Residencial</h4>
                        <div className="row">
                          <div className="col-md-2">
                            <Input type="text" name="Ddd" label="DDD" />
                          </div>
                          <div className="col-md-10">
                            <MaskedInput
                              type="text"
                              name="Numero"
                              label="Número"
                              mask="0000 0000"
                              unmask
                            />
                          </div>
                        </div>
                      </Scope>
                      <div className="row">
                        <div className="col-md">
                          <TextArea name="Observacao" label="Observações" />
                        </div>
                      </div>
                    </Scope>
                  </Section>
                  <Section title="Residencia">
                    <Scope path="Cliente">
                      <Scope path="Endereco">
                        <h4 className="scope-title">Endereço</h4>

                        <div className="row">
                          <div className="col-md">
                            <MaskedInput
                              name="Cep"
                              label="CEP"
                              // lazy
                              defaultValue={
                                propostaParaEditar &&
                                fixedPropostaParaEditar.Cliente &&
                                fixedPropostaParaEditar.Cliente.Endereco &&
                                fixedPropostaParaEditar.Cliente.Endereco.Cep
                              }
                              mask="00.000-000"
                              unmask
                              onChange={() =>
                                PreencherEndereco(
                                  formRef,
                                  'Cliente.Endereco.Cep',
                                  'Cliente.Endereco'
                                )
                              }
                            />
                          </div>
                          <div className="col-md">
                            <Input
                              type="text"
                              name="Logradouro"
                              label="Logradouro"
                            />
                          </div>
                          <div className="col-md">
                            <Input
                              type="text"
                              name="Numero"
                              label="Número"
                              defaultValue="0"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md">
                            <Input
                              type="text"
                              name="Complemento"
                              label="Complemento"
                            />
                          </div>

                          <div className="col-md">
                            <Select
                              name="Uf"
                              label="Estado"
                              // defaultValue={enderecoClienteEstado}
                              options={listaEstados}
                            />
                          </div>
                          <div className="col-md">
                            <Select
                              name="Cidade"
                              label="Cidade"
                              options={
                                fixedPropostaParaEditar &&
                                PreencherCidades(
                                  fixedPropostaParaEditar.Cliente &&
                                    fixedPropostaParaEditar.Cliente.Endereco &&
                                    fixedPropostaParaEditar.Cliente.Endereco.Uf
                                )
                              }
                              // defaultValue={{
                              //   id: fixedPropostaParaEditar.Cliente.Endereco.Cidade,
                              //   label:
                              //     fixedPropostaParaEditar.Cliente.Endereco.Cidade,
                              // }}
                              // Todo select automático
                              // options={formRef.current.getFieldValue()}

                              //               estados.estados
                              // .filter(function (estado) {
                              //   return estado.sigla === response.data.uf;
                              // })[0]
                              // .cidades.map((cidade) => {
                              //   const filtered = {};
                              //   filtered.id = cidade;
                              //   filtered.label = cidade;
                              //   return filtered;
                              // })
                              // defaultValue={enderecoClienteCidade}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md">
                            <Input type="text" name="Bairro" label="Bairro" />
                          </div>
                          <div className="col-md">
                            <Input
                              type="text"
                              name="PontoReferencia"
                              label="Ponto de referencia"
                            />
                          </div>
                        </div>
                      </Scope>
                      <div className="row">
                        <div className="col-md">
                          <Input
                            name="TempoResidencia"
                            label="Tempo de residência (em meses)"
                            // mask="00 meses"
                            type="number"
                          />
                        </div>
                        <div className="col-md">
                          <Select
                            name="TipoImovel"
                            label="Tipo de imóvel"
                            options={tipoImovel}
                          />
                        </div>
                      </div>
                      <Scope path="EnderecoAnterior">
                        <h4 className="scope-title">Endereço Anterior</h4>

                        <div className="row">
                          <div className="col-md">
                            <MaskedInput
                              name="Cep"
                              label="CEP"
                              lazy
                              mask="00.000-000"
                              unmask
                              onChange={() =>
                                PreencherEndereco(
                                  formRef,
                                  'Cliente.EnderecoAnterior.Cep',
                                  'Cliente.EnderecoAnterior'
                                )
                              }
                            />
                          </div>
                          <div className="col-md">
                            <Input
                              type="text"
                              name="Logradouro"
                              label="Logradouro"
                            />
                          </div>
                          <div className="col-md">
                            <Input type="number" name="Numero" label="Número" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md">
                            <Input
                              type="text"
                              name="Complemento"
                              label="Complemento"
                            />
                          </div>

                          <div className="col-md">
                            <Select
                              name="Uf"
                              label="Estado"
                              options={listaEstados}
                            />
                          </div>
                          <div className="col-md">
                            <Select
                              name="Cidade"
                              label="Cidade"
                              // Todo select automático
                              options={
                                fixedPropostaParaEditar &&
                                fixedPropostaParaEditar.Cliente &&
                                fixedPropostaParaEditar.Cliente.EnderecoAnterior
                                  ? PreencherCidades(
                                      fixedPropostaParaEditar.Cliente
                                        .EnderecoAnterior.Uf
                                    )
                                  : {}
                              }
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md">
                            <Input type="text" name="Bairro" label="Bairro" />
                          </div>
                          <div className="col-md">
                            <Input
                              type="text"
                              name="PontoReferencia"
                              label="Ponto de referencia"
                            />
                          </div>
                        </div>
                      </Scope>
                    </Scope>
                  </Section>
                  <Section title="Trabalho e Renda">
                    <Scope path="Cliente">
                      <Scope path="Ocupacao">
                        <h4 className="scope-title">Ocupação</h4>
                        <div className="row">
                          <div className="col-md">
                            <Select
                              name="TipoAtividade"
                              label="Tipo de atividade"
                              options={tipoAtividade}
                            />
                          </div>
                          <div className="col-md">
                            <Input type="text" name="Empresa" label="Empresa" />
                          </div>
                          <div className="col-md">
                            <Input type="text" name="Cargo" label="Cargo" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md">
                            <InputValor
                              name="Salario"
                              label="Salário"
                              defaultValue={
                                fixedPropostaParaEditar &&
                                fixedPropostaParaEditar.Cliente &&
                                fixedPropostaParaEditar.Cliente.Ocupacao &&
                                fixedPropostaParaEditar.Cliente.Ocupacao.Salario.toString()
                              }
                            />
                          </div>
                          <div className="col-md">
                            <InputValor
                              name="OutrasRendas"
                              label="Outras rendas"
                              defaultValue={
                                fixedPropostaParaEditar &&
                                fixedPropostaParaEditar.Cliente &&
                                fixedPropostaParaEditar.Cliente.Ocupacao &&
                                fixedPropostaParaEditar.Cliente.Ocupacao.OutrasRendas.toString()
                              }
                              // defaultValue={
                              //   fixedPropostaParaEditar &&
                              //   fixedPropostaParaEditar.Cliente &&
                              //   fixedPropostaParaEditar.Cliente.Ocupacao &&
                              //   fixedPropostaParaEditar.Cliente.Ocupacao
                              //     .OutrasRendas &&
                              //   fixedPropostaParaEditar.Cliente.Ocupacao.OutrasRendas.toString()
                              // }
                              // nullable
                            />
                          </div>
                          <div className="col-md">
                            <Input
                              type="date"
                              name="DataAdmissao"
                              label="Data de admissão"
                              defaultValue={newDate}
                            />
                          </div>
                        </div>
                        <Scope path="TelefoneComercial">
                          <h4 className="scope-title">Telefone Comercial</h4>
                          <div className="row">
                            <div className="col-md-2">
                              <Input type="text" name="Ddd" label="DDD" />
                            </div>
                            <div className="col-md-10">
                              <MaskedInput
                                type="text"
                                name="Numero"
                                label="Número"
                                mask="0000 0000"
                                unmask
                              />
                            </div>
                          </div>
                        </Scope>
                        <Scope path="EnderecoComercial">
                          <h4 className="scope-title">Endereço Comercial</h4>

                          <div className="row">
                            <div className="col-md">
                              <MaskedInput
                                name="Cep"
                                label="CEP"
                                mask="00.000-000"
                                lazy
                                unmask
                                onChange={() =>
                                  PreencherEndereco(
                                    formRef,
                                    'Cliente.Ocupacao.EnderecoComercial.Cep',
                                    'Cliente.Ocupacao.EnderecoComercial'
                                  )
                                }
                              />
                            </div>
                            <div className="col-md">
                              <Input
                                type="text"
                                name="Logradouro"
                                label="Logradouro"
                              />
                            </div>
                            <div className="col-md">
                              <Input
                                type="number"
                                name="Numero"
                                label="Número"
                                // defaultValue={propostaParaEditar && fixedPropostaParaEditar.Cliente.EnderecoComercia}
                              />
                              {/* {console.log(fixedPropostaParaEditar.Cliente.Ocupacao)} */}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md">
                              <Input
                                type="text"
                                name="Complemento"
                                label="Complemento"
                              />
                            </div>

                            <div className="col-md">
                              <Select
                                name="Uf"
                                label="Estado"
                                options={listaEstados}
                              />
                            </div>
                            <div className="col-md">
                              <Select
                                name="Cidade"
                                label="Cidade"
                                // Todo select automático
                                // options={listaEstados}
                                options={
                                  fixedPropostaParaEditar &&
                                  fixedPropostaParaEditar.Cliente &&
                                  fixedPropostaParaEditar.Cliente.Ocupacao &&
                                  fixedPropostaParaEditar.Cliente.Ocupacao
                                    .EnderecoComercial
                                    ? PreencherCidades(
                                        fixedPropostaParaEditar.Cliente.Ocupacao
                                          .EnderecoComercial.Uf
                                      )
                                    : {}
                                }
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md">
                              <Input type="text" name="Bairro" label="Bairro" />
                            </div>
                            <div className="col-md">
                              <Input
                                type="text"
                                name="PontoReferencia"
                                label="Ponto de referencia"
                              />
                            </div>
                          </div>
                        </Scope>
                      </Scope>
                    </Scope>
                  </Section>

                  <Section title="Dados do Conjuge">
                    <Scope path="Cliente.Conjuge">
                      <div className="row">
                        <div className="col-md">
                          <MaskedInput
                            name="Cpf"
                            label="Cpf"
                            mask="000.000.000-00"
                            type="text"
                            unmask
                          />
                        </div>
                        <div className="col-md">
                          <Input type="text" name="Nome" label="Nome" />
                        </div>
                        <div className="col-md">
                          <Input
                            name="DataNascimento"
                            type="date"
                            label="Data de nascimento"
                            defaultValue={newDate}
                          />
                        </div>
                      </div>
                      <Scope path="Telefone">
                        <h4 className="scope-title">Telefone</h4>

                        <div className="row">
                          <div className="col-md-2">
                            <Input type="text" name="Ddd" label="DDD" />
                          </div>
                          <div className="col-md-10">
                            <MaskedInput
                              type="text"
                              name="Numero"
                              label="Número"
                              mask="0 0000 0000"
                              unmask
                            />
                          </div>
                        </div>
                      </Scope>
                      <h4 className="scope-title">Ocupação</h4>
                      <div className="row">
                        <div className="col-md">
                          <Input type="text" name="Empresa" label="Empresa" />
                        </div>
                        <div className="col-md">
                          <Input type="text" name="Cargo" label="Cargo" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md">
                          <InputValor
                            name="Salario"
                            label="Salário"
                            defaultValue={
                              fixedPropostaParaEditar &&
                              fixedPropostaParaEditar.Cliente &&
                              fixedPropostaParaEditar.Cliente.Conjuge &&
                              fixedPropostaParaEditar.Cliente.Conjuge.Salario.toString()
                            }
                            // defaultValue={fixedPropostaParaEditar.Cliente.Ocupacao.Salario.toString()}
                            // nullable
                          />
                        </div>

                        <div className="col-md">
                          <Input
                            type="number"
                            name="TempoServico"
                            label="Tempo de serviço em meses"
                          />
                        </div>
                      </div>
                      <Scope path="TelefoneComercial">
                        <h4 className="scope-title">Telefone Comercial</h4>
                        <div className="row">
                          <div className="col-md-2">
                            <Input type="text" name="Ddd" label="DDD" />
                          </div>
                          <div className="col-md-10">
                            <MaskedInput
                              type="text"
                              name="Numero"
                              label="Número"
                              mask="0000 0000"
                              unmask
                            />
                          </div>
                        </div>
                      </Scope>
                    </Scope>
                  </Section>
                  <Section title="Referências">
                    <Scope path="Cliente">
                      <h4 className="scope-title">Referências Pessoais</h4>
                      {referenciasPessoais.map((referencia) => {
                        return (
                          <Scope
                            key={referencia.id}
                            path={`ReferenciasPessoais[${referencia.id}]`}
                          >
                            <div className="row">
                              <div className="col-md-4">
                                <Input type="text" name="Nome" label="Nome" />
                              </div>
                              <Scope path="Telefone">
                                <div className="col-md-2">
                                  <Input type="text" name="Ddd" label="DDD" />
                                </div>
                                <div className="col-md-6">
                                  <MaskedInput
                                    type="text"
                                    name="Numero"
                                    label="Número"
                                    mask="0 0000 0000"
                                    unmask
                                  />
                                </div>
                              </Scope>
                            </div>
                          </Scope>
                        );
                      })}
                      <button
                        type="button"
                        className="btn-referencias add"
                        onClick={() =>
                          HandleReferencias(
                            referenciasPessoais,
                            setReferenciasPessoais,
                            'add'
                          )
                        }
                      >
                        Nova Referencia
                      </button>
                      <button
                        type="button"
                        className="btn-referencias rem"
                        disabled={!(referenciasPessoais.length > 0)}
                        onClick={() =>
                          HandleReferencias(
                            referenciasPessoais,
                            setReferenciasPessoais,
                            'rem'
                          )
                        }
                      >
                        Remover Referencia
                      </button>

                      <hr />
                      <h4 className="scope-title">Referências Comerciais</h4>

                      {ReferenciasComerciais.map((referencia) => {
                        return (
                          <Scope
                            key={referencia.id}
                            path={`ReferenciasComerciais[${referencia.id}]`}
                          >
                            <div className="row">
                              <div className="col-md-4">
                                <Input type="text" name="Nome" label="Nome" />
                              </div>
                              <Scope path="Telefone">
                                <div className="col-md-2">
                                  <Input type="text" name="Ddd" label="DDD" />
                                </div>
                                <div className="col-md-6">
                                  <MaskedInput
                                    type="text"
                                    name="Numero"
                                    label="Número"
                                    mask="0 0000 0000"
                                    unmask
                                  />
                                </div>
                              </Scope>
                            </div>
                          </Scope>
                        );
                      })}
                      <button
                        type="button"
                        className="btn-referencias add"
                        onClick={() =>
                          HandleReferencias(
                            ReferenciasComerciais,
                            setReferenciasComerciais,
                            'add'
                          )
                        }
                      >
                        Nova Referencia
                      </button>
                      <button
                        type="button"
                        className="btn-referencias rem"
                        disabled={!(ReferenciasComerciais.length > 0)}
                        onClick={() =>
                          HandleReferencias(
                            ReferenciasComerciais,
                            setReferenciasComerciais,
                            'rem'
                          )
                        }
                      >
                        Remover Referencia
                      </button>

                      <hr />
                      <h4 className="scope-title">Referências Bancárias</h4>
                      {ReferenciasBancarias.map((referencia) => {
                        return (
                          <Scope
                            key={referencia.id}
                            path={`ReferenciasBancarias[${referencia.id}]`}
                          >
                            {/* {console.log(referencia)} */}
                            <div className="row">
                              <div className="col-md">
                                <Select
                                  name="CodigoBanco"
                                  label="Código do banco"
                                  options={codigoBancos}
                                  // defaultValue={}
                                />
                              </div>
                              <div className="col-md">
                                <Input
                                  name="Agencia"
                                  label="Agência"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md">
                                <Input
                                  name="DigitoAgencia"
                                  label="Dígito da agência"
                                  type="number"
                                />
                              </div>
                              <div className="col-md">
                                <Input
                                  name="DataAberturaConta"
                                  label="Data abertura"
                                  type="date"
                                  defaultValue={newDate}
                                />
                              </div>
                            </div>
                            <hr />
                          </Scope>
                        );
                      })}

                      <button
                        type="button"
                        className="btn-referencias add"
                        // disabled={!(ReferenciasBancarias.length > 0)}
                        onClick={() =>
                          HandleReferencias(
                            ReferenciasBancarias,
                            setReferenciasBancarias,
                            'add'
                          )
                        }
                      >
                        Nova Referencia
                      </button>
                      <button
                        type="button"
                        className="btn-referencias rem"
                        onClick={() =>
                          HandleReferencias(
                            ReferenciasBancarias,
                            setReferenciasBancarias,
                            'rem'
                          )
                        }
                      >
                        Remover Referencia
                      </button>
                    </Scope>
                  </Section>
                  <AvalistaSections
                    listaEstados={listaEstados}
                    sexo={sexo}
                    situacaoConjugal={situacaoConjugal}
                    tipoImovel={tipoImovel}
                    formRef={formRef}
                    tipoAtividade={tipoAtividade}
                    codigoBancos={codigoBancos}
                  />
                  <Section title="Pagamento">
                    <h4 className="scope-title">Pagamento</h4>
                    <div className="row">
                      <div className="col-md">
                        <Select
                          name="FormaPagamentoCliente"
                          label="Forma de pagamento"
                          options={formaPagamento}
                        />
                      </div>
                    </div>

                    <Scope path="ContaCliente">

                    <div className="row">
                        <div className="col-md">
                          <Input type="text" label="Filtro para bancos" name="bancoSearch" onChange={(e) => handleSearchBanco(e.target.value)} />
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-md">
                          <Select
                            name="TipoConta"
                            label="Tipo de Conta"
                            options={tipoConta}
                          />
                        </div>

                        <div className="col-md">
                          <Select
                            name="CodigoBanco"
                            label="Código do banco"
                            options={
                              bancoLoading ? [{id: '', label: 'carregando'}] : bancoFilter ? bancoFilter : codigoBancos   
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <Input name="Agencia" label="Agência" type="number" />
                        </div>
                        <div className="col-md-3">
                          <Input
                            name="DigitoAgencia"
                            label="Dígito agência"
                            type="text"
                          />
                        </div>

                        <div className="col-md-3">
                          <Input name="Conta" label="Conta" type="number" />
                        </div>
                        <div className="col-md-3">
                          <Input
                            name="DigitoConta"
                            label="Digito da conta"
                            type="text"
                          />
                        </div>
                      </div>
                    </Scope>
                  </Section>

                  <Section title="Documentos e Fotos">
                    <div className="row">
                      <div className="col-md-12">
                        {/* <Input name="Agencia" label="Agência" type="number" /> */}
                        <h4>Biometria Facial</h4>
                        <div className="biometria-facial-formulario">
                          <button
                            type="button"
                            className="btn btn-default"
                            onClick={() => setBiometriaShow(!biometriaShow)}
                          >
                            {biometriaFacialData
                              ? 'Alterar Foto'
                              : 'Tirar Foto'}
                          </button>

                          {biometriaFacialData && (
                            <div className="row">
                              <img
                                src={biometriaFacialData}
                                alt="Biometria Facial"
                                className="biometria-facial-display"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="documentos-selecionados-upload-box">
                          <h4>Documentos</h4>

                          <label>
                            <span className="bg mt-3">
                              <span className="icone">
                                <img src={uploadIcon} />
                              </span>
                              <span className="texto">
                                Documento de identificacao.
                              </span>
                            </span>

                            <FileBase64
                              name="documentoIdentificacao"
                              id="documentoIdentificacao"
                              onDone={(file) => getFiles({ file, tipo: 2 })}
                            />
                          </label>

                          <div className="documentos-lista">
                            {listaArquivos &&
                              listaArquivos
                                .filter((arquivo) => {
                                  return arquivo.tipoDocumento === 2;
                                })
                                .map((arquivo) => {
                                  return (
                                    <div
                                      key={arquivo.base64}
                                      className="arquivo-preview"
                                    >
                                      <img
                                        src={arquivo.base64}
                                        alt="preview de arquivo"
                                        className="img-preview"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = PDFIcon;
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="img-preview-btn"
                                        onClick={() =>
                                          handleRemoverArquivo(arquivo)
                                        }
                                      >
                                        Remover
                                      </button>
                                    </div>
                                  );
                                })}
                          </div>

                          <label>
                            <span className="bg mt-3">
                              <span className="icone">
                                <img src={uploadIcon} />
                              </span>
                              <span className="texto">
                                Comprovante de renda.
                              </span>
                            </span>
                            <FileBase64
                              name="documentoIdentificacao"
                              id="documentoIdentificacao"
                              onDone={(file) => getFiles({ file, tipo: 3 })}
                            />
                          </label>

                          <div className="documentos-lista">
                            {listaArquivos &&
                              listaArquivos
                                .filter((arquivo) => {
                                  return arquivo.tipoDocumento === 3;
                                })
                                .map((arquivo) => {
                                  return (
                                    <div
                                      key={arquivo.base64}
                                      className="arquivo-preview"
                                    >
                                      <img
                                        src={arquivo.base64}
                                        alt="preview de arquivo"
                                        className="img-preview"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = PDFIcon;
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="img-preview-btn"
                                        onClick={() =>
                                          handleRemoverArquivo(arquivo)
                                        }
                                      >
                                        Remover
                                      </button>
                                    </div>
                                  );
                                })}
                          </div>

                          <label>
                            <span className="bg mt-3">
                              <span className="icone">
                                <img src={uploadIcon} />
                              </span>
                              <span className="texto">
                                Comprovante de endereço.
                              </span>
                            </span>

                            <FileBase64
                              name="comprovanteEndereco"
                              id="comprovanteEndereco"
                              onDone={(file) => getFiles({ file, tipo: 4 })}
                            />
                          </label>
                          <div className="documentos-lista">
                            {listaArquivos &&
                              listaArquivos
                                .filter((arquivo) => {
                                  return arquivo.tipoDocumento === 4;
                                })
                                .map((arquivo) => {
                                  return (
                                    <div
                                      key={arquivo.base64}
                                      className="arquivo-preview"
                                    >
                                      <img
                                        src={arquivo.base64}
                                        alt="preview de arquivo"
                                        className="img-preview"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = PDFIcon;
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="img-preview-btn"
                                        onClick={() =>
                                          handleRemoverArquivo(arquivo)
                                        }
                                      >
                                        Remover
                                      </button>
                                    </div>
                                  );
                                })}
                          </div>
                          <label>
                            <span className="bg mt-3">
                              <span className="icone">
                                <img src={uploadIcon} />
                              </span>
                              <span className="texto">Outros.</span>
                            </span>

                            <FileBase64
                              name="outrosArquivos"
                              id="outrosArquivos"
                              onDone={(file) => getFiles({ file, tipo: 6 })}
                            />
                          </label>
                          <div className="documentos-lista">
                            {listaArquivos &&
                              listaArquivos
                                .filter((arquivo) => {
                                  return arquivo.tipoDocumento === 6;
                                })
                                .map((arquivo) => {
                                  return (
                                    <div
                                      key={arquivo.base64}
                                      className="arquivo-preview"
                                    >
                                      <img
                                        src={arquivo.base64}
                                        alt="preview de arquivo"
                                        className="img-preview"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = PDFIcon;
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="img-preview-btn"
                                        onClick={() =>
                                          handleRemoverArquivo(arquivo)
                                        }
                                      >
                                        Remover
                                      </button>
                                    </div>
                                  );
                                })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Section>
                </div>

                <div className="btn-container">
                  <button
                    type="button"
                    className="btn btn-default btn-lg"
                    onClick={HandleCancelarCurrentProposta}
                  >
                    Cancelar
                  </button>
                  <button type="submit" className="btn btn-primary btn-lg">
                    Enviar Proposta
                  </button>
                </div>
              </Form>
            </main>

            <SidebarRight />
          </div>
        </div>

        <Modal
          size="lg"
          show={biometriaShow}
          onHide={() => setBiometriaShow(!biometriaShow)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Biometria Facial</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BiometriaFacial callBackSendPhoto={callBackSendPhoto} />
          </Modal.Body>
        </Modal>

        <Modal size="lg" show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>CADASTRO DE NOVA PROPOSTA</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalState.loading && (
              <>
                <div className="w-100 d-flex align-items-center justify-content-center">
                  <img
                    src={LoadingImage}
                    alt="Loading spinner"
                    className="modal-loading"
                  />
                </div>
              </>
            )}

            <div className="validacao-wrapper">
              <div className="validacao-icon">
                {!modalState.loading && !modalState.error && (
                  <i
                    className="fa fa-check fa-4x validacao-success"
                    aria-hidden="true"
                  />
                )}
                {!modalState.loading && modalState.error && (
                  <i
                    className="fa fa-exclamation-circle fa-4x validacao-error"
                    aria-hidden="true"
                  />
                )}
              </div>
              <div className="validacao-text">{modalState.message}</div>

              {modalState.listaErros && (
                <ul className="list-group">
                  {modalState.listaErros.map((erro, key) => {
                    return (
                      <li key={key} className="list-group-item">
                        {erro.mensagem && erro.mensagem}
                        {!erro.mensagem && erro}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {modalState.buttons.map((button) => {
              return (
                <button
                  key={button.id}
                  type="button"
                  className="btn "
                  disabled={button.disabled}
                  onClick={() => button.function()}
                >
                  {button.label}
                </button>
              );
            })}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Proposta;
