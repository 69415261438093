import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useField } from '@unform/core';

import { IMaskInput } from 'react-imask';

export const InputValor = ({
  name,
  label,
  defaultValue,
  nullable,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, clearError, error } = useField(name);

  const [fieldValue, setFieldValue] = useState(null);

  useEffect(() => {
    // inputRef.current.value = 0;
    // console.log(defaultValue);
    // setFieldValue(inputRef.current.value);

    // if (defaultValue) {
    //   inputRef.current.value = defaultValue;
    //   setFieldValue(inputRef.current.value);
    // } else {
    //   inputRef.current.value = null;
    //   setFieldValue(null);
    // }

    if(defaultValue && nullable){
      inputRef.current.value = defaultValue;
      setFieldValue(defaultValue);
    }
    if (defaultValue && !nullable) {
      inputRef.current.value = defaultValue;
      setFieldValue(defaultValue);
    }

    if (nullable) {
      inputRef.current.value = '';
      setFieldValue('');
    }

    if (!nullable && !defaultValue) {
      inputRef.current.value = '0';
      setFieldValue('0');
    }
  }, []);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        // console.log(value.toString());
        if (value) {
          ref.value = value.toString();
        } else {
          ref.value = '';
        }
      },
      clearValue(ref: any) {
        ref.value = '';
      },
    });
  }, [fieldName, registerField]);

  const handleOnChange = useCallback((text) => {
    if (inputRef.current) inputRef.current.value = text;
    if (inputRef.current) setFieldValue(text);
  }, []);

  return (
    <>
      <div className="form-group">
        <label htmlFor={fieldName}>{label}</label>
        <IMaskInput
          id={fieldName}
          ref={inputRef}
          onFocus={clearError}
          name={fieldName}
          value={fieldValue}
          className={`form-control ${error ? 'form-error' : null}`}
          placeholder="R$ "
          mask="R$num"
          blocks={{
            num: {
              mask: Number,
              padFractionalZeros: true,
            },
          }}
          radix="."
          unmask
          onAccept={(value) => handleOnChange(value)}
          {...rest}
        />
        {error && <span className="form-error-text">{error}</span>}
      </div>
    </>
  );
};

export default InputValor;
