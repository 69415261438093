import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useField } from '@unform/core';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
// import CurrencyInput from 'react-currency-masked-input';
// import CurrencyInput from 'react-currency-input';
import { IMaskInput } from 'react-imask';

export const MaskedInput = ({
  name,
  label,
  mask,
  nullable,
  lazy,
  unmask,
  defaultValue,
  disabled,
  ...rest
}) => {
  const inputRef = useRef(null);
  const {
    fieldName,

    registerField,
    clearError,
    error,
  } = useField(name);
  const [fieldValue, setFieldValue] = useState();

  useEffect(() => {
    // setFieldValue(inputRef.current.value);

    // if (defaultValue) {
    //   inputRef.current.value = defaultValue;
    //   setFieldValue(inputRef.current.value);
    // } else {
    //   inputRef.current.value = null;
    //   setFieldValue(null);
    // }

    if (defaultValue && !nullable) {
      inputRef.current.value = defaultValue;
      setFieldValue(defaultValue);
    }

    if (nullable) {
      inputRef.current.value = '';
      setFieldValue('');
    }

    if (!nullable && !defaultValue) {
      inputRef.current.value = '';
      setFieldValue('');
    }
  }, []);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        // console.log(value);
        if (value) {
          ref.value = value;
        }
      },
      clearValue(ref: any) {
        ref.value = '';
      },
    });
  }, [fieldName, registerField]);

  const handleOnChange = useCallback((text) => {
    if (inputRef.current) inputRef.current.value = text;
    if (inputRef.current) setFieldValue(text);
  }, []);

  return (
    <>
      <div className="form-group">
        <label htmlFor={fieldName}>{label}</label>
        <IMaskInput
          id={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          name={fieldName}
          onFocus={clearError}
          className={`form-control ${error ? 'form-error' : null}`}
          mask={mask}
          // lazy={lazy || false}
          value={(inputRef.current && inputRef.current.value) || defaultValue}
          unmask={!!unmask}
          onAccept={(value) => handleOnChange(value)}
          // onChange={onChange}
          // defaultChecked={defaultChecked}
          {...rest}
        />
        {error && <span className="form-error-text">{error}</span>}
      </div>
    </>
  );
};

export default MaskedInput;
