import { Scope } from '@unform/core';
import React, { useState } from 'react';
import Input from '~/components/CustomInputs/Input';
import InputValor from '~/components/CustomInputs/InputValor';
import MaskedInput from '~/components/CustomInputs/MaskedInput';
import Select from '~/components/CustomInputs/Select';
import TextArea from '~/components/CustomInputs/TextArea';
import PreencherEndereco from '~/components/PreencherEndereco';
import Section from '~/components/Section';

// import { Container } from './styles';

function AvalistaSections({
  listaEstados,
  sexo,
  situacaoConjugal,
  tipoImovel,
  formRef,
  tipoAtividade,
  codigoBancos,
}) {
  const [referenciasPessoais, setReferenciasPessoais] = useState([{ id: 0 }]);
  const [ReferenciasComerciais, setReferenciasComerciais] = useState([
    { id: 0 },
  ]);

  const [ReferenciasBancarias, setReferenciasBancarias] = useState([{ id: 0 }]);

  const HandleReferencias = (referencia, setReferencia, type) => {
    // console.log('Ref: ', referencia);
    // console.log('SetRef: ', setReferencia());

    const ref = [...referencia];
    const newRef = [...referencia];

    switch (type) {
      case 'add': {
        const id = newRef.length === 0 ? 0 : newRef.splice(-1, 1)[0].id + 1;
        ref.push({
          id,
        });

        setReferencia(ref);
        break;
      }
      case 'rem': {
        if (newRef.length === 0) return;
        const { id } = ref.splice(-1, 1)[0];
        const removed = ref.filter((item) => item !== id);

        setReferencia(ref);
        break;
      }
      default:
    }
  };

  return (
    <>
      <Section title="Avalista - Dados Pessoais">
        <Scope path="Avalista">
          <div className="row">
            <div className="col-md">
              <Input type="text" name="Nome" label="Nome" />
            </div>
            <div className="col-md">
              <Input type="text" name="Apelido" label="Apelido" />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <MaskedInput
                type="text"
                mask="000.000.000-00"
                name="Cpf"
                label="CPF"
                unmask
              />
            </div>
            <div className="col-md">
              <Input
                name="DataNascimento"
                type="date"
                label="Data de nascimento"
                // defaultValue={newDate}
              />
            </div>
          </div>

          <Scope path="Rg">
            <div className="row">
              <div className="col-md">
                <Input
                  type="text"
                  name="Numero"
                  label="Número do RG"
                  // mask="00.00.000"
                  // unmask
                />
              </div>
              <div className="col-md">
                <Input
                  type="text"
                  name="OrgaoExpedidor"
                  label="Órgão expeditor"
                />
              </div>
              <div className="col-md">
                <Select name="Uf" label="UF" options={listaEstados} />
              </div>
              <div className="col-md">
                <Input
                  type="date"
                  name="DataExpedicao"
                  label="Data de expedicao"
                  // defaultValue={newDate}
                />
              </div>
            </div>
          </Scope>

          <div className="row">
            <div className="col-md">
              <Input type="text" name="Nacionalidade" label="Nacionalidade" />
            </div>
            <div className="col-md">
              <Select
                name="Naturalidade"
                label="Naturalidade"
                options={listaEstados}
              />
            </div>
            <div className="col-md">
              <Select name="Sexo" label="Sexo" options={sexo} />
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <Input type="text" name="NomePai" label="Nome do pai" />
            </div>
            <div className="col-md">
              <Input type="text" name="NomeMae" label="Nome da mãe" />
            </div>
            <div className="col-md">
              <Select
                name="SituacaoConjugal"
                label="Situação conjugal"
                options={situacaoConjugal}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <Input type="email" name="Email" label="E-mail" />
            </div>
            <div className="col-md">
              <Input
                type="number"
                name="QuantidadeDependentes"
                label="Quantidade de dependentes"
                nullable
              />
            </div>
          </div>
          <Scope path="TelefonePessoal">
            <h4 className="scope-title">Telefone Pessoal</h4>

            <div className="row">
              <div className="col-md-2">
                <Input type="text" name="Ddd" label="DDD" />
              </div>
              <div className="col-md-10">
                <MaskedInput
                  type="text"
                  name="Numero"
                  label="Número"
                  mask="0 0000 0000"
                  unmask
                />
              </div>
            </div>
          </Scope>
          <Scope path="TelefoneResidencial">
            <h4 className="scope-title">Telefone Residencial</h4>
            <div className="row">
              <div className="col-md-2">
                <Input type="text" name="Ddd" label="DDD" />
              </div>
              <div className="col-md-10">
                <MaskedInput
                  type="text"
                  name="Numero"
                  label="Número"
                  mask="0000 0000"
                  unmask
                />
              </div>
            </div>
          </Scope>
          <div className="row">
            <div className="col-md">
              <TextArea
                name="Observacao"
                label="Observações"
                nullable
                defaultValue="aaa"
              />
            </div>
          </div>
        </Scope>
      </Section>

      <Section title="Avalista - Residência">
        <Scope path="Avalista">
          <Scope path="Endereco">
            <h4 className="scope-title">Endereço</h4>

            <div className="row">
              <div className="col-md">
                <MaskedInput
                  name="Cep"
                  label="CEP"
                  mask="00.000-000"
                  unmask
                  lazy
                  onChange={() =>
                    PreencherEndereco(
                      formRef,
                      'Avalista.Endereco.Cep',
                      'Avalista.Endereco'
                    )
                  }
                />
              </div>
              <div className="col-md">
                <Input type="text" name="Logradouro" label="Logradouro" />
              </div>
              <div className="col-md">
                <Input
                  type="text"
                  name="Numero"
                  label="Número"
                  // defaultValue="0"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <Input type="text" name="Complemento" label="Complemento" />
              </div>

              <div className="col-md">
                <Select
                  name="Uf"
                  label="Estado"
                  // defaultValue={enderecoClienteEstado}
                  options={listaEstados}
                />
              </div>
              <div className="col-md">
                <Select
                  name="Cidade"
                  label="Cidade"
                  options={{}}
                  // Todo select automático
                  // options={formRef.current.getFieldValue()}

                  //               estados.estados
                  // .filter(function (estado) {
                  //   return estado.sigla === response.data.uf;
                  // })[0]
                  // .cidades.map((cidade) => {
                  //   const filtered = {};
                  //   filtered.id = cidade;
                  //   filtered.label = cidade;
                  //   return filtered;
                  // })
                  // defaultValue={enderecoClienteCidade}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <Input type="text" name="Bairro" label="Bairro" />
              </div>
              <div className="col-md">
                <Input
                  type="text"
                  name="PontoReferencia"
                  label="Ponto de referencia"
                />
              </div>
            </div>
          </Scope>
          <div className="row">
            <div className="col-md">
              <Input
                name="TempoResidencia"
                label="Tempo de residência (em meses)"
                // mask="00 meses"
                type="number"
              />
            </div>
            <div className="col-md">
              <Select
                name="TipoImovel"
                label="Tipo de imóvel"
                options={tipoImovel}
              />
            </div>
          </div>
          <Scope path="EnderecoAnterior">
            <h4 className="scope-title">Endereço Anterior</h4>

            <div className="row">
              <div className="col-md">
                <MaskedInput
                  name="Cep"
                  label="CEP"
                  lazy
                  mask="00.000-000"
                  unmask
                  onChange={() =>
                    PreencherEndereco(
                      formRef,
                      'Avalista.EnderecoAnterior.Cep',
                      'Avalista.EnderecoAnterior'
                    )
                  }
                />
              </div>
              <div className="col-md">
                <Input type="text" name="Logradouro" label="Logradouro" />
              </div>
              <div className="col-md">
                <Input type="number" name="Numero" label="Número" />
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <Input type="text" name="Complemento" label="Complemento" />
              </div>

              <div className="col-md">
                <Select name="Uf" label="Estado" options={listaEstados} />
              </div>
              <div className="col-md">
                <Select
                  name="Cidade"
                  label="Cidade"
                  // Todo select automático
                  options={listaEstados}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <Input type="text" name="Bairro" label="Bairro" />
              </div>
              <div className="col-md">
                <Input
                  type="text"
                  name="PontoReferencia"
                  label="Ponto de referencia"
                />
              </div>
            </div>
          </Scope>
        </Scope>
      </Section>

      <Section title="Avalista - Trabalho e Renda">
        <Scope path="Avalista">
          <Scope path="Ocupacao">
            <h4 className="scope-title">Ocupação</h4>
            <div className="row">
              <div className="col-md">
                <Select
                  name="TipoAtividade"
                  label="Tipo de atividade"
                  options={tipoAtividade}
                />
              </div>
              <div className="col-md">
                <Input type="text" name="Empresa" label="Empresa" />
              </div>
              <div className="col-md">
                <Input type="text" name="Cargo" label="Cargo" />
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <InputValor name="Salario" label="Salário" nullable />
              </div>
              <div className="col-md">
                <InputValor
                  name="OutrasRendas"
                  label="Outras rendas"
                  nullable
                />
              </div>
              <div className="col-md">
                <Input
                  type="date"
                  name="DataAdmissao"
                  label="Data de admissão"
                  // defaultValue={newDate}
                />
              </div>
            </div>
            <Scope path="TelefoneComercial">
              <h4 className="scope-title">Telefone Comercial</h4>
              <div className="row">
                <div className="col-md-2">
                  <Input type="text" name="Ddd" label="DDD" />
                </div>
                <div className="col-md-10">
                  <MaskedInput
                    type="text"
                    name="Numero"
                    label="Número"
                    mask="0000 0000"
                    unmask
                  />
                </div>
              </div>
            </Scope>
            <Scope path="EnderecoComercial">
              <h4 className="scope-title">Endereço Comercial</h4>

              <div className="row">
                <div className="col-md">
                  <MaskedInput
                    name="Cep"
                    label="CEP"
                    lazy
                    mask="00.000-000"
                    unmask
                    onChange={() =>
                      PreencherEndereco(
                        formRef,
                        'Avalista.Ocupacao.EnderecoComercial.Cep',
                        'Avalista.Ocupacao.EnderecoComercial'
                      )
                    }
                  />
                </div>
                <div className="col-md">
                  <Input type="text" name="Logradouro" label="Logradouro" />
                </div>
                <div className="col-md">
                  <Input type="number" name="Numero" label="Número" />
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <Input type="text" name="Complemento" label="Complemento" />
                </div>

                <div className="col-md">
                  <Select name="Uf" label="Estado" options={listaEstados} />
                </div>
                <div className="col-md">
                  <Select
                    name="Cidade"
                    label="Cidade"
                    // Todo select automático
                    options={listaEstados}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <Input type="text" name="Bairro" label="Bairro" />
                </div>
                <div className="col-md">
                  <Input
                    type="text"
                    name="PontoReferencia"
                    label="Ponto de referencia"
                  />
                </div>
              </div>
            </Scope>
          </Scope>
        </Scope>
      </Section>

      <Section title="Avalista - Dados do Conjuge">
        <Scope path="Avalista.Conjuge">
          <div className="row">
            <div className="col-md">
              <MaskedInput
                name="Cpf"
                label="Cpf"
                mask="000.000.000-00"
                type="text"
                unmask
              />
            </div>
            <div className="col-md">
              <Input type="text" name="Nome" label="Nome" />
            </div>
            <div className="col-md">
              <Input
                name="DataNascimento"
                type="date"
                label="Data de nascimento"
              />
            </div>
          </div>
          <Scope path="Telefone">
            <h4 className="scope-title">Telefone</h4>

            <div className="row">
              <div className="col-md-2">
                <Input type="text" name="Ddd" label="DDD" />
              </div>
              <div className="col-md-10">
                <MaskedInput
                  type="text"
                  name="Numero"
                  label="Número"
                  mask="0 0000 0000"
                  unmask
                />
              </div>
            </div>
          </Scope>
          <h4 className="scope-title">Ocupação</h4>
          <div className="row">
            <div className="col-md">
              <Input type="text" name="Empresa" label="Empresa" />
            </div>
            <div className="col-md">
              <Input type="text" name="Cargo" label="Cargo" />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <InputValor name="Salario" label="Salário" nullable />
            </div>

            <div className="col-md">
              <Input
                type="number"
                name="TempoServico"
                label="Tempo de serviço em meses"
              />
            </div>
          </div>
          <Scope path="TelefoneComercial">
            <h4 className="scope-title">Telefone Comercial</h4>
            <div className="row">
              <div className="col-md-2">
                <Input type="text" name="Ddd" label="DDD" />
              </div>
              <div className="col-md-10">
                <MaskedInput
                  type="text"
                  name="Numero"
                  label="Número"
                  mask="0000 0000"
                  unmask
                />
              </div>
            </div>
          </Scope>
        </Scope>
      </Section>

      <Section title="Avalista - Referências">
        <Scope path="Avalista">
          <h4 className="scope-title">Referências Pessoais</h4>
          {referenciasPessoais.map((referencia) => {
            return (
              <Scope
                key={referencia.id}
                path={`ReferenciasPessoais[${referencia.id}]`}
              >
                <div className="row">
                  <div className="col-md-4">
                    <Input type="text" name="Nome" label="Nome" />
                  </div>
                  <Scope path="Telefone">
                    <div className="col-md-2">
                      <Input type="text" name="Ddd" label="DDD" />
                    </div>
                    <div className="col-md-6">
                      <MaskedInput
                        type="text"
                        name="Numero"
                        label="Número"
                        mask="0 0000 0000"
                        unmask
                        lazy
                        // defaultValue="111111111"
                      />
                    </div>
                  </Scope>
                </div>
              </Scope>
            );
          })}
          <button
            type="button"
            className="btn-referencias add"
            onClick={() =>
              HandleReferencias(
                referenciasPessoais,
                setReferenciasPessoais,
                'add'
              )
            }
          >
            Nova Referencia
          </button>
          <button
            type="button"
            className="btn-referencias rem"
            disabled={!(referenciasPessoais.length > 0)}
            onClick={() =>
              HandleReferencias(
                referenciasPessoais,
                setReferenciasPessoais,
                'rem'
              )
            }
          >
            Remover Referencia
          </button>

          <hr />
          <h4 className="scope-title">Referências Comerciais</h4>

          {ReferenciasComerciais.map((referencia) => {
            return (
              <Scope
                key={referencia.id}
                path={`ReferenciasComerciais[${referencia.id}]`}
              >
                <div className="row">
                  <div className="col-md-4">
                    <Input type="text" name="Nome" label="Nome" />
                  </div>
                  <Scope path="Telefone">
                    <div className="col-md-2">
                      <Input type="text" name="Ddd" label="DDD" />
                    </div>
                    <div className="col-md-6">
                      <MaskedInput
                        type="text"
                        name="Numero"
                        label="Número"
                        mask="0 0000 0000"
                        unmask
                      />
                    </div>
                  </Scope>
                </div>
              </Scope>
            );
          })}
          <button
            type="button"
            className="btn-referencias add"
            onClick={() =>
              HandleReferencias(
                ReferenciasComerciais,
                setReferenciasComerciais,
                'add'
              )
            }
          >
            Nova Referencia
          </button>
          <button
            type="button"
            className="btn-referencias rem"
            disabled={!(ReferenciasComerciais.length > 0)}
            onClick={() =>
              HandleReferencias(
                ReferenciasComerciais,
                setReferenciasComerciais,
                'rem'
              )
            }
          >
            Remover Referencia
          </button>

          <hr />
          <h4 className="scope-title">Referências Bancárias</h4>
          {ReferenciasBancarias.map((referencia) => {
            return (
              <Scope
                key={referencia.id}
                path={`ReferenciasBancarias[${referencia.id}]`}
              >
                <div className="row">
                  <div className="col-md">
                    <Select
                      name="CodigoBanco"
                      label="Código do banco"
                      options={codigoBancos}
                    />
                  </div>
                  <div className="col-md">
                    <Input name="Agencia" label="Agência" type="text" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <Input
                      name="DigitoAgencia"
                      label="Dígito da agência"
                      type="number"
                    />
                  </div>
                  <div className="col-md">
                    <Input
                      name="DataAberturaConta"
                      label="Data abertura"
                      type="date"
                      // defaultValue={newDate}
                    />
                  </div>
                </div>
                <hr />
              </Scope>
            );
          })}

          <button
            type="button"
            // disabled={!(ReferenciasBancarias.length > 0)}
            className="btn-referencias add"
            onClick={() =>
              HandleReferencias(
                ReferenciasBancarias,
                setReferenciasBancarias,
                'add'
              )
            }
          >
            Nova Referencia
          </button>
          <button
            type="button"
            className="btn-referencias rem"
            onClick={() =>
              HandleReferencias(
                ReferenciasBancarias,
                setReferenciasBancarias,
                'rem'
              )
            }
          >
            Remover Referencia
          </button>
        </Scope>
      </Section>
    </>
  );
}

export default AvalistaSections;
