export function enviarPropostaRequest(data) {
  return {
    type: '@propostas/ENVIAR_PROPOSTA_REQUEST',
    payload: { data },
  };
}

export function enviarPropostaSuccess(data) {
  return {
    type: '@propostas/ENVIAR_PROPOSTA_SUCCESS',
    payload: { data },
  };
}
export function enviarPropostaFailure(data) {
  return {
    type: '@propostas/ENVIAR_PROPOSTA_FAILURE',
    payload: { data },
  };
}

export function enviarPropostaNovaRequest(data) {
  return {
    type: '@propostas/ENVIAR_PROPOSTA_NOVA_REQUEST',
    payload: { data },
  };
}

export function enviarPropostaNovaSuccess(data) {
  return {
    type: '@propostas/ENVIAR_PROPOSTA_NOVA_SUCCESS',
    payload: { data },
  };
}
export function enviarPropostaNovaFailure(data) {
  return {
    type: '@propostas/ENVIAR_PROPOSTA_NOVA_FAILURE',
    payload: { data },
  };
}



export function updatePropostaNovaRequest(data) {
  return {
    type: '@propostas/UPDATE_PROPOSTA_NOVA_REQUEST',
    payload: { data },
  };
}

export function updatePropostaNovaSuccess(data) {
  return {
    type: '@propostas/UPDATE_PROPOSTA_NOVA_SUCCESS',
    payload: { data },
  };
}
export function updatePropostaNovaFailure(data) {
  return {
    type: '@propostas/UPDATE_PROPOSTA_NOVA_FAILURE',
    payload: { data },
  };
}




export function updatePropostaRequest(data) {
  return {
    type: '@propostas/UPDATE_PROPOSTA_REQUEST',
    payload: { data },
  };
}

export function updatePropostaSuccess(data) {
  return {
    type: '@propostas/UPDATE_PROPOSTA_SUCCESS',
    payload: { data },
  };
}
export function updatePropostaFailure(data) {
  return {
    type: '@propostas/UPDATE_PROPOSTA_FAILURE',
    payload: { data },
  };
}

export function deletarPropostaRequest(data) {
  return {
    type: '@propostas/DELETAR_PROPOSTA_REQUEST',
    payload: { data },
  };
}
export function deletarPropostaSuccess(data) {
  return {
    type: '@propostas/DELETAR_PROPOSTA_SUCCESS',
    payload: { data },
  };
}
export function deletarPropostaFailure() {
  return {
    type: '@propostas/DELETAR_PROPOSTA_FAILURE',
    payload: {},
  };
}

export function selecionarPropostaParaEditarRequest(data) {
  return {
    type: '@propostas/SELECIONAR_PROPOSTA_EDITAR_REQUEST',
    payload: { data },
  };
}

export function selecionarPropostaParaEditarSuccess(data) {
  return {
    type: '@propostas/SELECIONAR_PROPOSTA_EDITAR_SUCCESS',
    payload: { data },
  };
}

export function selecionarPropostaParaEditarFailure() {
  return {
    type: '@propostas/SELECIONAR_PROPOSTA_EDITAR_FAILURE',
    payload: {},
  };
}

export function limparErrosDeValidacaoRequest() {
  return {
    type: '@propostas/LIMPAR_ERROS_REQUEST',
    payload: {},
  };
}
export function limparErrosDeValidacaoSuccess() {
  return {
    type: '@propostas/LIMPAR_ERROS_SUCCESS',
    payload: {},
  };
}
