import React from 'react';
import { useSelector } from 'react-redux';

// import { Container } from './styles';

function SidebarRight() {
  const propostas = useSelector((state) => state.propostas);
  const { errors } = propostas;
  return (
    <aside className="sidebar-right">
      <div className="status">
        {errors &&
          errors.length > 0 &&
          errors.map((erro, key) => {
            return (
              <div key={key} className="status-item">
                <span>
                  {erro.mensagem && erro.mensagem}
                  {!erro.mensagem && erro}
                </span>
                <small>Erro</small>
              </div>
            );
          })}

        {/* <div className="status-item completed">
          <span>DADOS COMERCIAIS</span>
          <small>COMPLETO</small>
        </div>
        <div className="status-item">
          <span>DADOS BANCÁRIOS</span>
          <small>INCOMPLETO</small>
        </div>
        <div className="status-item">
          <span>DADOS CÔNJUGE</span>
          <small>INCOMPLETO</small>
        </div> */}
      </div>
    </aside>
  );
}

export default SidebarRight;
