import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import axios from 'axios';
import querystring from 'querystring';
import QueryString from 'qs';
import { queryHelpers } from '@testing-library/react';
import Header from '~/components/header';
import { signInRequest } from '~/store/modules/auth/actions';
import logo from '~/assets/images/logopng.png';
import Input from '~/components/CustomInputs/Input';
import history from '~/services/history';

const Login = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.auth.loading);
  const signed = useSelector((state) => state.auth.signed);

  // console.log(loading);
  useEffect(() => {
    if (signed) {
      // console.log('Is Signed');
      history.go(0);
    } else {
      // console.log('Not Signed');
    }
  }, [signed]);

  async function getToken() {
    const requestData = {
      grant_type: 'password',
      password: 'ach$208518',
      username: '35308566000160',
      cpf_atendente: '15506302703',
    };
    const esc = encodeURI;

    const parsed = Object.keys(requestData)
      .map((k) => `${esc(k)}=${esc(requestData[k])}`)
      .join('&');
    console.log(parsed);

    // console.log(
    //   queryHelpers.stringify({

    //   })
    // );
    const url = 'https://hom-webservice.agoracred.com.br/ApiCredito/token';
    const request = {
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/x-www-form-urlencoded',
      }),
      body:
        'grant_type=password&password=ach$208518&username=35308566000160&cpf_atendente=15506302703',

      mode: 'cors',
      withCredentials: true,
      crossdomain: true,
    };
    const response = await axios({
      method: 'POST',
      url: 'https://hom-webservice.agoracred.com.br/ApiCredito/token',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/x-www-form-urlencoded',
      }),
      data: parsed,
      body:
        'grant_type=password&password=ach$208518&username=35308566000160&cpf_atendente=15506302703',
    });
    return response.data;

    // const data = await response.json();
    // return data;
  }

  const handleLogin = (data) => {
    dispatch(signInRequest(data));
  };
  return (
    <div className="fullHeight login-container">
      {/* <Header /> */}

      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="my-3 d-flex justify-content-center align-items-center">
              <img
                src={logo}
                alt="Logo Elleve"
                className="login-logo img-fluid w-50 "
              />
            </div>
            <Form ref={formRef} onSubmit={handleLogin}>
              <Input
                type="text"
                name="userName"
                // label="Nome de Usuário"
                required
                variant="green"
                placeholder="Nome de Usuário"
              />
              <Input
                type="password"
                name="password"
                required
                variant="green"
                placeholder="Senha"
              />

              <div className="w-100 d-flex align-items-center">
                <button
                  type="submit"
                  className="login-btn"
                  disabled={loading ? true : null}
                >
                  {loading ? 'Carregando' : 'Login'}
                </button>
              </div>
            </Form>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 offset-md-3 pt-3 mt-3">
            <div className="d-flex flex-row justify-content-around align-items-center">
              {/* <Link to="/">CADASTRE-SE</Link> */}
              <Link to="/recuperar">RECUPERAR SENHA</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
