import React, { useEffect, useRef, useCallback } from 'react';
import { useField } from '@unform/core';

export const TextArea = ({ name, label, nullable, ...rest }) => {
  const inputRef = useRef(null);
  const {
    fieldName,
    defaultValue,
    clearError,
    registerField,
    error,
  } = useField(name);

  useEffect(() => {
    // inputRef.current.value = defaultValue || '';
    if (nullable) {
      inputRef.current.value = '';
    }
    if (defaultValue && !nullable) {
      inputRef.current.value = defaultValue;
    }
    if (!defaultValue && !nullable) {
      inputRef.current.value = null;
    }
    // console.log(new Date());
  }, []);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        // console.log('value', value);
        if (value) {
          ref.value = value;
        } else {
          ref.value = '';
        }
      },
      clearValue(ref: any) {
        ref.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <div className="form-group">
        <label htmlFor={fieldName}>{label}</label>
        <textarea
          ref={inputRef}
          name={fieldName}
          className="form-control"
          onFocus={clearError}
          {...rest}
        >
          {inputRef.current && inputRef.current.value}
        </textarea>
        {error && <span className="form-error-text">{error}</span>}
      </div>
    </>
  );
};

export default TextArea;
