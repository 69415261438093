import React, { useState, useEffect } from 'react';
import querystring from 'querystring';

import axios from 'axios';

// import { Container } from './styles';

const accessData = {
  username: '35308566000160',
  password: 'ach$208518',
  grant_type: 'password',
  cpf_atendente: '15506302703',
};
// console.log('AccessData:', accessData);
// console.log('QueryStringData', querystring.stringify(accessData));

function TestApi() {
  async function getToken() {
    const url = 'https://hom-webservice.agoracred.com.br/ApiCredito/token';
    const request = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body:
        'grant_type=password&password=ach$208518&username=35308566000160&cpf_atendente=15506302703',

      mode: 'cors',
    };

    const response = await fetch(url, request);

    const data = await response.json();
    // console.log(data);
    return data.access_token;
  }

  async function getConferencia(token) {
    const url =
      'https://hom-webservice.agoracred.com.br/ApiCredito/api/estabelecimentos/conferencia';
    const request = {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      mode: 'cors',
    };

    // const response = await fetch(url, request);

    const axiosResponse = await axios({
      method: 'GET',
      url:
        'https://hom-webservice.agoracred.com.br/ApiCredito/api/estabelecimentos/conferencia',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      mode: 'cors',
    });
    // const data = await response.json();
    // console.log('Response from fetch', data);
    // return data.ExtratoEstabelecimento[0].NomeFantasia;
  }

  getToken().then((token) => {
    getConferencia(token).then((nomeFantasia) => {
      // alert(nomeFantasia);
    });
  });

  return <h1>Teste da API</h1>;
}

export default TestApi;
