import { Form } from "@unform/web";
import React, { useEffect, useRef, useState } from "react";
import Input from "~/components/CustomInputs/Input";
import Select from "~/components/CustomInputs/Select";
import Header from "~/components/header";
import Section from "~/components/Section";
import SidebarLeft from "~/components/sidebar-left";
import SidebarRight from "~/components/sidebar-right";
import uploadIcon from '~/assets/images/upload.svg';
import FileBase64 from 'react-file-base64';
import * as Yup from 'yup';
import {
  tipoOperacaoOptions,
  tiposTelefone,
  tiposEndereco,
  tiposReferencia,
  tipoClienteOptions,
  formaPagamento,
  listaEstados,
  sexo,
  situacaoConjugal,
  tipoImovel,
  tipoAtividade,
  falecido,
  codigoBancos,
  tipoConta,
  formaPagamentoCliente,
} from "~/components/DataSources/index.js";
import InputValor from "~/components/CustomInputs/InputValor";
import ElleveAPI from "~/services/api";
import { toast } from "react-toastify";
import LoadingGif from "~/assets/images/loading.gif";

import LoadingImage from "~/assets/images/loading.gif";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "~/components/CustomInputs/TextArea";
import { Scope } from "@unform/core";
import MaskedInput from "~/components/CustomInputs/MaskedInput";
import PreencherEndereco, {
  PreencherEnderecoNew,
} from "~/components/PreencherEndereco";
import { Modal } from "react-bootstrap";
import BiometriaFacial from "~/components/BiometriaFacial";
import PDFIcon from '~/assets/images/pdf.png';
import { enviarPropostaNovaRequest } from "~/store/modules/propostas/actions";
import history from "~/services/history";
import {ValidacaoEnvioPropostaNova} from "~/components/Validation/ValidacaoEnvioPropostas";

// import { Container } from './styles';

const PropostaNova = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch()
  const [pageLoading, setPageLoading] = useState(true);
  const [tabelasComerciais, setTabelasComerciais] = useState(false);
  const [newDate, setNewDate] = useState(false);
  const [valorSimulacao, setValorSimulacao] = useState(false);
  const [loadingSimulacao, setLoadingSimulacao] = useState(false);

  const currentPropostaErrors = useSelector((state) => state.propostas.errors);
  // console.log(currentPropostaErrors);

  const currentPropostaSuccess = useSelector(
    (state) => state.propostas.success
  );
  const [envioParaAnalise, setEnvioParaAnalise] = useState(false);



  const [biometriaShow, setBiometriaShow] = useState(false)
  const [biometriaFacialData, setBiometriaFacialData] = useState(false);
  const callBackSendPhoto = (fotoBiometria) => {
    setBiometriaFacialData(fotoBiometria);
    setBiometriaShow(false);
  };
  const profile = useSelector((state) => state.auth.profile);

  const [tabelaInfo, setTabelaInfo] = useState({
    carencia: 30,
    planos: [
      
    ]
  });

  useEffect(() => {
    

    ElleveAPI()
      .get("Proposta/tabela/1")
      .then((response) => {
        
        const tabelas = response.data.value.filter((item) => {
          return item.ativo
        }).map((tabela) => {
          const filtered = { ...tabela };
          filtered.id = tabela.id;
          filtered.label = tabela.descricao;
          return filtered;
        });

        const now = new Date(Date.now() + 31 * 24 * 60 * 60 * 1000);

        const date = now.toLocaleDateString("pt-BR").split("/");
        const dateParsed = `${date[2]}-${date[1]}-${date[0]}`;

        setNewDate(dateParsed);


        setTabelasComerciais(tabelas);
        setPageLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPageLoading(false);
      });
  }, []);


  const handleChangeTabelaComercial = (event) => {
    // console.log(event.target.value)
    // console.log(tabelasComerciais);


    const filtered = tabelasComerciais.filter((item) => {
      return item.id.toString() === event.target.value.toString();
    } )[0];

    setTabelaInfo({
      carencia: filtered.carencia,
      planos: filtered.tabelaFinanciamentoPlanoResponse.map((item) => {
        if(item.plano === 1){
          return  {
            id: item.plano,
            label: `${item.plano} X`,
          }
        }else{
          return  {
            id: item.plano,
            label: `${item.plano} X`,
          }
        }
        
      })
    })
  }
  const handleValorSimulacao = async () => {
    setLoadingSimulacao(true);

    const data = {
      valorVista:
        formRef.current.getFieldValue("valorVista") !== ""
          ? parseInt(formRef.current.getFieldValue("valorVista"), 10)
          : 0,
      valorEntrada:
        formRef.current.getFieldValue("valorEntrada") !== ""
          ? parseInt(formRef.current.getFieldValue("valorEntrada"), 10)
          : 0,
      dataPrimeiraParcela:
        formRef.current.getFieldValue("dataPrimeiraParcela") === ""
          ? null
          : formRef.current.getFieldValue("dataPrimeiraParcela"),
      tabelaComercial:
        formRef.current.getFieldValue("tabelaComercial") !== ""
          ? parseInt(formRef.current.getFieldValue("tabelaComercial"), 10)
          : 0,
      plano:
        formRef.current.getFieldValue("plano") !== ""
          ? parseInt(formRef.current.getFieldValue("plano"), 10)
          : 0,
    };
    // console.log(data);

    ElleveAPI()
      .post("/Proposta/simulacao", data)
      .then((response) => {
        // console.log(response);

        if(response.data.value.valorParcela === 0 ){

          // setValorSimulacao(response.data.value.valorParcela.toString());
          setLoadingSimulacao(false)
          toast.error("Erro ao calcular o valor da parcela, verifique os valores informados.");

        }else{
          setValorSimulacao(response.data.value.valorParcela.toString());
          setLoadingSimulacao(false)
          toast.success("Valor da parcela calculado");
        }
        
        

      })
      .catch((error) => {
        const errors = Object.values(error.response.data.errors);
        console.log(errors[0][0]);
        toast.warning(errors[0][0]);
        setLoadingSimulacao(false);

        // toast.warning(errors[0][0])
      });
  };

  const [displayConjuge, setDisplayConjuge] = useState(false);
  const handleSituacaoConjugal = (value) => {
    // console.log(value)
    if (value === "Casado") {
      setDisplayConjuge(true);
    } else {
      setDisplayConjuge(false);
    }
  };
  const [displayConjugeAvalista, setDisplayConjugeAvalista] = useState(false);
  const handleSituacaoConjugalAvalista = (value) => {
    // console.log(value)
    if (value === "Casado") {
      setDisplayConjugeAvalista(true);
    } else {
      setDisplayConjugeAvalista(false);
    }
  };

  const [listaTelefones, setListaTelefones] = useState([{id: 1}]);
  const [listaEnderecos, setListaEnderecos] = useState([{id: 1}]);
  const [listaReferencias, setListaReferencias] = useState([
    {id: 1},
    {id: 2}
  ]);
  const [listaReferenciasBancarias, setListaReferenciasBancarias] = useState([{id: 1}]);
  const [listaTelefonesAvalista, setListaTelefonesAvalista] = useState([]);
  const [listaEnderecosAvalista, setListaEnderecosAvalista] = useState([]);
  const [listaReferenciasAvalista, setListaReferenciasAvalista] = useState([]);
  const [
    listaReferenciasBancariasAvalista,
    setListaReferenciasBancariasAvalista,
  ] = useState([]);

  const handleAdicionarItens = (itemArray, setItemArray) => {
    const ref = [...itemArray];
    const newRef = [...itemArray];
    const id = newRef.length === 0 ? 0 : newRef.splice(-1, 1)[0].id + 1;
    ref.push({
      id,
    });
    setItemArray(ref);
  };
  const handleRemoverItem = (itemId, itemArray, setItemArray) => {
    setItemArray(
      itemArray.filter((item) => {
        return item.id !== itemId;
      })
    );
  };

  let time = null;

  const [bancoLoading, setBancoLoading] = useState(false);
  const [bancoFilter, setBancoFilter] = useState(false);
  const handleSearchBanco = (busca) => {
    clearTimeout(time);
    time = setTimeout(() => {
      setBancoLoading(true);

      if (busca.length === 0) {
        setBancoFilter(false);
        setBancoLoading(false);
      } else {
        const listaFiltered = codigoBancos.filter((item) => {
          return item.label.toLowerCase().indexOf(busca.toLowerCase()) !== -1;
        });
        console.log(listaFiltered);
        setBancoFilter(listaFiltered);
        setBancoLoading(false);
      }
    }, 500);
  };



  const [listaArquivos, setListaArquivos] = useState([]);
  const getFiles = (file) => {
    const currentFiles = listaArquivos

    const fileType = file.file.type.split('/');
    const filteredFile = {
      base64: file.file.base64,
      documento: file.file.base64
        .replace(/^data:image\/[a-z]+;base64,/, '')
        .replace(/^data:application\/[a-z]+;base64,/, ''),
      nomeArquivo: file.file.name,
      tipoDocumento: file.tipo,
      extensao: fileType[1],
    };

    

    const newArray = [...currentFiles, { ...filteredFile }];
    // console.log(currentFiles)
    console.log(filteredFile)
    // console.log(newArray)


    setListaArquivos(newArray);
  };

  const handleRemoverArquivo = (selected) => {
    setListaArquivos(
      listaArquivos.filter((arquivo) => {
        return arquivo.base64 !== selected.base64;
      })
    );
  };


  const [showModal, setShowModal] = useState(false)


  const [modalState, setModalState] = useState({
    loading: true,
    error: false,
    message: 'Validando os dados',
    buttons: [
      {
        id: 1,
        label: 'Cancelar',
        disabled: true,
        function: () => setShowModal(false),
      },
      {
        id: 2,
        label: 'Salvar Dados',
        disabled: true,
        function: () => {
          console.log('submited');
        },
      },
    ],
  });

  const parseErrosForDisplay = (array) => {
    // console.log(array);
    const values = Object.values(array).map((item) => {
      if(Array.isArray(item)){
        return item.slice(-1);
      }
      else{
        return item;
      }
      
    }) 
    // console.log(values);
    return values;
  }


  useEffect(() => {
    if (currentPropostaErrors) {
      setModalState({
        loading: false,
        error: true,
        message: 'Resposta: Verifique os seguintes erros',
        listaErros: parseErrosForDisplay(currentPropostaErrors),
        buttons: [
          {
            id: 1,
            label: 'Alterar Dados',
            disabled: false,
            function: () => {
              setShowModal(false);
              // dispatch(limparErrosDeValidacaoRequest());
            },
          },
          {
            id: 2,
            label: 'Enviar para análise',
            disabled: false,
            function: () => {
              console.log('Current Data Sent:', formRef.current.getData());
              dispatch(
                enviarPropostaNovaRequest(
                  addFieldsToDataToSaveProposta(formRef.current.getData())
                )
              );
            },
          },
        ],
      });
      setEnvioParaAnalise(false);
    }
    if (envioParaAnalise) {
      setModalState({
        loading: true,
        error: false,
        message: 'Validando dados da proposta',
        // listaErros: currentPropostaErrors,
        buttons: [
          {
            id: 1,
            label: 'Alterar Dados',
            disabled: true,
            function: () => setShowModal(false),
          },
          {
            id: 2,
            label: 'Enviar para análise',
            disabled: true,
            function: () => {
              console.log('Cleansed Data: ', formRef.current.getData());
              dispatch(
                enviarPropostaNovaRequest(
                  addFieldsToDataToSaveProposta(formRef.current.getData())
                )
              );
              setEnvioParaAnalise(false);
            },
          },
        ],
      });
    }
    if (currentPropostaSuccess) {
      setModalState({
        loading: false,
        error: false,
        message: 'Proposta criada com sucesso!',
        // listaErros: currentPropostaErrors,
        buttons: [
          {
            id: 1,
            label: 'Fechar',
            disabled: false,
            function: () => {
              setShowModal(false);
              history.push('/dashboard/consulta');
              history.go(0);
            },
          },
          // {
          //   id: 2,
          //   label: 'Enviar para análise',
          //   disabled: true,
          //   function: () => {
          //     console.log('submited');
          //     dispatch(enviarPropostaNovaRequest(formRef.current.getData()));
          //   },
          // },
        ],
      });
    }
  }, [currentPropostaErrors, envioParaAnalise, currentPropostaSuccess]);


  const addFieldsToDataToSaveProposta = (data) => {
    return {
      ...data,
      biometria: biometriaFacialData
        ? {
            foto: biometriaFacialData.replace(
              /^data:image\/[a-z]+;base64,/,
              ''
            ),
            cpf: data.cliente.documento,
            nome: data.cliente.nome,
            dataNascimento: data.cliente.pessoaFisica.dataNascimento,
            nomeArquivo: 'biometriafacial.png',
          }
        : null,
      listaArquivos: listaArquivos
        ? listaArquivos.map((arquivo) => {
            const filtered = {};
            filtered.documento = arquivo.documento;
            filtered.indexador = data.cliente.documento;
            filtered.nomeArquivo = arquivo.nomeArquivo;
            filtered.tipoDocumento = arquivo.tipoDocumento;
            filtered.extensao = arquivo.extensao;
            return filtered;
          })
        : null,
    };
  };



  const handleEnviarProposta = async (data) => {
    // console.log(data);

    
    // console.log(data)
    setShowModal(!showModal);
    try {
      const schema = ValidacaoEnvioPropostaNova();
      await schema.validate(data, {
        abortEarly: false,
      });

      // console.log(data);
      setModalState({
        loading: false,
        error: false,
        message: 'Dados validados com sucesso',
        buttons: [
          {
            id: 1,
            label: 'Alterar Dados',
            disabled: false,
            function: () => setShowModal(false),
          },
          {
            id: 2,
            label: 'Enviar para análise',
            disabled: false,
            function: () => {
              // console.log('submited');
              setEnvioParaAnalise(true);

              dispatch(
                enviarPropostaNovaRequest(addFieldsToDataToSaveProposta(data))
              );
            },
          },
        ],
      });
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          // console.log(error);
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }

      const errorArray = err.inner.map((item)=> {
        return {
          mensagem: `${item.path} : ${item.message}`
        };
      })


      setModalState({
        loading: false,
        error: true,
        listaErros: errorArray ,
        message: `Verifique os dados do formulário. Foram encontrados ${err.errors.length} erros. `,
        buttons: [
          {
            id: 1,
            label: 'Alterar Dados',
            disabled: false,
            function: () => setShowModal(false),
          },
          {
            id: 2,
            label: 'Enviar para análise',
            disabled: false,
            function: () => {
              // console.log('Current Data: ', cleanDeep(data));
              dispatch(
                enviarPropostaNovaRequest(addFieldsToDataToSaveProposta(data))
              );
              setEnvioParaAnalise(true);

            },
          },
        ],
      });
      // dispatch(enviarPropostaNovaRequest(data));
    }


  };


  const [hasAvalista, setHasAvalista] = useState(false);

  const handleHasAvalista = () => {
    setHasAvalista(!hasAvalista)
  }

  if (pageLoading)
    return (
      <div className="">
        <Header />
        <div className="container content-container loading">
          <div className="row">
            <img src={LoadingGif} alt="loading spinner" />
          </div>
        </div>
      </div>
    );

  if (!pageLoading)
    return (
      <div>
        <Header />

        <div className="full-height content-container">
            <SidebarLeft />

            <main className="page-content">
              {/* <h3>Nova Proposta</h3> */}

              <Form
                ref={formRef}
                onSubmit={handleEnviarProposta}
                id="frm-envio"
                className="w-100"
              >
                <div className="accordion" id="accordionExample">
                  <Section title="DADOS DO FINANCIAMENTO" expanded>
                    <h3 className="section-title">Informações Básicas</h3>
                    <div className="row">
                      <div className="col-md">
                        <Select
                          name="tipoOperacao"
                          label="Tipo de Operação"
                          options={tipoOperacaoOptions}
                          defaultValue="CP"
                        />
                      </div>
                    </div>
                    <div className="row hiddenField">
                      <div className="col-md">
                        <Select
                          name="tipoCliente"
                          label="Tipo de Cliente"
                          options={tipoClienteOptions}
                          defaultValue="Novo"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md">
                        <Select
                          name="formaPagamento"
                          label="Forma de Pagamento"
                          options={formaPagamento}
                          defaultValue="Boleto"
                        />
                      </div>
                      <div className="col-md">
                        <Select
                          name="formaPagamentoCliente"
                          label="Forma de Pagamento Cliente"
                          options={formaPagamentoCliente}
                          defaultValue="Transferencia"
                        />
                      </div>
                    </div>

                    <h3 className="section-title">Valores</h3>

                    <div className="row">
                      <div className="col-md">
                        <InputValor
                          name="valorVista"
                          label="R$ à Vista"
                          nullable
                        />
                      </div>
                      <div className="col-md">
                        <InputValor
                          name="valorEntrada"
                          label="R$ Entrada"
                          nullable
                        />
                      </div>
                      <div className="col-md">
                        <InputValor
                          name="valorParcela"
                          label="R$ da Parcela"
                          nullable
                          disabled
                          value={valorSimulacao || null}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md">
                        <Select
                          name="tabelaComercial"
                          label="Tabela Comercial"
                          options={tabelasComerciais}
                          onChange={(event) => handleChangeTabelaComercial(event)}
                        />
                      </div>
                      <div className="col-md">
                        {/* <Input
                          type="number"
                          label="Prazo em meses"
                          name="plano"
                          nullable
                        /> */}
                        <Select name="plano" label="Prazo em meses" options={tabelaInfo.planos} />
                      </div>
                      <div className="col-md">
                        <Input
                          name="dataPrimeiraParcela"
                          type="date"
                          label="Primeira parcela"
                          defaultValue={newDate}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <label>Simular proposta</label>
                        <button
                          type="button"
                          className="btn btn-simular"
                          onClick={handleValorSimulacao}
                          disabled={loadingSimulacao}
                        >
                          {loadingSimulacao ? "..." : "Simular"}
                        </button>
                      </div>
                    </div>

                    <h3 className="section-title">Dados da Loja</h3>

                    <div className="row">
                      
                      <Input
                          type="hidden"
                          name="cpfAtendente"
                          label="CPF do atendente"
                          disabled
                          defaultValue={profile && profile.cpf}
                        />
                        <Input
                          type="hidden"
                          name="lojaId"
                          label="Id da Loja"
                          disabled
                          defaultValue="1"
                        />
                      <div className="col-md">
                        <Input
                          type="number"
                          name="codigoPropostaLojista"
                          label="Código de proposta Lojista"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md">
                        <TextArea name="observacao" label="Observações" />
                      </div>
                    </div>
                  </Section>

                  <Section title="Cliente">
                    <Scope path="cliente">
                      <div className="row">
                        <div className="col-md">
                          <MaskedInput
                            type="text"
                            mask="000.000.000-00"
                            name="documento"
                            label="CPF"
                            // disabled
                            unmask
                          />
                        </div>

                        <div className="col-md">
                          <Input type="text" name="nome" label="Nome" />
                        </div>
                      </div>
                      <h3 className="section-title">Pessoa Física</h3>

                      <Scope path="pessoaFisica">

                      <div className="row">
                        <div className="col-md">
                          <Input
                            type="date"
                            name="dataNascimento"
                            label="Data de Nascimento"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md">
                          <Input type="text" name="rg" label="Número do RG" />
                        </div>
                        <div className="col-md">
                          <Input
                            type="text"
                            name="orgaoEmissorRg"
                            label="Orgão Emissor"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md">
                          <Select
                            name="ufOrgaoEmissorRg"
                            label="UF"
                            options={listaEstados}
                          />
                        </div>

                        <div className="col-md">
                          <Input
                            type="date"
                            name="dataEmissaoRg"
                            label="Data de emissão"
                            // defaultValue={newDate}
                          />
                        </div>
                      </div>
                      <hr />

                      <div className="row">
                        <div className="col-md">
                          <Input type="text" name="pai" label="Nome do Pai" />
                        </div>
                        <div className="col-md">
                          <Input type="text" name="mae" label="Nome da Mãe" />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md">
                          <Select name="sexo" options={sexo} label="Sexo" />
                        </div>
                        <div className="col-md">
                          <Select
                            name="estadoCivil"
                            options={situacaoConjugal}
                            label="Situação Conjugal"
                            onChange={(e) =>
                              handleSituacaoConjugal(e.target.value)
                            }
                          />
                        </div>
                      </div>

                      <hr />

                      <div className="row">
                        <div className="col-md">
                          <Input type="email" name="email" label="E-mail" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md">
                          <Input
                            type="text"
                            name="naturalidade"
                            label="Naturalidade"
                          />
                        </div>
                      </div>

                      <hr />

                      <div className="row">
                        <div className="col-md">
                          <Select
                            name="tipoResidencia"
                            options={tipoImovel}
                            label="Tipo de residencia"
                          />
                        </div>
                        <div className="col-md">
                          <Input
                            type="number"
                            name="tempoResidencia"
                            label="Tempo de residencia"
                          />
                        </div>
                      </div>

                      <h3 className="section-title">Ocupação</h3>

                      <div className="row">
                        <div className="col-md">
                          <Select
                            name="ocupacao"
                            options={tipoAtividade}
                            label="Ocupação"
                          />
                        </div>
                        <div className="col-md">
                          <Input type="text" name="cargo" label="Cargo" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md">
                          <Input type="text" name="empresa" label="Empresa" />
                        </div>
                        <div className="col-md">
                          <InputValor
                            name="valorRenda"
                            label="Renda"
                            nullable
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md">
                          <InputValor
                            name="valorRendaExtra"
                            label="Renda extra"
                            nullable
                          />
                        </div>
                        <div className="col-md">
                          <Input
                            type="number"
                            name="tempoServico"
                            label="Tempo Serviço"
                            nullable
                          />
                        </div>
                      </div>

<hr/>
                      <div className="row">
                        <div className="col-md">
                          <Select
                            name="falecido"
                            label="Falecido"
                            options={falecido}
                            defaultValue="Não"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md">
                          <TextArea name="observacao" label="Observações" />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md">
                          <Input type="text" name="apelido" label="Apelido" />
                        </div>
                        <div className="col-md">
                          <Input
                            type="number"
                            name="quantidadeDependentes"
                            label="Quantidade dependentes"
                          />
                        </div>
                      </div>

                      </Scope>


                      {displayConjuge && (
                        <Scope path="conjuge">
                          <h3 className="section-title">Conjuge</h3>

                          <div className="row">
                            <div className="col-md">
                              <Input type="text" name="nome" label="Nome" />
                            </div>
                            <div className="col-md">
                              <MaskedInput
                                name="Cpf"
                                label="Cpf"
                                mask="000.000.000-00"
                                type="text"
                                unmask
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md">
                              <Input
                                type="date"
                                name="dataNascimento"
                                label="Data de Nascimento"
                              />
                            </div>
                            <div className="col-md">
                              <InputValor
                                name="valorRenda"
                                label="Renda"
                                nullable
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md">
                              <Input type="text" name="cargo" label="Cargo" />
                            </div>
                            <div className="col-md">
                              <Input
                                type="text"
                                name="empresa"
                                label="Empresa"
                              />
                            </div>
                            <div className="col-md">
                              <Input
                                type="number"
                                name="tempoServico"
                                label="Tempo Serviço"
                                nullable
                              />
                            </div>
                          </div>
                        </Scope>
                      )}

                      <h3 className="section-title">Telefones</h3>

                      {listaTelefones.map((item, index) => (
                        <Scope key={item.id} path={`telefones[${index}]`}>
                          <div className="row">
                            <div className="col-md-2">
                              <Input type="number" name="ddd" label="DDD"  />
                            </div>
                            <div className="col-md-5">
                              <MaskedInput
                                type="text"
                                name="numero"
                                label="Número"
                                mask="0000-00000"
                                unmask
                              />
                            </div>
                            <div className="col-md-3">
                              <Select
                                name="tipoTelefone"
                                label="Tipo"
                                options={tiposTelefone}
                                defaultValue="Pessoal"
                              />
                            </div>
                            <div className="col-md-1 d-flex align-items-center justify-content-center ">
                              <button
                                type="button"
                                className="btn-referencias rem"
                                onClick={() =>
                                  handleRemoverItem(
                                    item.id,
                                    listaTelefones,
                                    setListaTelefones
                                  )
                                }
                              >
                                X
                              </button>
                            </div>
                          </div>
                        </Scope>
                      ))}

                      <hr />
                      <div className="d-flex justify-content-end mt-2">
                        <button
                          type="button"
                          onClick={() =>
                            handleAdicionarItens(
                              listaTelefones,
                              setListaTelefones
                            )
                          }
                          className="btn-referencias add"
                        >
                          Adicionar Telefone
                        </button>
                      </div>

                      <h3 className="section-title">Endereços</h3>

                      {listaEnderecos.map((item, index) => (
                        <Scope key={item.id} path={`enderecos[${index}]`}>
                          <div className="row">
                            <div className="col-md">
                              <MaskedInput
                                name="cep"
                                label="CEP"
                                mask="00.000-000"
                                unmask
                                lazy
                                onChange={() =>
                                  PreencherEnderecoNew(
                                    formRef,
                                    `cliente.enderecos[${index}].cep`,
                                    `cliente.enderecos[${index}]`
                                  )
                                }
                              />
                            </div>
                            <div className="col-md">
                              <Input
                                type="number"
                                name="numero"
                                label="Número"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <Input
                                type="text"
                                name="logradouro"
                                label="Logradouro"
                              />
                            </div>
                            <div className="col">
                              <Input type="text" name="bairro" label="Bairro" />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <Select
                                name="uf"
                                label="Estado"
                                // defaultValue={enderecoClienteEstado}
                                options={listaEstados}
                              />
                            </div>
                            <div className="col">
                              <Select
                                name="cidade"
                                label="Cidade"
                                options={{}}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md">
                              <Input
                                type="text"
                                name="complemento"
                                label="Complemento"
                              />
                            </div>
                            <div className="col-md">
                              <Select
                                name="tipoEndereco"
                                label="Tipo Endereço"
                                options={tiposEndereco}
                                defaultValue="Residencial"
                              />
                            </div>
                          </div>

                          <div className="d-flex justify-content-end mt-2 mb-2">
                            <button
                              type="button"
                              className="btn-referencias rem"
                              onClick={() =>
                                handleRemoverItem(
                                  item.id,
                                  listaEnderecos,
                                  setListaEnderecos
                                )
                              }
                            >
                              Remover Endereço
                            </button>
                          </div>
                          <hr />
                        </Scope>
                      ))}

                      <hr />
                      <div className="d-flex justify-content-end mt-2">
                        <button
                          type="button"
                          onClick={() =>
                            handleAdicionarItens(
                              listaEnderecos,
                              setListaEnderecos
                            )
                          }
                          className="btn-referencias add"
                        >
                          Adicionar Endereço
                        </button>
                      </div>

                      <h3 className="section-title">Referencias</h3>

                      {listaReferencias.map((item, index) => (
                        <Scope key={item.id} path={`referencias[${index}]`}>
                          <div className="row">
                            <div className="col-md">
                              <Input type="text" name="nome" label="Nome" />
                            </div>

                            <div className="col-md">
                              <Select
                                name="tipoReferencia"
                                label="Tipo"
                                options={tiposReferencia}
                                defaultValue={item.id === 1 ? 1 :  2}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md">
                              <Input type="text" name="ddd" label="DDD" />
                            </div>
                            <div className="col-md">
                              <MaskedInput
                                type="text"
                                name="numero"
                                label="Número"
                                mask="0000-00000"
                                unmask
                              />
                            </div>                            
                          </div>
                          
                          <div className="row">
                            <div className="col-md d-flex justify-content-end mt-2 mb-2">
                            <button
                                type="button"
                                className="btn-referencias rem"
                                onClick={() =>
                                  handleRemoverItem(
                                    item.id,
                                    listaReferencias,
                                    setListaReferencias
                                  )
                                }
                              >
                                Remover Referência
                              </button>


                            </div>
                          </div>
                          <hr/>
                        </Scope>
                      ))}

                      <hr />
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          onClick={() =>
                            handleAdicionarItens(
                              listaReferencias,
                              setListaReferencias
                            )
                          }
                          className="btn-referencias add"
                        >
                          Adicionar Referência
                        </button>
                      </div>

                      <h3 className="section-title">Referencias Bancárias</h3>

                      {listaReferenciasBancarias.map((item, index) => (
                        <Scope
                          key={item.id}
                          path={`referenciasBancarias[${index}]`}
                        >
                          <div className="row">
                            <div className="col-md">
                              <Select
                                name="banco"
                                label="Código do banco"
                                options={codigoBancos}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md">
                              <Input
                                type="number"
                                name="agencia"
                                label="Agência"
                              />
                            </div>
                            <div className="col-md">
                              <Input
                                type="number"
                                name="digitoAgencia"
                                label="Dígito agência"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md">
                              <Input
                                name="dataAberturaConta"
                                label="Data abertura"
                                type="date"
                                // defaultValue={newDate}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn-referencias rem"
                              onClick={() =>
                                handleRemoverItem(
                                  item.id,
                                  listaReferenciasBancarias,
                                  setListaReferenciasBancarias
                                )
                              }
                            >
                              Remover Referência
                            </button>
                          </div>
                          <hr />
                        </Scope>
                      ))}

                      <hr />
                      <div className="d-flex justify-content-end mt-2">
                        <button
                          type="button"
                          onClick={() =>
                            handleAdicionarItens(
                              listaReferenciasBancarias,
                              setListaReferenciasBancarias
                            )
                          }
                          className="btn-referencias add"
                        >
                          Adicionar Referência
                        </button>
                      </div>
                    </Scope>
                  </Section>




                  <Section title="Conta Bancária">
                    <Scope path="contaBancaria">
                      <div className="row">
                        <div className="col-md">
                          <Input
                            type="text"
                            label="Filtro para bancos"
                            name="bancoSearch"
                            onChange={(e) => handleSearchBanco(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md">
                          <Select
                            name="tipoContaBancaria"
                            label="Tipo de Conta"
                            options={tipoConta}
                          />
                        </div>

                        <div className="col-md">
                          <Select
                            name="banco"
                            label="Banco"
                            options={
                              bancoLoading
                                ? [{ id: "", label: "carregando" }]
                                : bancoFilter
                                ? bancoFilter
                                : codigoBancos
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md">
                          <Input name="agencia" label="Agência" type="number" />
                        </div>
                        <div className="col-md">
                          <Input
                            name="digitoAgencia"
                            label="Dígito agência"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md">
                          <Input name="conta" label="Conta" type="number" />
                        </div>
                        <div className="col-md">
                          <Input
                            name="digitoConta"
                            label="Digito da conta"
                            type="text"
                          />
                        </div>
                      </div>
                    </Scope>
                  </Section>


                  <Section title="Documentos e Fotos">
                    <div className="row">
                      <div className="col-md-12">
                        {/* <Input name="Agencia" label="Agência" type="number" /> */}
                        <h4>Biometria Facial</h4>
                        <div className="biometria-facial-formulario">
                          <button
                            type="button"
                            className="btn btn-default"
                            onClick={() => setBiometriaShow(!biometriaShow)}
                          >
                            {biometriaFacialData
                              ? 'Alterar Foto'
                              : 'Tirar Foto'}
                          </button>

                          {biometriaFacialData && (
                            <div className="row">
                              <img
                                src={biometriaFacialData}
                                alt="Biometria Facial"
                                className="biometria-facial-display"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="documentos-selecionados-upload-box">
                          <h4>Documentos</h4>

                          <label>
                            <span className="bg mt-3">
                              <span className="icone">
                                <img src={uploadIcon} />
                              </span>
                              <span className="texto">
                                Documento de identificacao.
                              </span>
                            </span>

                            <FileBase64
                              name="documentoIdentificacao"
                              id="documentoIdentificacao"
                              onDone={(file) => getFiles({ file, tipo: 2 })}
                            />
                          </label>

                          <div className="documentos-lista">
                            {listaArquivos &&
                              listaArquivos
                                .filter((arquivo) => {
                                  return arquivo.tipoDocumento === 2;
                                })
                                .map((arquivo) => {
                                  return (
                                    <div
                                      key={arquivo.base64}
                                      className="arquivo-preview"
                                    >
                                      <img
                                        src={arquivo.base64}
                                        alt="preview de arquivo"
                                        className="img-preview"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = PDFIcon;
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="img-preview-btn"
                                        onClick={() =>
                                          handleRemoverArquivo(arquivo)
                                        }
                                      >
                                        Remover
                                      </button>
                                    </div>
                                  );
                                })}
                          </div>

                          <label>
                            <span className="bg mt-3">
                              <span className="icone">
                                <img src={uploadIcon} />
                              </span>
                              <span className="texto">
                                Comprovante de renda.
                              </span>
                            </span>
                            <FileBase64
                              name="documentoIdentificacao"
                              id="documentoIdentificacao"
                              onDone={(file) => getFiles({ file, tipo: 3 })}
                            />
                          </label>

                          <div className="documentos-lista">
                            {listaArquivos &&
                              listaArquivos
                                .filter((arquivo) => {
                                  return arquivo.tipoDocumento === 3;
                                })
                                .map((arquivo) => {
                                  return (
                                    <div
                                      key={arquivo.base64}
                                      className="arquivo-preview"
                                    >
                                      <img
                                        src={arquivo.base64}
                                        alt="preview de arquivo"
                                        className="img-preview"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = PDFIcon;
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="img-preview-btn"
                                        onClick={() =>
                                          handleRemoverArquivo(arquivo)
                                        }
                                      >
                                        Remover
                                      </button>
                                    </div>
                                  );
                                })}
                          </div>

                          <label>
                            <span className="bg mt-3">
                              <span className="icone">
                                <img src={uploadIcon} />
                              </span>
                              <span className="texto">
                                Comprovante de endereço.
                              </span>
                            </span>

                            <FileBase64
                              name="comprovanteEndereco"
                              id="comprovanteEndereco"
                              onDone={(file) => getFiles({ file, tipo: 4 })}
                            />
                          </label>
                          <div className="documentos-lista">
                            {listaArquivos &&
                              listaArquivos
                                .filter((arquivo) => {
                                  return arquivo.tipoDocumento === 4;
                                })
                                .map((arquivo) => {
                                  return (
                                    <div
                                      key={arquivo.base64}
                                      className="arquivo-preview"
                                    >
                                      <img
                                        src={arquivo.base64}
                                        alt="preview de arquivo"
                                        className="img-preview"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = PDFIcon;
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="img-preview-btn"
                                        onClick={() =>
                                          handleRemoverArquivo(arquivo)
                                        }
                                      >
                                        Remover
                                      </button>
                                    </div>
                                  );
                                })}
                          </div>
                          <label>
                            <span className="bg mt-3">
                              <span className="icone">
                                <img src={uploadIcon} />
                              </span>
                              <span className="texto">Outros.</span>
                            </span>

                            <FileBase64
                              name="outrosArquivos"
                              id="outrosArquivos"
                              onDone={(file) => getFiles({ file, tipo: 6 })}
                            />
                          </label>
                          <div className="documentos-lista">
                            {listaArquivos &&
                              listaArquivos
                                .filter((arquivo) => {
                                  return arquivo.tipoDocumento === 6;
                                })
                                .map((arquivo) => {
                                  return (
                                    <div
                                      key={arquivo.base64}
                                      className="arquivo-preview"
                                    >
                                      <img
                                        src={arquivo.base64}
                                        alt="preview de arquivo"
                                        className="img-preview"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = PDFIcon;
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="img-preview-btn"
                                        onClick={() =>
                                          handleRemoverArquivo(arquivo)
                                        }
                                      >
                                        Remover
                                      </button>
                                    </div>
                                  );
                                })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Section>




<div className="row">
  <div className="col-md mb-3 ">
                                <button type="button" className="btn btn-simular-alt" onClick={handleHasAvalista}>
                                  {hasAvalista ? 'Não Enviar dados do Avalista' : 'Enviar dados do Avalista?' }
                                </button>
  </div>
</div>



{/* Avalista */}

{hasAvalista && (
  <Section title="Avalista">
                   


                   <Scope path="avalista">
                       <div className="row">
                         <div className="col-md">
                           <MaskedInput
                             type="text"
                             mask="000.000.000-00"
                             name="documento"
                             label="Documento"
                             // disabled
                             unmask
                           />
                         </div>
 
                         <div className="col-md">
                           <Input type="text" name="nome" label="Nome" />
                         </div>
                       </div>
                       <h3 className="section-title">Pessoa Física</h3>
 
                       <Scope path="pessoaFisica">
 
                       <div className="row">
                         <div className="col-md">
                           <Input
                             type="date"
                             name="dataNascimento"
                             label="Data de Nascimento"
                           />
                         </div>
                       </div>
 
                       <div className="row">
                         <div className="col-md">
                           <Input type="text" name="rg" label="Número do RG" />
                         </div>
                         <div className="col-md">
                           <Input
                             type="text"
                             name="orgaoEmissorRg"
                             label="Orgão Emissor"
                           />
                         </div>
                       </div>
                       <div className="row">
                         <div className="col-md">
                           <Select
                             name="ufOrgaoEmissorRg"
                             label="UF"
                             options={listaEstados}
                           />
                         </div>
 
                         <div className="col-md">
                           <Input
                             type="date"
                             name="dataEmissaoRg"
                             label="Data de emissão"
                             // defaultValue={newDate}
                           />
                         </div>
                       </div>
                       <hr />
 
                       <div className="row">
                         <div className="col-md">
                           <Input type="text" name="pai" label="Nome do Pai" />
                         </div>
                         <div className="col-md">
                           <Input type="text" name="mae" label="Nome da Mãe" />
                         </div>
                       </div>
 
                       <div className="row">
                         <div className="col-md">
                           <Select name="sexo" options={sexo} label="Sexo" />
                         </div>
                         <div className="col-md">
                           <Select
                             name="estadoCivil"
                             options={situacaoConjugal}
                             label="Situação Conjugal"
                             onChange={(e) =>
                               handleSituacaoConjugalAvalista(e.target.value)
                             }
                           />
                         </div>
                       </div>
 
                       <hr />
 
                       <div className="row">
                         <div className="col-md">
                           <Input type="email" name="email" label="E-mail" />
                         </div>
                       </div>
                       <div className="row">
                         <div className="col-md">
                           <Input
                             type="text"
                             name="naturalidade"
                             label="Naturalidade"
                           />
                         </div>
                       </div>
 
                       <hr />
 
                       <div className="row">
                         <div className="col-md">
                           <Select
                             name="tipoResidencia"
                             options={tipoImovel}
                             label="Tipo de residencia"
                           />
                         </div>
                         <div className="col-md">
                           <Input
                             type="number"
                             name="tempoResidencia"
                             label="Tempo de residencia"
                           />
                         </div>
                       </div>
 
                       <h3 className="section-title">Ocupação</h3>
 
                       <div className="row">
                         <div className="col-md">
                           <Select
                             name="ocupacao"
                             options={tipoAtividade}
                             label="Ocupação"
                           />
                         </div>
                         <div className="col-md">
                           <Input type="text" name="cargo" label="Cargo" />
                         </div>
                       </div>
                       <div className="row">
                         <div className="col-md">
                           <Input type="text" name="empresa" label="Empresa" />
                         </div>
                         <div className="col-md">
                           <InputValor
                             name="valorRenda"
                             label="Renda"
                             nullable
                           />
                         </div>
                       </div>
                       <div className="row">
                         <div className="col-md">
                           <InputValor
                             name="valorRendaExtra"
                             label="Renda extra"
                             nullable
                           />
                         </div>
                         <div className="col-md">
                           <Input
                             type="number"
                             name="tempoServico"
                             label="Tempo Serviço"
                             nullable
                           />
                         </div>
                       </div>

<hr/> 
                       <div className="row">
                         <div className="col-md">
                           <Select
                             name="falecido"
                             label="Falecido"
                             options={falecido}
                           />
                         </div>
                       </div>
 
                       <div className="row">
                         <div className="col-md">
                           <TextArea name="observacao" label="Observações" />
                         </div>
                       </div>
 
                       <div className="row">
                         <div className="col-md">
                           <Input type="text" name="apelido" label="Apelido" />
                         </div>
                         <div className="col-md">
                           <Input
                             type="number"
                             name="quantidadeDependentes"
                             label="Quantidade dependentes"
                           />
                         </div>
                       </div>
 
                       </Scope>
 
 
                       {displayConjugeAvalista && (
                         <Scope path="conjuge">
                           <h3 className="section-title">Conjuge</h3>
 
                           <div className="row">
                             <div className="col-md">
                               <Input type="text" name="nome" label="Nome" />
                             </div>
                             <div className="col-md">
                               <MaskedInput
                                 name="Cpf"
                                 label="Cpf"
                                 mask="000.000.000-00"
                                 type="text"
                                 unmask
                               />
                             </div>
                           </div>
                           <div className="row">
                             <div className="col-md">
                               <Input
                                 type="date"
                                 name="dataNascimento"
                                 label="Data de Nascimento"
                               />
                             </div>
                             <div className="col-md">
                               <InputValor
                                 name="valorRenda"
                                 label="Renda"
                                 nullable
                               />
                             </div>
                           </div>
                           <div className="row">
                             <div className="col-md">
                               <Input type="text" name="cargo" label="Cargo" />
                             </div>
                             <div className="col-md">
                               <Input
                                 type="text"
                                 name="empresa"
                                 label="Empresa"
                               />
                             </div>
                             <div className="col-md">
                               <Input
                                 type="number"
                                 name="tempoServico"
                                 label="Tempo Serviço"
                                 nullable
                               />
                             </div>
                           </div>
                         </Scope>
                       )}
 
                       <h3 className="section-title">Telefones</h3>
 
                       {listaTelefonesAvalista.map((item, index) => (
                         <Scope key={item.id} path={`telefones[${index}]`}>
                           <div className="row">
                             <div className="col-md-2">
                               <Input type="text" name="ddd" label="DDD" />
                             </div>
                             <div className="col-md-5">
                               <MaskedInput
                                 type="text"
                                 name="numero"
                                 label="Número"
                                 mask="0000 00000"
                                 unmask
                               />
                             </div>
                             <div className="col-md-3">
                               <Select
                                 name="tipoTelefone"
                                 label="Tipo"
                                 options={tiposTelefone}
                               />
                             </div>
                             <div className="col-md-1 d-flex align-items-center justify-content-center ">
                               <button
                                 type="button"
                                 className="btn-referencias rem"
                                 onClick={() =>
                                   handleRemoverItem(
                                     item.id,
                                     listaTelefonesAvalista,
                                     setListaTelefonesAvalista
                                   )
                                 }
                               >
                                 X
                               </button>
                             </div>
                           </div>
                         </Scope>
                       ))}
 
                       <hr />
                       <div className="d-flex justify-content-end">
                         <button
                           type="button"
                           onClick={() =>
                             handleAdicionarItens(
                               listaTelefonesAvalista,
                               setListaTelefonesAvalista
                             )
                           }
                           className="btn-referencias add"
                         >
                           Adicionar Telefone
                         </button>
                       </div>
 
                       <h3 className="section-title">Endereços</h3>
 
                       {listaEnderecosAvalista.map((item, index) => (
                         <Scope key={item.id} path={`enderecos[${index}]`}>
                           <div className="row">
                             <div className="col-md">
                               <MaskedInput
                                 name="cep"
                                 label="CEP"
                                 mask="00.000-000"
                                 unmask
                                 lazy
                                 onChange={() =>
                                   PreencherEnderecoNew(
                                     formRef,
                                     `avalista.enderecos[${index}].cep`,
                                     `avalista.enderecos[${index}]`
                                   )
                                 }
                               />
                             </div>
                             <div className="col-md">
                               <Input
                                 type="number"
                                 name="numero"
                                 label="Número"
                               />
                             </div>
                           </div>
                           <div className="row">
                             <div className="col">
                               <Input
                                 type="text"
                                 name="logradouro"
                                 label="Logradouro"
                               />
                             </div>
                             <div className="col">
                               <Input type="text" name="bairro" label="Bairro" />
                             </div>
                           </div>
                           <div className="row">
                             <div className="col">
                               <Select
                                 name="uf"
                                 label="Estado"
                                 // defaultValue={enderecoClienteEstado}
                                 options={listaEstados}
                               />
                             </div>
                             <div className="col">
                               <Select
                                 name="cidade"
                                 label="Cidade"
                                 options={{}}
                               />
                             </div>
                           </div>
                           <div className="row">
                             <div className="col-md">
                               <Input
                                 type="text"
                                 name="complemento"
                                 label="Complemento"
                               />
                             </div>
                             <div className="col-md">
                               <Select
                                 name="tipoEndereco"
                                 label="Tipo Endereço"
                                 options={tiposEndereco}
                               />
                             </div>
                           </div>
 
                           <div className="d-flex justify-content-end mt-2">
                             <button
                               type="button"
                               className="btn-referencias rem"
                               onClick={() =>
                                 handleRemoverItem(
                                   item.id,
                                   listaEnderecosAvalista,
                                   setListaEnderecosAvalista
                                 )
                               }
                             >
                               Remover Endereço
                             </button>
                           </div>
                           <hr />
                         </Scope>
                       ))}
 
                       <hr />
                       <div className="d-flex justify-content-end">
                         <button
                           type="button"
                           onClick={() =>
                             handleAdicionarItens(
                               listaEnderecosAvalista,
                               setListaEnderecosAvalista
                             )
                           }
                           className="btn-referencias add"
                         >
                           Adicionar Endereço
                         </button>
                       </div>
 
                       <h3 className="section-title">Referencias</h3>
 
                       {listaReferenciasAvalista.map((item, index) => (
                         <Scope key={item.id} path={`referencias[${index}]`}>
                           <div className="row">
                             <div className="col-md">
                               <Input type="text" name="nome" label="Nome" />
                             </div>
 
                             <div className="col-md">
                               <Select
                                 name="tipoReferencia"
                                 label="Tipo"
                                 options={tiposReferencia}
                               />
                             </div>
                           </div>
                           <div className="row">
                             <div className="col-md">
                               <Input type="text" name="ddd" label="DDD" />
                             </div>
                             <div className="col-md">
                               <MaskedInput
                                 type="text"
                                 name="numero"
                                 label="Número"
                                 mask="0000-00000"
                                 unmask
                               />
                             </div>                            
                           </div>
                           <div className="row">
                             <div className="col-md d-flex justify-cntent-end">
                             <button
                                 type="button"
                                 className="btn-referencias rem"
                                 onClick={() =>
                                   handleRemoverItem(
                                     item.id,
                                     listaReferenciasAvalista,
                                     setListaReferenciasAvalista
                                   )
                                 }
                               >
                                 Remover Referência
                               </button>
 
 
                             </div>
                           </div>
                         </Scope>
                       ))}
 
                       <hr />
                       <div className="d-flex justify-content-end">
                         <button
                           type="button"
                           onClick={() =>
                             handleAdicionarItens(
                               listaReferenciasAvalista,
                               setListaReferenciasAvalista
                             )
                           }
                           className="btn-referencias add"
                         >
                           Adicionar Referência
                         </button>
                       </div>
 
                       <h3 className="section-title">Referencias Bancárias</h3>
 
                       {listaReferenciasBancariasAvalista.map((item, index) => (
                         <Scope
                           key={item.id}
                           path={`referenciasBancarias[${index}]`}
                         >
                           <div className="row">
                             <div className="col-md">
                               <Select
                                 name="banco"
                                 label="Código do banco"
                                 options={codigoBancos}
                               />
                             </div>
                           </div>
 
                           <div className="row">
                             <div className="col-md">
                               <Input
                                 type="number"
                                 name="agencia"
                                 label="Agência"
                               />
                             </div>
                             <div className="col-md">
                               <Input
                                 type="number"
                                 name="digitoAgencia"
                                 label="Dígito agência"
                               />
                             </div>
                           </div>
                           <div className="row">
                             <div className="col-md">
                               <Input
                                 name="dataAberturaConta"
                                 label="Data abertura"
                                 type="date"
                                 // defaultValue={newDate}
                               />
                             </div>
                           </div>
                           <div className="d-flex justify-content-end">
                             <button
                               type="button"
                               className="btn-referencias rem"
                               onClick={() =>
                                 handleRemoverItem(
                                   item.id,
                                   listaReferenciasBancariasAvalista,
                                   setListaReferenciasBancariasAvalista
                                 )
                               }
                             >
                               Remover Referência
                             </button>
                           </div>
                           <hr />
                         </Scope>
                       ))}
 
                       <hr />
                       <div className="d-flex justify-content-end">
                         <button
                           type="button"
                           onClick={() =>
                             handleAdicionarItens(
                               listaReferenciasBancariasAvalista,
                               setListaReferenciasBancariasAvalista
                             )
                           }
                           className="btn-referencias add"
                         >
                           Adicionar Referência
                         </button>
                       </div>
                     </Scope>
 
 
 
 
 
 
 
 
 
                   </Section>
)}

                   {/* Avalista */}




                </div>










                <div className="btn-container">
                  
                  <button type="submit" className="btn btn-primary btn-lg">
                    Enviar Proposta
                  </button>
                </div>

                

        
              </Form>

              <Modal
          size="lg"
          show={biometriaShow}
          onHide={() => setBiometriaShow(!biometriaShow)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Biometria Facial</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BiometriaFacial callBackSendPhoto={callBackSendPhoto} />
          </Modal.Body>
        </Modal>




        <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>CADASTRO DE NOVA PROPOSTA</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalState.loading && (
              <>
                <div className="w-100 d-flex align-items-center justify-content-center">
                  <img
                    src={LoadingImage}
                    alt="Loading spinner"
                    className="modal-loading"
                  />
                </div>
              </>
            )}
            <div className="validacao-wrapper">
              <div className="validacao-icon">
                {!modalState.loading && !modalState.error && (
                  <i
                    className="fa fa-check fa-4x validacao-success"
                    aria-hidden="true"
                  />
                )}
                {!modalState.loading && modalState.error && (
                  <i
                    className="fa fa-exclamation-circle fa-4x validacao-error"
                    aria-hidden="true"
                  />
                )}
              </div>
              <div className="validacao-text">{modalState.message}</div>

              {modalState.listaErros && (
                <ul className="list-group">
                    {/* {console.log(modalState.listaErros)} */}
                    
                  {modalState.listaErros.map((erro, key) => {
                    // console.log(erro)
                    return (
                      <li key={key} className="list-group-item">
                        {erro.mensagem && erro.mensagem}
                        {!erro.mensagem && erro}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {modalState.buttons.map((button) => {
              return (
                <button
                  key={button.id}
                  type="button"
                  className="btn "
                  disabled={button.disabled}
                  onClick={() => button.function()}
                >
                  {button.label}
                </button>
              );
            })}
          </Modal.Footer>
        </Modal>





            </main>

        </div>
      </div>
    );
};

export default PropostaNova;
