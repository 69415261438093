import React from 'react';
import PropTypes from 'prop-types';

export default function DefaultLayout({ children }) {
  // console.log(children);
  return (
    <div className="full-height">
      {children}
    </div>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
