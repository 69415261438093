import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Document, Page } from 'react-pdf';
import { Link } from 'react-router-dom';
import FileBase64 from 'react-file-base64';
import api, { ElleveAPI } from '~/services/api';
import history from '~/services/history';
import PDFIcon from '~/assets/images/pdf.png';
import {
  deletarPropostaRequest,
  selecionarPropostaParaEditarRequest,
} from '~/store/modules/propostas/actions';

// import { Container } from './styles';
const situacoes = [
  { Value: "Temporaria", Name: "Temporaria", ClassName: "analise" },
  { Value: "EnviadoAnalise", Name: "Enviado para analista", ClassName: "analise" },
  {
    Value: "EnviadoAtendente",
    Name: "Enviado para atendente",
    ClassName: "pendente",
  },
  { Value: "Aprovada", Name: "Aprovada", ClassName: "aprovado" },
  { Value: "Reprovada", Name: "Reprovada", ClassName: "reprovado" },
  { Value: "Cancelada", Name: "Cancelada", ClassName: "reprovado" },
  {
    Value: "AprovadaEncerrada",
    Name: "Aprovada e finalizada",
    ClassName: "aprovado",
  },
  {
    Value: "ReprovadaEncerrada",
    Name: "Reprovada e finalizada",
    ClassName: "reprovado",
  },
];

function CardItem({ data }) {
  // console.log(data);
  const dispatch = useDispatch();
  const [statusProposta, setStatusProposta] = useState(false);
  const token = useSelector((state) => state.auth.access_token);

  const [showImpressao, setShowImpressao] = useState(false);
  const [impressaoContent, setImpressaoContent] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [codigoProposta, setCodigoProposta] = useState(false)

  // console.log(data);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const [listaArquivos, setListaArquivos] = useState(false);
  const getFiles = (files) => {
    setListaArquivos(files);
    setShowImpressao(true);
  };

  const handleUploadFiles = () => {
    try {
      toast.info('Enviando arquivos selecionados');
      const parseFilesData = listaArquivos.map((arquivo) => {
        const filtered = {};
        filtered.documento = arquivo.base64
          .replace(/^data:image\/[a-z]+;base64,/, '')
          .replace(/^data:application\/[a-z]+;base64,/, '');
        filtered.indexador = codigoProposta.toString();
        filtered.nomeArquivo = arquivo.name;
        filtered.tipoDocumento = 5;
        return filtered;
      });

      parseFilesData.map((file) => {
        const resp = ElleveAPI().post(`Json/proposta/documento`, file);
        // console.log(resp);
        ElleveAPI()
          .post(`Json`, {
            jsonRetorno: JSON.stringify(resp.data),
            idFinanceira: 1,
            idMetodo: 11,
          })
          .then(() => {
            toast.success('Arquivo enviado com sucesso');
          });
      });
    } catch (error) {
      console.log(error);
      toast.error('Erro ao enviar os arquivos');
    }
  };

  useEffect(() => {

    // console.log(data)


    ElleveAPI()
    .get(`Proposta/resposta/${data.id}`)
    .then((response) => {
      console.log(response.data.value)
      setCodigoProposta(response.data.value.codigo);
      setStatusProposta({
        Situacao: situacoes.filter((situacao) => {
          return situacao.Value === response.data.value.situacao;
        })[0],
        Observacao: response.data.value.observacao,
      });

      const jsonResposta = {
        codigo: 'string',
        jsonEnvio: null,
        jsonRetorno: JSON.stringify(response.data),
        idFinanceira: 1,
        idMetodo: 6,
      };

      // ElleveAPI().post('Json/', jsonResposta);
    })
    .catch((error) => {
      console.log(error);
      // console.log(error.response);
      toast.error('Erro ao tentar atualizar status da proposta');
    });

   



  }, [data]);

  const HandleDeletarProposta = () => {
    console.log(data)
    if (window.confirm('Tem certeza que deseja deletar esta proposta?')) {
      dispatch(deletarPropostaRequest(data));
    }
  };

  const HandleSelecionarPropostaParaEditar = () => {
    dispatch(selecionarPropostaParaEditarRequest(data));
    history.push('/dashboard/proposta');
    history.go(0);
  };

  const HandleImprimirContrato = () => {
    if (statusProposta && statusProposta.Situacao.Name === 'Aprovada') {
      console.log('aaa');
    } else {
      toast.warning(
        'Não é possível gerar contrato para uma proposta não aprovada'
      );
    }
  };

  const handleGerarContrato = () => {
    if (window.confirm('Gerar contrato para a proposta?')) {
      ElleveAPI()
        .get(`Json/contrato/${codigoProposta}`)
        .then((response) => {

          const jsonResposta = {
            codigo: 'string',
            jsonEnvio: null,
            jsonRetorno: JSON.stringify(response.data),
            idFinanceira: 1,
            idMetodo: 7,
          };

          // ElleveAPI()
          //   .post('Json/', jsonResposta)
          //   .then((resJson) => console.log(resJson.data));

          history.go(0);
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          toast.error('Erro ao gerar contrato');
        });
    }
  };
  const handleGerarTa = () => {
    if (window.confirm('Gerar contrato TA para a proposta?')) {
      ElleveAPI()
        .get(`Json/contrato/ta/${codigoProposta}`)
        .then((response) => {
          // console.log(response);

          setShowImpressao(true);
          setImpressaoContent(response.data.pdfStream);

          const jsonResposta = {
            codigo: 'string',
            jsonEnvio: null,
            jsonRetorno: JSON.stringify(response.data),
            idFinanceira: 1,
            idMetodo: 8,
          };

          // ElleveAPI()
          //   .post('Json/', jsonResposta)
          //   .then((resJson) => console.log(resJson.data));
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          toast.error('Erro ao gerar contrato TA');
        });
    }
  };
  const handleGerarCarne = () => {
    if (window.confirm('Gerar contrato Carnê para a proposta?')) {
      ElleveAPI()
        .get(`Json/contrato/carne/${codigoProposta}`)
        .then((response) => {
          console.log(response);

          setShowImpressao(true);
          setImpressaoContent(response.data.pdfStream);

          const jsonResposta = {
            codigo: 'string',
            jsonEnvio: null,
            jsonRetorno: JSON.stringify(response.data),
            idFinanceira: 1,
            idMetodo: 9,
          };

          // ElleveAPI()
          //   .post('Json/', jsonResposta)
          //   .then((resJson) => console.log(resJson.data));
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          toast.error('Erro ao gerar contrato Carnê');
        });
    }
  };

  const handleRemoverArquivo = (selected) => {
    setListaArquivos(
      listaArquivos.filter((arquivo) => {
        return arquivo.base64 !== selected.base64;
      })
    );
  };

  return (
    <div
      className={`card card-${
        statusProposta ? statusProposta.Situacao.ClassName : 'pendente'
      }`}
    >
      <div className="card-content">

    <div className="cod-proposta">{codigoProposta && (
      <span>Código Proposta: {codigoProposta.toString()}</span>
    )}
    </div>
        <div className="nome">{data.nomeCliente && data.nomeCliente}</div>
        {statusProposta ? (
          <div className="flag">{statusProposta.Situacao.Name}</div>
        ) : (
          <div className="flag">...</div>
        )}
        <div className="observacao">
          Observação: {statusProposta.Observacao}
        </div>
      </div>

      <div className="btn-container">
        <button
          type="button"
          className="btn btn-default"
          onClick={HandleSelecionarPropostaParaEditar}
          disabled={
            statusProposta &&
            statusProposta.Situacao &&
            statusProposta.Situacao.Name !== 'EnviadoAtendente'
          }
        >
          Editar
        </button>

        {statusProposta && statusProposta.Situacao.Name !== 'Cancelada' ? (
          <button
            type="button"
            className="btn btn-default"
            onClick={HandleDeletarProposta}
            // disabled
          >
            Deletar
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-default"
            onClick={HandleDeletarProposta}
            disabled
          >
            Deletar
          </button>
        )}

        {statusProposta && (
          <>

          
            {statusProposta.Situacao.Name === 'Aprovada' ? (
              <button
                type="button"
                className="btn btn-default"
                onClick={() => handleGerarContrato()}
              >
                Gerar Contrato
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-default"
                onClick={() => handleGerarContrato()}
                disabled
              >
                Gerar Contrato
              </button>
            )}
            {statusProposta.Situacao.Name === 'Aprovada' && (
              <button
                type="button"
                className="btn btn-default"
                onClick={() => handleGerarTa()}
              >
                Gerar TA
              </button>
            )}
            <button
                type="button"
                className="btn btn-default"
                onClick={() => handleGerarTa()}
              >
                Gerar TA
              </button>
            {statusProposta.Situacao.Name === 'AprovadaEncerrada' && (
              <button
                type="button"
                className="btn btn-default"
                onClick={() => handleGerarTa()}
              >
                Gerar TA
              </button>
            )}

            {statusProposta.Situacao.Name === 'Aprovada' && (
              <button
                type="button"
                className="btn btn-default"
                onClick={() => handleGerarCarne()}
              >
                Gerar Carnê
              </button>
            )}
            {statusProposta.Situacao.Name === 'AprovadaEncerrada' && (
              <button
                type="button"
                className="btn btn-default"
                onClick={() => handleGerarCarne()}
              >
                Gerar Carnê
              </button>
            )}

            {statusProposta.Situacao.Name === 'Aprovada' && (
              <button
                type="button"
                className="btn btn-default btn-upload"
                // onClick={() => HandleEnviar()}
              >
                <label>
                  Enviar TA
                  <FileBase64
                    multiple
                    name="documentosUpload"
                    id="documentosUpload"
                    onDone={getFiles}
                  />
                </label>
              </button>
            )}
            {statusProposta.Situacao.Name === 'AprovadaEncerrada' && (
              <button
                type="button"
                className="btn btn-default btn-upload"
                // onClick={() => HandleEnviar()}
              >
                <label>
                  Enviar TA
                  <FileBase64
                    multiple
                    name="documentosUpload"
                    id="documentosUpload"
                    onDone={getFiles}
                  />
                </label>
              </button>
            )}
          </>
        )}
      </div>

      <Modal
        size="lg"
        show={showImpressao}
        onHide={() => setShowImpressao(!showImpressao)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {listaArquivos ? 'Enviar Arquivos' : 'Impressão'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {console.log(impressaoContent)} */}

          {/* {impressaoContent && (
            <object
              style={{
                width: '100%',
                height: '842pt',
              }}
              type="application/pdf"
              data={`data:application/octet-stream;base64,${impressaoContent}`}
            />
          )} */}

          <div className="documentos-lista">
            {listaArquivos &&
              listaArquivos.map((arquivo) => {
                // console.log(arquivo);
                return (
                  <div key={arquivo.base64} className="arquivo-preview">
                    <img
                      src={arquivo.base64}
                      alt="preview de arquivo"
                      className="img-preview"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = PDFIcon;
                      }}
                    />
                    <button
                      type="button"
                      className="img-preview-btn"
                      onClick={() => handleRemoverArquivo(arquivo)}
                    >
                      Remover
                    </button>
                  </div>
                );
              })}
          </div>
          {listaArquivos && listaArquivos.length > 0 && (
            <div className="biometria-foto-actions">
              <div className="actions-container">
                <button
                  type="button"
                  className="btn-referencias rem"
                  onClick={() => setShowImpressao(false)}
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btn-referencias add"
                  onClick={handleUploadFiles}
                >
                  Enviar
                </button>
              </div>
            </div>
          )}
          {impressaoContent && (
            <a
              href={`data:application/octet-stream;base64,${impressaoContent}`}
              download="impressao.pdf"
            >
              Download em PDF
            </a>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CardItem;
