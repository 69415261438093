import React from 'react';

export const Section = ({ children, title, expanded }) => {
  // console.log(expanded);
  const titleReplaced = title.split(' ').join('');
  // console.log(titleReplaced);
  return (
    <div className="card">
      <div className="card-header" id={`heading${titleReplaced}`}>
        <h2 className="mb-0">
          <button
            className="btn btn-link btn-block text-left section-title-action"
            type="button"
            data-toggle="collapse"
            data-target={`#${titleReplaced}`}
            aria-expanded={expanded ? 'true' : 'false'}
            aria-controls={`${titleReplaced}`}
          >
            {title}
          </button>
        </h2>
      </div>

      <div
        id={`${titleReplaced}`}
        className={`collapse ${expanded ? 'show' : null} `}
        aria-labelledby={`heading${titleReplaced}`}
        data-parent="#accordionExample"
      >
        <div className="card-body">{children}</div>
      </div>
    </div>
  );
};

export default Section;
