import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Consulta from '../pages/Consulta';
import Login from '~/pages/Login';
import Recuperar from '~/pages/Recuperar';
import Proposta from '~/pages/Proposta';
import Home from '~/pages/Home';
import TestApi from '~/pages/TestApi';
import PDF from '~/pages/pdf';
import PropostaNova from '~/pages/PropostaNova';
import ConsultaNova from '~/pages/ConsultaNova';
import EditarProposta from '~/pages/PropostaNova/editar';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/teste" exact component={TestApi} />

      <Route path="/recuperar" exact component={Recuperar} />

      {/* <Route path="/dashboard" exact component={Home} isPrivate /> */}
      <Route path="/dashboard" exact component={PropostaNova} isPrivate />
      <Route path="/dashboard/editarproposta/:id" exact component={EditarProposta} isPrivate />
      <Route path="/dashboard/consulta" exact component={ConsultaNova} isPrivate />
      <Route path="/dashboard/pdf" exact component={PDF} isPrivate />
      {/* <Route path="/dashboard/consulta" exact component={Consulta} isPrivate /> */}
      <Route path="/dashboard/proposta" component={Proposta} exact isPrivate />
    </Switch>
  );
}
